import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import { AUTH_LOGOUT } from "../actions/auth";
import Api from "@/services/ApiService.js";

const state = {
  user: {}
};

const getters = {
  getUser: state => state.user,
  getPermissions: state => state.user.permissions
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Api.me()
        .then(resp => {
          if (resp.data.data == "notavalible")
            commit(USER_SUCCESS, JSON.parse(localStorage.user));
          else commit(USER_SUCCESS, resp.data.data);
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
};

const mutations = {
  [USER_SUCCESS]: (state, user) => {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
    // Vue.set(state, 'user', user)
  },
  [USER_ERROR]: state => {
    localStorage.removeItem("user");
    state.user = {};
  },
  [AUTH_LOGOUT]: state => {
    localStorage.removeItem("user");
    state.user = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
