<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Verwaltung</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-toolbar background-color="primary" dark tabs>
            <v-tabs v-model="tab" background-color="primary" grow>
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab v-for="item in canTabItems" :key="item.text">{{
                item.text
              }}</v-tab>
            </v-tabs>
          </v-toolbar>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item v-if="$can('users.general')">
              <User></User>
            </v-tab-item>
            <v-tab-item v-if="$can('user_groups.general')">
              <Group ref="group"></Group>
            </v-tab-item>
            <v-tab-item v-if="$can('divisions.general')">
              <Division v-on:divisonChanged="refreshGroups"></Division>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import User from "@/views/User/User";
import Group from "@/views/User/Group";
import Division from "@/views/User/Division";

export default {
  components: { User, Group, Division },
  data: () => ({
    tab: null,
    tabItems: [
      {
        text: "Helfer",
        permission: "users.general"
      },
      {
        text: "Gruppen",
        permission: "user_groups.general"
      },
      {
        text: "Einheiten",
        permission: "divisions.general"
      }
    ]
  }),
  mounted: function() {
    this.tab = parseInt(this.$route.query.tab, 10);
  },
  computed: {
    canTabItems() {
      return this.tabItems.filter(h => this.$can(h.permission));
    }
  },
  watch: {
    tab: function(newVal, oldVal) {
      if (oldVal != null && newVal != oldVal)
        this.$router.replace({ name: "user", query: { tab: newVal } });
    }
  },
  methods: {
    refreshGroups() {
      if (this.$refs.group) {
        this.$refs.group.loadGroups();
      }
    }
  }
};
</script>

<style></style>
