<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">
          Qualifikationen
        </h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-list>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="item.title"
                :to="{ name: 'qualificationshow', params: { id: item.link } }"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="primary">{{ icons.mdiSend }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < items.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiSend } from "@mdi/js";

export default {
  data: () => ({
    items: [
      {
        title: "Atemschutz",
        link: "atemschutz"
      },
      {
        title: "CBRN",
        link: "cbrn"
      },
      {
        title: "Fahrerlaubnis",
        link: "fahrerlaubnis"
      }
    ],
    icons: { mdiSend }
  })
};
</script>

<style></style>
