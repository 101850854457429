<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Mein Barcode</h1>
      </v-col>
      <v-col cols="12">
        <barcode
          :value="myuser.thwin_id + '-01'"
          format="CODE128"
          width="2"
          height="80"
          background="white"
          text-position="top"
          v-bind:class="{
            isMobile: $vuetify.breakpoint.smAndDown,
            barcode: true
          }"
          >Ein Fehler ist aufgetreten.</barcode
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueBarcode from "vue-barcode";
import { mapGetters } from "vuex";

export default {
  components: { barcode: VueBarcode },
  data: () => ({
    user: null
  }),
  computed: {
    ...mapGetters({ myuser: "getUser" })
  }
};
</script>

<style>
.barcode svg {
  height: 100%;
  width: 100%;
  max-width: 1200px;
}
.isMobile {
  position: absolute;
  bottom: 0;
  width: 90%;
}
@media only print {
  .barcode {
    width: 4.2cm !important;
    margin-left: auto;
    margin-right: auto;
    outline-style: dotted;
  }
}
</style>
