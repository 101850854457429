<template>
  <v-card class="d-flex flex-column">
    <div class="background_Img" :style="imgStyle"></div>
    <v-app-bar flat dense color="transparent">
      <v-toolbar-title>
        {{ value.name }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn v-if="!disabled" v-on:click="removeButtonClicked" small icon>
        <v-icon>{{ icons.remove }} </v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-actions>
      <v-btn v-on:click="download" icon small>
        <v-icon>{{ icons.download }} </v-icon>
      </v-btn>
      <v-btn v-if="isImage" v-on:click="onOpenFullscreen" icon small>
        <v-icon>{{ icons.fullScreen }} </v-icon>
      </v-btn>
      <v-spacer />
      <div>{{ fileSizeInMb }} MB</div>
    </v-card-actions>
  </v-card>
</template>
<script>
import Api from "@/services/ApiService.js";
import { mdiDelete, mdiDownload, mdiFullscreen } from "@mdi/js";

export default {
  name: "File",
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imgUrl: "",
    icons: {
      remove: mdiDelete,
      download: mdiDownload,
      fullScreen: mdiFullscreen,
    },
    showImage: false,
  }),
  mounted() {
    if (this.isImage) {
      Api.filesGet(this.value.id).then((response) => {
        var blob = new Blob([response.data]);
        this.imgUrl = window.URL.createObjectURL(blob);
        this.$emit("image", { id: this.value.id, url: this.imgUrl });
      });
    }
  },
  methods: {
    download() {
      Api.filesGet(this.value.id)
        .then((response) => {
          var url;
          if (!this.imgUrl) {
            url = window.URL.createObjectURL(new Blob([response.data]));
          } else {
            url = this.imgUrl;
          }
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.value.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch(function(error) {
          vm.$emit("showSnackbar", error);
        });
    },
    removeButtonClicked(event) {
      event.stopPropagation();
      this.$emit("remove", this.value.id);
    },
    onOpenFullscreen() {
      this.$emit("show", this.value.id);
    },
  },
  computed: {
    isImage() {
      if (
        this.value.name.toLowerCase().endsWith("jpg") ||
        this.value.name.toLowerCase().endsWith("png") ||
        this.value.name.toLowerCase().endsWith("jpeg") ||
        this.value.name.toLowerCase().endsWith("bmp")
      ) {
        return true;
      }
      return false;
    },
    imgStyle() {
      if (this.isImage) {
        return "background-image: url('" + this.imgUrl + "')";
      }
      return "";
    },
    fileSizeInMb() {
      let v = this.value.size / 1000000;
      return v.toFixed(2).replace(".", ",");
    },
  },
  watch: {},
};
</script>
<style scoped>
.v-card {
  padding: 1vmin;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  aspect-ratio: 1.25;
}

.background_Img {
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.35;
}
</style>
