<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <h1 class="text-h3 font-weight-bold primary--text">Abfragen</h1>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn
              v-if="$can('events.general')"
              outlined
              color="primary"
              :to="{ name: 'eventcreate' }"
              >Erstellen</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @update:page="goTop()"
            :headers="headers"
            :items="filteredEvents"
            :search="search"
            :loading="loader"
            sort-by="title"
            @click:row="showItem"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="$can('events.general')"
                text
                icon
                small
                :to="{ name: 'eventedit', params: { id: item.id } }"
              >
                <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
              </v-btn>
              <v-btn
                v-if="item.date_options"
                text
                icon
                small
                :to="{ name: 'eventshow', params: { id: item.id } }"
              >
                <v-icon color="primary">{{
                  icons.mdiCalendarMultiselect
                }}</v-icon>
              </v-btn>
              <v-btn
                v-if="$can(['events.general', 'events.advanced'])"
                text
                icon
                small
                :to="{ name: 'eventadvanced', params: { id: item.id } }"
              >
                <v-icon color="primary">{{ icons.mdiCalendar }}</v-icon>
              </v-btn>
              <v-btn
                v-if="$can('events.general')"
                text
                icon
                small
                @click="deleteItem(item)"
              >
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <Confirm ref="confirm"></Confirm>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import { VIcon } from "vuetify/lib";
import EventBus from "@/eventBus";
import {
  mdiMagnify,
  mdiPencil,
  mdiCalendar,
  mdiDelete,
  mdiCalendarMultiselect
} from "@mdi/js";

export default {
  data: () => ({
    loader: false,
    token: undefined,
    dataDoodle: [],
    dataAll: [],
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        value: "title"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    icons: {
      mdiMagnify,
      mdiPencil,
      mdiCalendar,
      mdiDelete,
      mdiCalendarMultiselect
    }
  }),
  created: function() {
    this.token = localStorage.doodletoken;
    this.loadEvents();
  },
  mounted: function() {
    if (this.$route.params.token) {
      localStorage.doodletoken = this.$route.params.token;
      this.token = localStorage.doodletoken;
    }
    if (this.$store.getters.isAuthenticated) {
      localStorage.doodletoken = "";
    }
  },
  computed: {
    filteredEvents() {
      if (!this.$can(["events.general", "events.advanced"])) {
        return this.dataDoodle;
      } else {
        const mergedArray = [...this.dataDoodle, ...this.dataAll];
        let set = new Set();
        let unionArray = mergedArray.filter(item => {
          if (!set.has(item.id)) {
            set.add(item.id);
            return true;
          }
          return false;
        }, set);
        return unionArray;
      }
    }
  },
  methods: {
    loadEvents: function() {
      this.loader = true;
      const vm = this;
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodleEvents(vm.token)
        .then(function(response) {
          vm.dataDoodle = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
      if (this.$can(["events.general", "events.advanced"])) {
        Api.events()
          .then(function(response) {
            vm.dataAll = response.data.data;
            vm.loader = false;
          })
          .catch(() => {
            vm.loader = false;
          });
      }
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.dataAll.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.dataAll.splice(index, 1);
            Api.eventsDelete(item).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    showItem(item) {
      event.stopPropagation();
      if (!this.$can(["events.advanced"]) && item.date_options != null)
        this.$router.push({ name: "eventshow", params: { id: item.id } });
      else
        this.$router.push({ name: "eventadvanced", params: { id: item.id } });
    },
    editItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "eventedit", params: { id: item.id } });
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  },
  components: {
    Confirm
  }
};
</script>

<style></style>
