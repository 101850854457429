<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">Meine Daten</h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.thwin_id"
                label="ID"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.first_name"
                label="Vorname"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.last_name"
                label="Nachname"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="user.gender"
                :items="genders"
                item-text="name"
                item-value="val"
                label="Geschlecht"
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                slot="activator"
                v-model="birth_date_t"
                label="Geburtsdatum"
                disabled
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="user.division"
                :items="division"
                item-text="name"
                item-value="id"
                label="Einheit"
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="user.position"
                :items="position"
                :item-text="positionT"
                item-value="id"
                label="Funktion"
                disabled
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="user.user_groups"
                :items="user.user_groups"
                item-text="name"
                item-value="id"
                label="Helfergruppe"
                multiple
                disabled
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.username"
                label="Benutzername"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.mobile"
                label="Handynummer"
                placeholder="+49..."
                :rules="rules.mobile"
                validate-on-blur
              ></v-text-field>
              <v-checkbox
                v-model="privacy.mobile"
                label="Für andere sichtbar"
                color="primary"
                class="mt-0"
                v-mask="masks.mobile"
                type="tel"
                autocomplete="tel"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.phone"
                label="Festnetznummer"
                :rules="rules.phone"
                validate-on-blur
                placeholder="+49..."
                v-mask="masks.phone"
                type="tel"
                autocomplete="tel"
              ></v-text-field>
              <v-checkbox
                v-model="privacy.phone"
                label="Für andere sichtbar"
                color="primary"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.email"
                :rules="rules.email"
                validate-on-blur
                required
                label="E-Mail"
              ></v-text-field>
              <v-checkbox
                v-model="privacy.email"
                label="Für andere sichtbar"
                color="primary"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="user.vegetarian"
                label="Vegetarische Ernährung gewünscht"
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.health_information"
                label="Wichtige Gesundheitsinformationen"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="scbaCheck"
                label="Atemschutz"
                color="primary"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mb-4" v-if="scbaCheck">
            <v-col cols="12" md="3">
              <v-text-field
                slot="activator"
                v-model="next_examination_t"
                label="Gültigkeit G26.3 Untersuchung"
                readonly
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                slot="activator"
                v-model="last_instruction_t"
                label="Letzte jährliche Unterweisung"
                readonly
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                slot="activator"
                v-model="last_excercise_t"
                label="Letzte jährliche Belastungsübung"
                readonly
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                slot="activator"
                v-model="last_deployment_t"
                label="Letzte jährliche Einsatzübung/Einsatz"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="scbaCheck">
            <v-col>
              <v-checkbox
                v-model="cbrn"
                label="CBRN Ausbildung"
                color="primary"
                disabled
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mb-4" v-if="cbrn && scbaCheck">
            <v-col cols="12" md="3">
              <v-text-field
                slot="activator"
                v-model="last_cbrn_deployment_t"
                label="Letzte CBRN Übung/Einsatz"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="driver"
                :items="drivers_licences"
                item-text="name"
                item-value="val"
                label="Führerscheinklasse"
                multiple
                disabled
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="driver.includes('c') || driver.includes('ce')"
            >
              <v-text-field
                slot="activator"
                v-model="driver_date_t"
                label="Ablaufdatum Führerschein"
                readonly
                disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            color="primary"
            large
            class="ml-0"
            :disabled="!valid"
            @click="save()"
            >Speichern</v-btn
          >

          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import { mask } from "vue-the-mask";
var deLocale = require("date-fns/locale/de");
import { mapGetters } from "vuex";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";

export default {
  directives: {
    mask
  },
  data: () => ({
    user: {
      thwin_id: "",
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      birth: "",
      mobile: "",
      phone: "",
      scba: {
        next_examination: "",
        last_instruction: "",
        last_excercise: "",
        last_deployment: "",
        cbrn: false,
        last_cbrn_deployment: ""
      },
      driver: [],
      driver_date: ""
    },
    privacy: {
      email: null,
      mobile: null,
      phone: null
    },
    driver: [],
    driver_date: "",
    scbaCheck: false,
    cbrn: false,
    genders: [
      { name: "Männlich", val: "m" },
      { name: "Weiblich", val: "f" },
      { name: "Divers", val: "x" }
    ],
    loader: false,
    divisions: null,
    positions: null,
    user_groups: null,
    drivers_licences: [
      { name: "B", val: "b" },
      { name: "BE", val: "be" },
      { name: "C", val: "c" },
      { name: "CE", val: "ce" }
    ],
    valid: true,
    rules: {
      required: [v => !!v || "Benötigt"],
      email: [
        v => !!v || "Benötigt",
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Ungültige E-Mail"
      ],
      mobile: [
        v =>
          /(^$|^(\+\d{1,15})$)/.test(v) || "Bitte in folgendem Format: +49..."
      ],
      phone: [
        v =>
          /(^$|^(\+\d{1,15})$)/.test(v) || "Bitte in folgendem Format: +49..."
      ]
    },
    masks: {
      thwin_id: "########",
      mobile: "+###############",
      phone: "+###############"
    }
  }),
  created: function() {
    this.user = JSON.parse(JSON.stringify(this.myuser));
  },
  computed: {
    ...mapGetters({ myuser: "getUser" }),
    birth_date_t: {
      get: function() {
        return this.user.birth
          ? format(this.user.birth, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.birth = value;
      }
    },
    next_examination_t: {
      get: function() {
        return this.user.scba.next_examination
          ? format(this.user.scba.next_examination, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.next_examination = value;
      }
    },
    last_instruction_t: {
      get: function() {
        return this.user.scba.last_instruction
          ? format(this.user.scba.last_instruction, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_instruction = value;
      }
    },
    last_excercise_t: {
      get: function() {
        return this.user.scba.last_excercise
          ? format(this.user.scba.last_excercise, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_excercise = value;
      }
    },
    last_deployment_t: {
      get: function() {
        return this.user.scba.last_deployment
          ? format(this.user.scba.last_deployment, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_deployment = value;
      }
    },
    last_cbrn_deployment_t: {
      get: function() {
        return this.user.scba.last_cbrn_deployment
          ? format(this.user.scba.last_cbrn_deployment, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_cbrn_deployment = value;
      }
    },
    driver_date_t: {
      get: function() {
        return this.driver_date
          ? format(this.driver_date, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.driver_date = value;
      }
    },
    division() {
      return [this.user.division];
    },
    position() {
      return [this.user.position];
    }
  },
  watch: {
    birth_date_menu(val) {
      val && setTimeout(() => (this.$refs.birth_date.activePicker = "YEAR"));
    },
    myuser() {
      this.user = JSON.parse(JSON.stringify(this.myuser));
    },
    user() {
      const vm = this;
      if (vm.user.driver == null) {
        vm.driver = [];
      } else {
        vm.driver_date = vm.user.driver.expiry_date;
        let e = [];
        if (vm.user.driver.b) e.push("b");
        if (vm.user.driver.be) e.push("be");
        if (vm.user.driver.c) e.push("c");
        if (vm.user.driver.ce) e.push("ce");
        vm.driver = e;
      }
      if (vm.user.privacy == null) {
        vm.privacy = {
          email: null,
          mobile: null,
          phone: null
        };
      } else {
        vm.privacy = {
          email: vm.user.privacy.email,
          mobile: vm.user.privacy.mobile,
          phone: vm.user.privacy.phone
        };
      }
      if (vm.user.scba == null) {
        vm.user.scba = {
          next_examination: "",
          last_instruction: "",
          last_excercise: "",
          last_deployment: "",
          cbrn: false,
          last_cbrn_deployment: ""
        };
      } else {
        vm.scbaCheck = true;
        if (vm.user.scba.cbrn) vm.cbrn = vm.user.scba.cbrn;
      }
      if (vm.user.mobile == null) {
        vm.user.mobile = "";
      }
      if (vm.user.phone == null) {
        vm.user.phone = "";
      }
    }
  },
  methods: {
    save() {
      const vm = this;
      if (this.$refs.form.validate()) {
        this.loader = true;
        let newUser = {
          username: vm.user.username,
          mobile: vm.user.mobile,
          phone: vm.user.phone,
          email: vm.user.email,
          vegetarian: vm.user.vegetarian,
          health_information: vm.user.health_information,
          privacy: vm.privacy
        };
        Api.selfUpdate(newUser).then(response => {
          this.$store.commit("USER_SUCCESS", response.data.data);
          vm.loader = false;
          vm.$router.push({ name: "home" }, () => {
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          });
        });
      }
    },
    positionT: function(item) {
      const vm = this;
      switch (vm.user.gender) {
        case "m":
          return item.male;
        case "f":
          return item.female;
        case "x":
          return item.inter;
        default:
          return item.inter;
      }
    }
  }
};
</script>

<style></style>
