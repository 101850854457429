<template>
  <v-row>
    <v-col cols="12">
      <v-card-title>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <template v-slot:[`activator`]="{ on }">
            <v-btn outlined color="primary" v-on="on">Erstellen</v-btn>
          </template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-skeleton-loader :loading="dialogloader" type="article, actions">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                          :rules="rules.required"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="editedItem.short_name"
                          label="Abkürzung"
                          :rules="rules.required"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editedItem.users"
                          label="Helfer"
                          :items="users"
                          item-text="'${data.item.first_name} ${data.item.last_name}'"
                          item-value="id"
                          multiple
                          chips
                          :filter="customFilter"
                          :search-input.sync="searchUsers"
                          @change="searchUsers = ''"
                          auto-select-first
                        >
                          <template v-slot:[`selection`]="data">
                            <v-chip
                              :input-value="data.selected"
                              close
                              class="chip--select-multi"
                              @click:close="removeSelected(data.item)"
                            >
                              {{ data.item.first_name }}
                              {{ data.item.last_name }}
                            </v-chip>
                          </template>
                          <template v-slot:[`item`]="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content
                                v-text="data.item"
                              ></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="
                                    `${data.item.first_name} ${data.item.last_name}`
                                  "
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close">Abbrechen</v-btn>
                  <v-btn color="primary" text @click="save">Speichern</v-btn>
                </v-card-actions>
              </v-card>
            </v-skeleton-loader>
          </v-form>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @update:page="goTop()"
        :headers="headers"
        :items="divisions"
        :search="search"
        :loading="loader"
        sort-by="name"
        @click:row="editItem"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn text icon small @click="editItem(item)">
            <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <v-btn text icon small @click="deleteItem(item)">
            <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <Confirm ref="confirm"></Confirm>
  </v-row>
</template>

<script>
import _ from "lodash";
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiPencil, mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  data: () => ({
    loader: false,
    dialog: false,
    dialogloader: false,
    valid: true,
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name"
      },
      {
        text: "Abkürzung",
        align: "left",
        value: "short_name"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    divisions: [],
    users: [],
    searchUsers: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      short_name: "",
      users: []
    },
    defaultItem: {
      name: "",
      short_name: "",
      users: []
    },
    rules: {
      required: [v => !!v || "Benötigt"]
    },
    icons: { mdiPencil, mdiDelete, mdiMagnify }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neue Einheit" : "Einheit Bearbeiten";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: function() {
    this.loadDivisions();
    this.loadUsers();
  },
  methods: {
    loadDivisions: function() {
      this.loader = true;
      const vm = this;
      Api.divisions()
        .then(function(response) {
          vm.divisions = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = _.orderBy(
          response.data.data,
          ["last_name", "first_name"],
          ["asc", "asc"]
        );
      });
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.divisions.indexOf(item);
      const vm = this;
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm)
            Api.divisionsDelete(item).then(() => {
              vm.divisions.splice(index, 1);
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
              vm.$emit("divisonChanged");
            });
        });
    },
    editItem(item) {
      const vm = this;
      vm.dialog = true;
      vm.dialogloader = true;
      event.stopPropagation();
      Api.divisionsGet(item.id).then(function(response) {
        vm.editedIndex = vm.divisions.indexOf(item);
        vm.editedItem = response.data.data;
        vm.editedItem.users = _.orderBy(
          response.data.data.users,
          ["last_name", "first_name"],
          ["asc", "asc"]
        );
        vm.dialogloader = false;
      });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const vm = this;
      const id = this.editedIndex;
      if (this.$refs.form.validate()) {
        if (typeof this.editedItem.users[0] === "object") {
          var ids = [];
          this.editedItem.users.forEach(element => {
            ids.push(element.id);
          });
          this.editedItem.users = ids;
        }
        if (this.editedIndex > -1) {
          Api.divisionsEdit(this.editedItem).then(response => {
            Object.assign(this.divisions[id], response.data.data);
            vm.$emit("divisonChanged");
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          });
        } else {
          Api.divisionsCreate(this.editedItem).then(response => {
            vm.divisions.push(response.data.data);
            vm.$emit("divisonChanged");
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          });
        }
        this.close();
      }
    },
    customFilter(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    removeSelected(item) {
      let index = -1;
      if (typeof this.editedItem.users[0] === "object")
        index = _.findIndex(this.editedItem.users, item);
      else index = this.editedItem.users.indexOf(item.id);
      this.editedItem.users.splice(index, 1);
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  },
  components: {
    Confirm
  }
};
</script>

<style></style>
