import EventBus from "./eventBus";
import router from "./router";

function handleError(error) {
  var message = "";

  if (typeof error == "undefined") {
    return Promise.reject(error);
  }

  // No response object. Try to get message directly from error.
  if (typeof error.response == "undefined") {
    if (error.hasOwnProperty("message")) {
      message = error.message;
      emitError(message);
    }
    return Promise.reject(error);
  }

  if (error.response.status == 401) {
    // 401 - Unauthenticated errors are handled by another interceptor
    return Promise.reject(error);
  }

  if (error.response.status == 403) {
    // Display unauthorized page
    router.push({ name: "403" });
  }

  if (error.response.status == 404) {
    // Display not found page
    router.push({ name: "404" });
  }

  let tmpMsg = messageFromResponse(error.response);
  if (tmpMsg != null) {
    message = tmpMsg;
  }

  // Use generic error message
  if (message == "") {
    message = getGenericErrorMessage(error.response.status);
  }

  emitError(message);
  return Promise.reject(error);
}

function getGenericErrorMessage(status) {
  if (status == 500) {
    return "Server Fehler.";
  }
  if (status == 503) {
    return "Wartungsarbeiten! Bitte Versuche es später erneut.";
  }
}

function messageFromResponse(response) {
  if (!response.hasOwnProperty("data")) {
    return null;
  }

  if (
    !response.data.hasOwnProperty("message") ||
    response.data.message.length <= 0
  ) {
    return null;
  }

  if (!response.data.hasOwnProperty("errors")) {
    return response.data.message;
  }

  var message = "";
  for (let val of Object.entries(response.data.errors).values()) {
    message += val[1] + " ";
  }
  return message;
}

function emitError(message) {
  if (message != "") {
    EventBus.$emit("error", message);
  }
}

export default handleError;
