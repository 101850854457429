<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Benutzer</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            {{ activated }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="last_name"
          >
            <template v-slot:[`item.activated`]="{ item }">
              <v-btn
                v-if="!item.activated && $can('notifications.administration')"
                text
                icon
                small
                @click="activate(item)"
              >
                <v-icon color="primary">{{ icons.mdiSend }}</v-icon>
              </v-btn>
              <v-btn v-if="!item.activated" text icon small>
                <v-icon color="primary">{{ icons.mdiClose }}</v-icon>
              </v-btn>
              <v-btn v-if="item.activated" text icon small>
                <v-icon color="primary">{{ icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiMagnify, mdiSend, mdiClose, mdiCheck } from "@mdi/js";

export default {
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "Vorname",
        align: "left",
        value: "first_name"
      },
      {
        text: "Nachname",
        align: "left",
        value: "last_name"
      },
      {
        text: "Benutzername",
        align: "left",
        value: "username"
      },
      {
        text: "Aktivierung",
        align: "right",
        value: "activated"
      }
    ],
    icons: { mdiMagnify, mdiSend, mdiClose, mdiCheck }
  }),
  created: function() {
    this.loadActivations();
  },
  methods: {
    loadActivations: function() {
      this.loader = true;
      const vm = this;
      Api.adminActivations()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    activate(item) {
      Api.notificationsCreate("/activation/" + item.id).then(() => {
        EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
      });
    }
  },
  computed: {
    activated() {
      return (
        this.data.filter(h => h.activated).length +
        "/" +
        this.data.length +
        " Benutzern aktiviert"
      );
    }
  }
};
</script>
