<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">
          {{ archiveSwitch ? "Aufgaben" : "Erledigte Aufgaben" }}
        </h1>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-card-title class="pa-0">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row justify="start">
                    <v-col cols="auto">
                      <v-btn
                        v-if="$can('tasks.general')"
                        outlined
                        color="primary"
                        :to="{ name: 'taskcreate' }"
                        >Erstellen</v-btn
                      >
                    </v-col>
                    <v-col cols="auto">
                      <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="600px"
                        v-if="$can('tasks.general')"
                      >
                        <template v-slot:[`activator`]="{ on }">
                          <v-btn outlined color="primary" v-on="on"
                            >Kategorien Verwalten</v-btn
                          >
                        </template>
                        <v-card>
                          <v-card-title>
                            <span
                              v-if="!this.$vuetify.breakpoint.smAndDown"
                              class="text-h5"
                              >Kategorien</span
                            >
                            <v-spacer
                              v-if="!this.$vuetify.breakpoint.smAndDown"
                            ></v-spacer>
                            <v-tabs
                              centered
                              v-model="tab"
                              slider-color="primary"
                            >
                              <v-tab>Erstellen</v-tab>
                              <v-tab>Bearbeiten</v-tab>
                            </v-tabs>
                          </v-card-title>

                          <v-tabs-items v-model="tab">
                            <v-tab-item>
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-form
                                      v-model="validNew"
                                      lazy-validation
                                      ref="formNew"
                                    >
                                      <v-text-field
                                        v-model="categoryNewName"
                                        :rules="rules.required"
                                        color="primary"
                                        label="Name"
                                        required
                                      ></v-text-field>
                                    </v-form>
                                  </v-col>
                                </v-row>
                              </v-container>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="dialog = false"
                                  >Schließen</v-btn
                                >

                                <v-btn
                                  :disabled="!validNew"
                                  color="primary"
                                  text
                                  v-shortkey.once="['enter']"
                                  @shortkey="createCategory"
                                  @click="createCategory"
                                  >Erstellen</v-btn
                                >
                              </v-card-actions>
                            </v-tab-item>
                            <v-tab-item>
                              <v-form
                                v-model="valid"
                                lazy-validation
                                ref="form"
                              >
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-form
                                        v-model="validDelete"
                                        lazy-validation
                                        ref="formDelete"
                                      >
                                        <v-autocomplete
                                          :rules="rules.required"
                                          auto-select-first
                                          :items="manageCategories"
                                          v-model="changeCategory"
                                          label="Kategorie"
                                          @change="setNameChange"
                                          item-text="name"
                                          item-value="id"
                                        ></v-autocomplete>
                                      </v-form>
                                    </v-col>
                                    <v-col>
                                      <v-text-field
                                        v-model="categoryName"
                                        :rules="rules.required"
                                        color="primary"
                                        label="Neuer Name"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-container>

                                <v-card-actions style="flex-wrap: wrap">
                                  <v-spacer
                                    v-if="!this.$vuetify.breakpoint.smAndDown"
                                  ></v-spacer>
                                  <v-btn
                                    color="primary"
                                    :class="
                                      $vuetify.breakpoint.smAndDown
                                        ? 'ml-2'
                                        : ''
                                    "
                                    text
                                    @click="dialog = false"
                                    >Schließen</v-btn
                                  >
                                  <v-btn
                                    :disabled="!validDelete"
                                    color="primary"
                                    text
                                    @click="deleteCategory"
                                    >Löschen</v-btn
                                  >
                                  <v-btn
                                    :disabled="!valid"
                                    color="primary"
                                    text
                                    v-shortkey.once="['enter']"
                                    @shortkey="updateCategory"
                                    @click="updateCategory"
                                    >Speichern</v-btn
                                  >
                                </v-card-actions>
                              </v-form>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-card>
                      </v-dialog>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :append-icon="icons.mdiMagnify"
                    label="Filter"
                    single-line
                    hide-details
                    @input="updateUrl"
                    v-model="search"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    auto-select-first
                    :items="categories"
                    label="Kategorie"
                    item-text="name"
                    item-value="name"
                    @input="updateUrl"
                    v-model="categoryFilterValue"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : ''"
                >
                  <v-select
                    :items="participationtype"
                    label="Teilnahmestatus"
                    @input="updateUrl"
                    v-model="participationFilterValue"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>

          <v-data-table
            @update:page="goTop()"
            :headers="computedHeaders"
            :items="computedTasks"
            item-key="id"
            :search="search"
            :loading="loader"
            sort-by="duedate"
            @click:row="editItem"
          >
            <template v-slot:[`item.priority`]="{ item }">
              <v-icon v-if="item.priority == 1" color="red">
                {{ icons.mdiExclamation }}
              </v-icon>
            </template>
            <template v-slot:[`item.duedate`]="{ item }">
              <duedate :value="item.duedate"></duedate>
              <v-icon
                v-if="compareDate >= item.duedate"
                color="red"
                class="ml-2"
                >{{ icons.mdiClockAlertOutline }}</v-icon
              >
            </template>
            <template v-slot:[`item.state`]="{ item }">
              {{ item.state != null ? item.state : "-" }} %
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <span>
                {{ item.count }}
                <v-icon
                  small
                  v-if="item.participationtype > 0"
                  color="primary"
                  class="ml-2"
                  >{{ icons.mdiMarkerCheck }}</v-icon
                >
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <span v-if="$can('tasks.general')">
                <v-btn
                  text
                  icon
                  small
                  :to="{ name: 'taskedit', params: { id: item.id } }"
                >
                  <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
                </v-btn>
                <v-btn text icon small @click="deleteItem(item)">
                  <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </span>
            </template>
          </v-data-table>
          <Confirm ref="confirm"></Confirm>
        </v-card>
      </v-col>
    </v-row>

    <v-bottom-navigation :value="bottomNav" color="primary">
      <v-btn
        @click="
          archiveSwitch = true;
          loadTasks();
        "
      >
        <span>Aktuelle Aufgaben</span>
        <v-icon>{{ icons.mdiProgressClock }}</v-icon>
      </v-btn>

      <v-btn
        @click="
          archiveSwitch = false;
          loadTasks();
        "
      >
        <span>Erledigte Aufgaben</span>
        <v-icon>{{ icons.mdiArchive }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import _ from "lodash";
import {
  mdiExclamation,
  mdiClockAlertOutline,
  mdiMarkerCheck,
  mdiPencil,
  mdiDelete,
  mdiProgressClock,
  mdiArchive,
  mdiMagnify
} from "@mdi/js";

export default {
  components: {
    Confirm,
    duedate: {
      props: ["value"],
      template: "<span v-html='duedate'></span>",
      computed: {
        duedate() {
          return this.value
            ? format(this.value, "dddd, Do MMMM YYYY", {
                locale: deLocale
              })
            : "";
        }
      }
    }
  },
  data: () => ({
    data: [],
    dialog: false,
    archiveSwitch: true,
    bottomNav: 0,
    compareDate: format(new Date(), "YYYY-MM-DD"),
    user: {},
    loader: false,
    valid: true,
    validDelete: true,
    validNew: true,
    search: "",
    categoryFilterValue: null,
    participationFilterValue: null,
    categories: [],
    rules: {
      required: [v => !!v || "Benötigt"]
    },
    categoryName: null,
    categoryNewName: null,
    tab: null,
    manageCategories: [],
    changeCategory: null,
    participationtype: [
      "Alle",
      "Teilnehmend",
      "Verantwortlich",
      "Teilnehmend oder Verantwortlich"
    ],
    headers: [
      {
        text: "Titel",
        align: "left",
        value: "title"
      },
      {
        text: "Prio",
        icon: "priority_high",
        align: "center",
        value: "priority"
      },
      {
        text: "Fälligkeit",
        align: "left",
        value: "duedate"
      },
      {
        text: "Status",
        align: "left",
        value: "state"
      },
      {
        text: "Teilnehmer",
        icon: "group",
        align: "left",
        value: "users_count",
        hide: "mit"
      },

      {
        text: "Teilnehmer",
        icon: "group",
        align: "right",
        value: "users_count",
        hide: "ohne"
      },

      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action",
        hide: "mit"
      }
    ],
    icons: {
      mdiExclamation,
      mdiClockAlertOutline,
      mdiMarkerCheck,
      mdiPencil,
      mdiDelete,
      mdiProgressClock,
      mdiArchive,
      mdiMagnify
    }
  }),

  created: function() {
    this.loadTasks();
    this.loadCategories();
  },
  mounted: function() {
    this.search = this.$route.query.search;
    this.categoryFilterValue = this.$route.query.categorie;
    this.participationFilterValue = this.$route.query.participation;
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        h =>
          !h.hide ||
          (this.$can("tasks.general") && h.hide == "mit") ||
          (!this.$can("tasks.general") && h.hide == "ohne")
      );
    },
    computedTasks() {
      return this.data.filter(
        v => this.categoryFilter(v) && this.participationFilter(v)
      );
    }
  },

  methods: {
    categoryFilter(item) {
      const vm = this;
      return (
        !vm.categoryFilterValue ||
        vm.categoryFilterValue === "Alle" ||
        (item.task_category &&
          vm.categoryFilterValue === item.task_category.name)
      );
    },
    participationFilter(item) {
      const vm = this;
      if (
        !vm.participationFilterValue ||
        vm.participationFilterValue === "Alle"
      ) {
        return true;
      }
      if (
        vm.participationFilterValue === "Teilnehmend oder Verantwortlich" &&
        item.participationtype > 0
      ) {
        return true;
      }
      if (
        vm.participationFilterValue === "Teilnehmend" &&
        (item.participationtype == 1 || item.participationtype == 2)
      ) {
        return true;
      }
      if (
        vm.participationFilterValue === "Verantwortlich" &&
        item.participationtype >= 2
      ) {
        return true;
      }
      return false;
    },
    setNameChange() {
      const vm = this;
      vm.manageCategories.forEach(element => {
        if (element.id == vm.changeCategory) {
          vm.categoryName = element.name;
        }
      });
    },
    deleteCategory() {
      if (this.$refs.formDelete.validate()) {
        const vm = this;
        const index = this.changeCategory;
        this.$refs.confirm
          .open(
            "Löschen",
            "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
            { color: "red" }
          )
          .then(confirm => {
            if (confirm) {
              Api.taskCategoryDelete(vm.changeCategory).then(() => {
                vm.categories = _.filter(vm.categories, function(o) {
                  return o.id !== index;
                });
                vm.manageCategories = _.filter(vm.manageCategories, function(
                  o
                ) {
                  return o.id !== index;
                });
                vm.dialog = false;
                vm.changeCategory = null;
                vm.categoryName = null;
                EventBus.$emit(
                  "showSnackbar",
                  "Erfolgreich gelöscht!",
                  "primary"
                );
              });
            }
          });
      }
    },
    createCategory() {
      if (this.$refs.formNew.validate()) {
        const vm = this;
        Api.taskCategoryCreate(vm.categoryNewName).then(response => {
          vm.manageCategories.unshift(response.data.data);
          vm.categories.unshift(response.data.data);
          vm.categoryNewName = null;
          EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          vm.dialog = false;
        });
      }
    },
    updateCategory() {
      if (this.$refs.form.validate()) {
        const vm = this;
        Api.taskCategoryUpdate(vm.changeCategory, vm.categoryName).then(
          response => {
            vm.categories.forEach(element => {
              if (element.id == vm.changeCategory) {
                element.name = response.data.data.name;
              }
            });
            vm.manageCategories.forEach(element => {
              if (element.id == vm.changeCategory) {
                element.name = response.data.data.name;
              }
            });
            vm.categoryName = "";
            vm.dialog = false;
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          }
        );
      }
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      const vm = this;
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            Api.taskDelete(item.id).then(function() {
              vm.data.splice(index, 1);
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    editItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "taskedit", params: { id: item.id } });
    },
    loadTasks: function() {
      this.loader = true;
      const vm = this;
      if (this.archiveSwitch) {
        Api.task()
          .then(function(response) {
            vm.data = response.data.data;
            vm.loader = false;
          })
          .catch(() => {
            vm.loader = false;
          });
      } else {
        Api.taskGetArchive()
          .then(function(response) {
            vm.data = response.data.data;
            vm.loader = false;
          })
          .catch(() => {
            vm.loader = false;
          });
      }
    },
    loadCategories: function() {
      const vm = this;
      Api.taskCategoryGet().then(function(response) {
        vm.categories = response.data.data;
        vm.manageCategories = vm.categories.slice();
        let alle = {
          id: 999,
          name: "Alle"
        };

        vm.categories.unshift(alle);
      });
    },
    loadCurrentUser: function() {
      const vm = this;
      Api.me().then(function(response) {
        vm.user = response.data.data;
      });
    },
    updateUrl() {
      this.$router.replace({
        name: "task",
        query: {
          search: this.search,
          categorie: this.categoryFilterValue,
          participation: this.participationFilterValue
        }
      });
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  }
};
</script>

<style></style>
