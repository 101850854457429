<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Benutzerrollen</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn outlined color="primary" :to="{ name: 'rolecreate' }"
              >Erstellen</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @update:page="goTop()"
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="name"
            @click:row="editItem"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                text
                icon
                small
                :to="{ name: 'roleedit', params: { id: item.id } }"
              >
                <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
              </v-btn>
              <v-btn text icon small @click="deleteItem(item)">
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <Confirm ref="confirm"></Confirm>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiPencil, mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    data: [],
    icons: { mdiPencil, mdiDelete, mdiMagnify }
  }),
  created: function() {
    this.loadRoles();
  },
  methods: {
    loadRoles: function() {
      this.loader = true;
      const vm = this;
      Api.roles().then(function(response) {
        vm.data = response.data.data;
        vm.loader = false;
      });
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.rolesDelete(item).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    editItem(item) {
      this.$router.push({ name: "roleedit", params: { id: item.id } });
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  }
};
</script>

<style></style>
