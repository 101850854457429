var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor",class:{
    darkActive: this.$vuetify.theme.dark
  }},[_c('v-card',[_c('v-card-title',[_c('editor-menu-bar',{staticClass:"pb-4",attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var commands = ref.commands;
  var isActive = ref.isActive;
return _c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(_vm.toggle_multiple),callback:function ($$v) {_vm.toggle_multiple=$$v},expression:"toggle_multiple"}},[_c('v-btn',{class:{ 'is-active': isActive.bold() },attrs:{"text":""},on:{"click":commands.bold}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatBold))])],1),_c('v-btn',{class:{ 'is-active': isActive.italic() },attrs:{"text":""},on:{"click":commands.italic}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatItalic))])],1),_c('v-btn',{staticClass:"hidden-sm-and-down",class:{ 'is-active': isActive.strike() },attrs:{"text":""},on:{"click":commands.strike}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatStrikethrough))])],1),_c('v-btn',{class:{ 'is-active': isActive.underline() },attrs:{"text":""},on:{"click":commands.underline}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatUnderline))])],1)],1)}}])}),_c('v-divider',{staticClass:"mx-2 mb-4",attrs:{"vertical":""}}),_c('editor-menu-bar',{staticClass:"pb-4",attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var commands = ref.commands;
  var isActive = ref.isActive;
return _c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(_vm.toggle_multiple1),callback:function ($$v) {_vm.toggle_multiple1=$$v},expression:"toggle_multiple1"}},[_c('v-btn',{staticClass:"hidden-sm-and-down",class:{ 'is-active': isActive.paragraph() },attrs:{"text":""},on:{"click":commands.paragraph}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatTextdirectionLToR))])],1),_c('v-btn',{class:{ 'is-active': isActive.heading({ level: 1 }) },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 1 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatHeader1))])],1),_c('v-btn',{class:{ 'is-active': isActive.heading({ level: 2 }) },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatHeader2))])],1),_c('v-btn',{class:{ 'is-active': isActive.heading({ level: 3 }) },attrs:{"text":""},on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatHeader3))])],1),(_vm.withTodo)?_c('v-btn',{class:{ 'is-active': isActive.todo_list() },attrs:{"text":""},on:{"click":commands.todo_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatListChecks))])],1):_vm._e()],1)}}])}),_c('v-divider',{staticClass:"mx-2 mb-4",attrs:{"vertical":""}}),_c('editor-menu-bar',{staticClass:"pb-4",attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var commands = ref.commands;
  var isActive = ref.isActive;
return _c('v-btn-toggle',{attrs:{"multiple":""},model:{value:(_vm.toggle_multiple2),callback:function ($$v) {_vm.toggle_multiple2=$$v},expression:"toggle_multiple2"}},[_c('v-btn',{class:{ 'is-active': isActive.bullet_list() },attrs:{"text":""},on:{"click":commands.bullet_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatListBulleted))])],1),_c('v-btn',{class:{ 'is-active': isActive.ordered_list() },attrs:{"text":""},on:{"click":commands.ordered_list}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFormatListNumbered))])],1),_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"text":""},on:{"click":commands.horizontal_rule}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMinus))])],1),_c('v-btn',{attrs:{"text":""},on:{"click":commands.undo}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUndo))])],1),_c('v-btn',{attrs:{"text":""},on:{"click":commands.redo}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRedo))])],1)],1)}}])})],1),_c('v-card-text',{on:{"click":function($event){!_vm.editor.view.focused ? _vm.editor.focus() : ''}}},[_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }