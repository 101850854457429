<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Anwesenheit</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-slot:[`activator`]="{ on }">
                <v-btn outlined color="primary" v-on="on">Einloggen</v-btn>
              </template>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Helfer einloggen</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="loginUser"
                            label="Helfer"
                            :items="notPresentUsers"
                            item-text="'${data.item.first_name} ${data.item.last_name}'"
                            item-value="id"
                            :filter="customFilter"
                            :rules="rules.required"
                            required
                            auto-select-first
                            @keydown.enter.native.prevent
                          >
                            <template slot="item" slot-scope="data">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="
                                    `${data.item.first_name} ${data.item.last_name}`
                                  "
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <template slot="selection" slot-scope="data">
                              <span>
                                {{ data.item.first_name }}
                                {{ data.item.last_name }}
                              </span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false"
                      >Schließen</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="login(loginUser)"
                      >Einloggen</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @update:page="goTop()"
            :headers="headers"
            :items="presentUsers"
            :search="search"
            :loading="loader"
            no-data-text="Niemand anwesend"
            sort-by="last_name"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon small @click="logout(item)">
                <v-icon color="primary">{{ icons.mdiExitRun }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <Confirm ref="confirm"></Confirm>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiExitRun, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "Vorname",
        align: "left",
        value: "first_name"
      },
      {
        text: "Nachname",
        align: "left",
        value: "last_name"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    icons: { mdiExitRun, mdiMagnify },
    loginUser: "",
    searchUsers: "",
    dialog: false,
    valid: false,
    rules: {
      required: [v => !!v || "Benötigt."]
    }
  }),
  created: function() {
    this.loadPresence();
  },
  computed: {
    presentUsers() {
      return this.data.filter(user => user.present === true);
    },
    notPresentUsers() {
      return this.data.filter(user => user.present === false);
    }
  },
  methods: {
    loadPresence: function() {
      this.loader = true;
      const vm = this;
      Api.presence()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    login(item) {
      if (this.$refs.form.validate()) {
        Api.presenceEdit(item, { present: true }).then(response => {
          this.data.push(response.data.data);
          this.dialog = false;
          this.loginUser = "";
          EventBus.$emit("showSnackbar", "Eingeloggt!", "primary");
        });
      }
    },
    logout(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      Api.presenceEdit(item.id, { present: false }).then(response => {
        Object.assign(this.data[index], response.data.data);
        EventBus.$emit("showSnackbar", "Ausgeloggt!", "primary");
      });
    },
    customFilter(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  }
};
</script>

<style></style>
