import Vue from "vue";
import Router from "vue-router";
import store from "./store";

// Components
import Home from "@/views/Home.vue";
import Login from "@/views/Login";
import Register from "@/views/Register";
import PasswordReset from "@/views/PasswordReset";
import UserOverview from "@/views/User/Overview";
import UserEdit from "@/views/User/UserEdit";
import Meeting from "@/views/Meeting/Meeting";
import MeetingEdit from "@/views/Meeting/MeetingEdit";
import MeetingShow from "@/views/Meeting/MeetingShow";
import Doodle from "@/views/Meeting/Doodle";
import DoodleShow from "@/views/Meeting/DoodleShow";
import Event from "@/views/Event/Event";
import EventAdvanced from "@/views/Event/EventAdvanced";
import EventEdit from "@/views/Event/EventEdit";
import EventShow from "@/views/Event/EventShow";
import Admin from "@/views/Admin/Admin";
import Activations from  "@/views/Admin/Activations";
import FailedJobs from "@/views/Admin/FailedJobs";
import FeedbackRecipients from "@/views/Admin/FeedbackRecipients";
import FeedbackTemplates from "@/views/Admin/FeedbackTemplates";
import OAuthClients from "@/views/Admin/OAuthClients";
import Role from "@/views/Role/Role";
import RoleEdit from "@/views/Role/RoleEdit";
import Task from "@/views/Task/Task";
import TaskEdit from "@/views/Task/TaskEdit";
import Presence from "@/views/Presence/Presence";
import Mail from "@/views/Mail/Mail";
import MailEdit from "@/views/Mail/MailEdit";
import Qualification from "@/views/Qualification/Qualification";
import QualificationShow from "@/views/Qualification/QualificationShow";
import UserShow from "@/views/User/UserShow";
import Barcode from "@/views/User/Barcode";
import Calendar from "@/views/User/Calendar";
import NotAllowed  from "@/views/NotAllowed";
import PageNotFound from "@/views/PageNotFound";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/home");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Home"
      }
    },
    { path: "/", redirect: { name: "home" } },
    {
      path: "/login",
      name: "login",
      component: Login,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Login"
      }
    },
    {
      path: "/registrieren/:user_id/:token",
      name: "register",
      component: Register,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Registrierung"
      }
    },
    {
      path: "/passwort_zuruecksetzen/:user_id/:reminder_code",
      name: "passwordreset",
      component: PasswordReset,
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Passwort zurücksetzen"
      }
    },
    {
      path: "/helfer",
      name: "user",
      component: UserOverview,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Helferverwaltung"
      }
    },
    {
      path: "/helfer/erstellen",
      name: "usercreate",
      component: UserEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Helfer erstellen"
      }
    },
    {
      path: "/helfer/:id",
      name: "useredit",
      component: UserEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Helfer bearbeiten"
      }
    },
    {
      path: "/dienste",
      name: "meeting",
      component: Meeting,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Dienste"
      }
    },
    {
      path: "/dienste/erstellen",
      name: "meetingcreate",
      component: MeetingEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Dienst erstellen"
      }
    },
    {
      path: "/dienste/:id",
      name: "meetingedit",
      component: MeetingEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Dienste bearbeiten"
      }
    },
    {
      path: "/dienste/erweitert/:id",
      name: "meetingshow",
      component: MeetingShow,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Erweiterte Dienstansicht"
      }
    },
    {
      path: "/doodle_uebersicht",
      name: "doodle",
      component: Doodle,
      meta: {
        title: "Doodle"
      }
    },
    {
      path: "/doodle/:id",
      name: "doodleshow",
      component: DoodleShow,
      meta: {
        title: "Dienst eintragen"
      }
    },
    {
      path: "/doodle/:id/:token",
      name: "doodleshowtoken",
      component: DoodleShow,
      meta: {
        title: "Dienst Eintragen"
      }
    },
    {
      path: "/abfragen",
      name: "event",
      component: Event,
      meta: {
        title: "Abfragen"
      }
    },
    {
      path: "/abfragen/erstellen",
      name: "eventcreate",
      component: EventEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Abfrage erstellen"
      }
    },
    {
      path: "/abfragen/bearbeiten/:id",
      name: "eventedit",
      component: EventEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Abfrage bearbeiten"
      }
    },
    {
      path: "/abfragen/erweitert/:id",
      name: "eventadvanced",
      component: EventAdvanced,
      meta: {
        title: "Abfrage auswerten"
      }
    },
    {
      path: "/abfragen/:id",
      name: "eventshow",
      component: EventShow,
      meta: {
        title: "Abfrage eintragen"
      }
    },
    {
      path: "/abfragen/:id/:token",
      name: "eventshowtoken",
      component: EventShow,
      meta: {
        title: "Abfrage eintragen"
      }
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Administration"
      }
    },
    {
      path: "/admin/aktivierungen",
      name: "activations",
      component: Activations,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Benutzer"
      }
    },
    {
      path: "/admin/fehlgeschlagen",
      name: "failedjobs",
      component: FailedJobs,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Jobübersicht"
      }
    },
    {
      path: "/admin/feedback_empfaenger",
      name: "feedbackrecipients",
      component: FeedbackRecipients,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Feedback Empfänger"
      }
    },
    {
      path: "/admin/feedback_vorlagen",
      name: "feedbacktemplates",
      component: FeedbackTemplates,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Feedback Vorlagen"
      }
    },
    {
      path: "/admin/oauth_clients",
      name: "oauthclients",
      component: OAuthClients,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Clients"
      }
    },
    {
      path: "/admin/rollen",
      name: "role",
      component: Role,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Rollenverwaltung"
      }
    },
    {
      path: "/admin/rollen/erstellen",
      name: "rolecreate",
      component: RoleEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Rolle erstellen"
      }
    },
    {
      path: "/admin/rollen/:id",
      name: "roleedit",
      component: RoleEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Rolle bearbeiten"
      }
    },
    {
      path: "/aufgaben",
      name: "task",
      component: Task,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Aufgaben"
      }
    },
    {
      path: "/aufgaben/erstellen",
      name: "taskcreate",
      component: TaskEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Aufgabe erstellen"
      }
    },
    {
      path: "/aufgaben/:id",
      name: "taskedit",
      component: TaskEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Aufgabe bearbeiten"
      }
    },
    {
      path: "/anwesenheit",
      name: "presence",
      component: Presence,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Anwesenheit"
      }
    },
    {
      path: "/mails",
      name: "mail",
      component: Mail,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Mails"
      }
    },
    {
      path: "/mails/:id",
      name: "mailedit",
      component: MailEdit,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Mail senden"
      }
    },
    {
      path: "/qualifikationen",
      name: "qualification",
      component: Qualification,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Qualifikationen"
      }
    },
    {
      path: "/qualifikationen/:id",
      name: "qualificationshow",
      component: QualificationShow,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Qualifikationen"
      }
    },
    {
      path: "/meine_daten",
      name: "usershow",
      component: UserShow,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Meine Daten"
      }
    },
    {
      path: "/mein_barcode",
      name: "barcode",
      component: Barcode,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Barcode"
      }
    },
    { path: "/mein_code", redirect: { name: "barcode" } },
    {
      path: "/kalender",
      name: "calendar",
      component: Calendar,
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Kalender"
      }
    },
    {
      path: "/403",
      name: "403",
      component: NotAllowed
    },
    { path: "*", name: "404", component: PageNotFound }
  ]
});
