<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">
          Feedback-Empfänger
        </h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn outlined color="primary" @click="create">Erstellen</v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="email"
            no-data-text="Keine Empfänger vorhanden"
          >
            <template v-slot:[`item.email`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.email"
                @save="save(props.item)"
                :rules="rules.email"
                large
                cancel-text="Abbrechen"
                save-text="Speichern"
              >
                {{ props.item.email }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.email"
                    :rules="rules.required"
                    label="Bearbeiten"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon small @click="deleteItem(item)">
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent width="1000">
      <v-card>
        <v-card-title class="text-h5 primary white--text" primary-title
          >Empfänger Erstellen</v-card-title
        >
        <v-card-text class="mt-5">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              v-model="editedItem.email"
              :rules="rules.email"
              label="E-Mail"
              required
              @keydown.enter.native.prevent="save(editedItem)"
            ></v-text-field>
            <v-row class="mt-3">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  large
                  :disabled="!valid"
                  @click="save(editedItem)"
                  >Speichern</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn outlined color="primary" large @click="dialog = false"
                  >Abbrechen</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirm"></Confirm>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "Email",
        align: "left",
        value: "email"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    icons: { mdiDelete, mdiMagnify },
    rules: {
      email: [
        v => !!v || "Benötigt",
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Ungültige E-Mail"
      ]
    },
    editedItem: {},
    dialog: false,
    valid: false
  }),
  created: function() {
    this.loadFeedbackRecipients();
  },
  methods: {
    loadFeedbackRecipients() {
      this.loader = true;
      const vm = this;
      Api.adminFeedbackRecipients()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.adminFeedbackRecipientsDelete(item.id).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    show(item) {
      this.dialog = true;
      this.editedItem = item;
    },
    save(item) {
      const vm = this;
      if (item.id) {
        Api.adminFeedbackRecipientsEdit(item.id, { email: item.email }).then(
          () => {
            EventBus.$emit("showSnackbar", "Mail geändert!", "primary");
          }
        );
      } else {
        if (this.$refs.form.validate()) {
          Api.adminFeedbackRecipientsCreate(item).then(response => {
            vm.data.push(response.data.data);
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          });
        }
      }
      vm.dialog = false;
    },
    create() {
      this.show({ email: "" });
    }
  }
};
</script>
