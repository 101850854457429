<template>
  <div>
    <v-file-input
      chips
      truncate-length="15"
      multiple
      label="Dateien Hochladen"
      @change="uploadFiles"
      v-model="uploadingFiles"
      :loading="loading"
      :disabled="disabled"
    ></v-file-input>
  </div>
</template>
<script>
import Api from "@/services/ApiService.js";
import _ from "lodash";

export default {
  name: "FileUpload",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      uploadingFiles: null,
    };
  },
  methods: {
    uploadFiles() {
      const vm = this;
      vm.uploadingFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file, file.name);
        const source = Api.fileCancelToken(); // TODO Cancel Upload
        Api.filesUpload(formData, source)
          .then((response) => {
            var file = response.data.data;
            file.isTemporary = true;
            vm.$emit("file-uploaded", file);
          })
          .catch(function(error) {
            vm.$emit("showSnackbar", error);
          })
          .finally(function() {
            vm.uploadingFiles = vm.uploadingFiles.filter(function(
              value,
              index,
              arr
            ) {
              return value !== file;
            });
          });
      });
    },
  },
  watch: {},
  computed: {
    loading() {
      return !!this.uploadingFiles && this.uploadingFiles.length > 0;
    },
  },
};
</script>
<style scoped></style>
