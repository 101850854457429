<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="mb-12" cols="12" md="6">
            <v-btn
              v-if="!this.$store.getters.isAuthenticated"
              :to="{
                name: 'doodle'
              }"
              outlined
              color="primary"
              class="mb-2"
            >
              <span>Dienstübersicht</span>
            </v-btn>
            <v-skeleton-loader :loading="loading" type="article">
              <v-card class="mx-auto" color="primary" dark>
                <v-card-title>
                  <v-container class="pa-0">
                    <v-row>
                      <v-col cols="auto">
                        <v-icon large left>{{ icons.mdiCalendar }}</v-icon>
                        <span
                          class="text-h4 font-weight-light"
                          style="word-break: break-word"
                          >{{ meeting.title }}</span
                        >
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto">
                        <span class="text-body-1">{{ date_t }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="hidden-print-only">
                      <v-col cols="auto">
                        <v-btn
                          class="mr-3"
                          v-if="$can(['meetings.general', 'meetings.advanced'])"
                          :to="{
                            name: 'meetingshow',
                            params: { id: meeting.id || 0 }
                          }"
                          right
                          outlined
                          small
                        >
                          <span>Erweiterte-Ansicht</span>
                        </v-btn>
                        <v-btn
                          v-if="$can('meetings.general')"
                          :to="{
                            name: 'meetingedit',
                            params: { id: meeting.id || 0 }
                          }"
                          right
                          outlined
                          small
                        >
                          <span>Bearbeiten</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>

                <v-card-text
                  class="text-subtitle-1 px-4 white--text link-color"
                  v-html="description_t"
                  >Keine Beschreibung vorhanden</v-card-text
                >

                <v-card-actions class="px-0">
                  <v-list-item class="grow">
                    <v-list-item-content
                      align-center
                      @click.stop="dialog = true"
                    >
                      <span>
                        <v-icon class="mr-2">{{
                          icons.mdiInformationOutline
                        }}</v-icon>
                        <span style="vertical-align: middle">
                          {{
                            meeting.responsible != null
                              ? meeting.responsible.first_name +
                                " " +
                                meeting.responsible.last_name
                              : "Kein Verantwortlicher"
                          }}
                        </span>
                      </span>
                    </v-list-item-content>
                    <v-icon class="mr-1">{{ icons.mdiCheck }}</v-icon>
                    <span class="text-subtitle-1 mr-2">{{ yes }}</span>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1">{{ icons.mdiRadioboxBlank }}</v-icon>
                    <span class="text-subtitle-1">{{ maybe }}</span>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-row justify="center">
              <v-col class="mb-1 hidden-print-only" cols="12">
                <v-row align="end" justify="center">
                  <v-skeleton-loader :loading="loading" type="heading">
                    <p class="text-h5 font-weight-black pb-1 pr-4">
                      {{ name_t }}
                    </p>
                  </v-skeleton-loader>
                  <v-select
                    :disabled="hasBegoneOrDeadlinePassed"
                    v-model="meeting.user.attendance"
                    @input="save()"
                    :items="attendances"
                    item-text="name"
                    item-value="val"
                    label="Teilnahme"
                    style="max-width:100px"
                  ></v-select>
                </v-row>
              </v-col>
              <v-col
                class="mt-4 hidden-print-only"
                cols="12"
                sm="8"
                v-if="hasBegoneOrDeadlinePassed"
              >
                <span style="color:red">
                  Die Deadline zum Eintragen ist
                  {{
                    this.registerUntilDateText != ""
                      ? "am " + this.registerUntilDateText
                      : ""
                  }}
                  abgelaufen. Wenn sich etwas geändert hat oder du zu spät dran
                  bist, wende dich bitte an den Dienstverantwortlichen oder an
                  die für dich verantwortliche Führungskraft.
                </span>
              </v-col>
              <v-col
                class="mt-4"
                cols="12"
                sm="8"
                v-if="meeting.register_until && !isDeadlinePassed"
              >
                <span>
                  Bitte trage dich bis zum
                  {{ this.registerUntilDateText }} ein.
                </span>
              </v-col>
              <v-col
                class="mb-1 hidden-print-only"
                cols="12"
                sm="8"
                v-if="meeting.user.attendance && meeting.comment"
              >
                <v-text-field
                  :disabled="hasBegoneOrDeadlinePassed"
                  v-model="meeting.user.comment"
                  v-if="meeting.user.attendance && meeting.comment"
                  v-on:change="save"
                  label="Kommentar"
                  required
                ></v-text-field>
              </v-col>

              <v-col class="mt-4" cols="12" sm="8">
                <v-card>
                  <v-list>
                    <v-list-item v-if="meeting.users.length == 0">
                      <v-list-item-content>
                        <v-list-item-title
                          >Noch keine Teilnehmer!</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="(user, index) in sortedUsers">
                      <v-list-item
                        :key="user.id"
                        :three-line="longComment && user.comment != null"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user.first_name }}
                            {{ user.last_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            :v-if="user.comment"
                            @click="switchComment()"
                            >{{ user.comment }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <meetingIcon :value="user.attendance"></meetingIcon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider
                        v-if="index + 1 < meeting.users.length"
                        :key="'k' + index"
                      ></v-divider>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-dialog v-model="dialog" max-width="600" v-if="meeting.responsible">
        <v-card class="primary">
          <v-row class="mx-0">
            <v-card-title class="white--text pl-md-12">
              <div
                class="text-h4 pl-md-6 pt-md-12"
                style="word-break: break-word"
              >
                {{
                  meeting.responsible.first_name +
                    " " +
                    meeting.responsible.last_name
                }}
              </div>
            </v-card-title>
          </v-row>

          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-icon color="primary">{{
                  icons.mdiAccountBadgeHorizontal
                }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  meeting.responsible.position
                }}</v-list-item-title>
                <v-list-item-subtitle>Funktion</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="meeting.responsible.division">
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  meeting.responsible.division.name
                }}</v-list-item-title>
                <v-list-item-subtitle>Einheit</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="
                meeting.responsible.mobile != null ||
                  meeting.responsible.phone != null
              "
              inset
            ></v-divider>

            <v-list-item
              v-if="meeting.responsible.mobile != null"
              :href="'tel:' + meeting.responsible.mobile"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ icons.mdiPhone }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  meeting.responsible.mobile
                }}</v-list-item-title>
                <v-list-item-subtitle>Mobil</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="meeting.responsible.phone != null"
              :href="'tel:' + meeting.responsible.phone"
            >
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  meeting.responsible.phone
                }}</v-list-item-title>
                <v-list-item-subtitle>Festnetz</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider
              v-if="meeting.responsible.email != null"
              inset
            ></v-divider>

            <v-list-item
              v-if="meeting.responsible.email != null"
              :href="'mailto:' + meeting.responsible.email"
            >
              <v-list-item-action>
                <v-icon color="primary">{{ icons.mdiEmail }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{
                  meeting.responsible.email
                }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import _ from "lodash";
import isPast from "date-fns/is_past";
import isToday from "date-fns/is_today";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import { VIcon } from "vuetify/lib";
import EventBus from "@/eventBus";
import {
  mdiCalendar,
  mdiInformationOutline,
  mdiCheck,
  mdiRadioboxBlank,
  mdiAccountBadgeHorizontal,
  mdiPhone,
  mdiEmail,
  mdiClose,
  mdiPlus
} from "@mdi/js";

export default {
  data: () => ({
    loading: true,
    token: "",
    meeting: {
      title: "",
      start: "",
      end: "",
      register_until: "",
      description: "",
      comment: false,
      user_groups: [],
      user: {
        attendance: ""
      },
      users: []
    },
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    attendance: "",
    attendances: [
      { name: "Ja", val: "yes" },
      { name: "Nein", val: "no" },
      { name: "Vielleicht", val: "maybe" }
    ],
    longComment: false,
    dialog: false,
    icons: {
      mdiCalendar,
      mdiInformationOutline,
      mdiCheck,
      mdiRadioboxBlank,
      mdiAccountBadgeHorizontal,
      mdiPhone,
      mdiEmail
    }
  }),
  created: function() {
    this.token = localStorage.doodletoken;
    this.loadMeeting();
  },
  mounted: function() {
    if (this.$route.params.token) {
      localStorage.doodletoken = this.$route.params.token;
      this.token = localStorage.doodletoken;
    } else {
      this.token = localStorage.doodletoken;
    }
    if (this.$store.getters.isAuthenticated) {
      localStorage.doodletoken = "";
    }
  },
  computed: {
    isDeadlinePassed() {
      if (this.meeting.register_until) {
        return (
          isPast(new Date(this.meeting.register_until)) &&
          !isToday(new Date(this.meeting.register_until))
        );
      } else return false;
    },
    registerUntilDateText() {
      if (this.meeting.register_until) {
        return format(this.meeting.register_until, "dd, Do MMMM YYYY", {
          locale: deLocale
        });
      } else return "";
    },
    date_t() {
      if (this.start_date == this.end_date) {
        return (
          format(this.meeting.start, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          }) +
          " - " +
          format(this.meeting.end, "HH:mm", {
            locale: deLocale
          })
        );
      } else {
        return (
          format(this.meeting.start, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          }) +
          " - " +
          format(this.meeting.end, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          })
        );
      }
    },
    name_t() {
      return this.meeting.user
        ? this.meeting.user.first_name + " " + this.meeting.user.last_name
        : "";
    },
    description_t() {
      return this.meeting.description
        ? this.meeting.description
        : "Keine Beschreibung vorhanden";
    },
    yes() {
      return _.filter(this.meeting.users, function(user) {
        return user.attendance === "yes";
      }).length;
    },
    maybe() {
      return _.filter(this.meeting.users, function(user) {
        return user.attendance === "maybe";
      }).length;
    },
    sortedUsers() {
      return _.orderBy(
        this.meeting.users,
        [
          function(element) {
            var rank = {
              yes: 1,
              maybe: 2,
              no: 3
            };
            return rank[element.attendance];
          },
          "last_name",
          "first_name"
        ],
        ["asc", "asc", "asc"]
      );
    },
    hasBegone() {
      return isPast(new Date(this.meeting.start));
    },
    hasBegoneOrDeadlinePassed() {
      return this.hasBegone || this.isDeadlinePassed;
    }
  },
  methods: {
    loadMeeting() {
      const vm = this;
      vm.id = this.$route.params.id;
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodleShow(vm.id, vm.token).then(function(response) {
        vm.meeting = response.data.data;
        var start = response.data.data.start.split(" ");
        vm.start_date = start[0];
        vm.start_time = start[1].substring(0, 5);
        var end = response.data.data.end.split(" ");
        vm.end_date = end[0];
        vm.end_time = end[1].substring(0, 5);
        vm.loading = false;
      });
    },
    save() {
      const vm = this;
      let newDoodle = {
        id: vm.meeting.user.id,
        attendance: vm.meeting.user.attendance,
        comment: vm.meeting.user.comment
      };
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodleEdit(vm.id, newDoodle, vm.token).then(response => {
        vm.meeting = response.data.data;
      });
    },
    switchComment() {
      this.longComment = !this.longComment;
    }
  },
  components: {
    meetingIcon: {
      props: ["value"],
      components: {
        VIcon
      },
      template:
        '<v-icon :color="meetingColorComputed">{{ meetingIconComputed }}</v-icon>',
      computed: {
        meetingIconComputed() {
          return this.value ? this.icon(this.value) : mdiPlus;
        },
        meetingColorComputed() {
          return this.value ? this.color(this.value) : "primary";
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiPlus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "primary";
          }
        }
      }
    }
  }
};
</script>

<style>
.link-color > p > a {
  color: white;
}
</style>
