<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Clients</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn outlined color="primary" @click="create">Erstellen</v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="name"
          >
            <template v-slot:[`item.name`]="props">
              <v-edit-dialog
                :return-value.sync="props.item.name"
                @save="save(props.item)"
                large
                cancel-text="Abbrechen"
                save-text="Speichern"
              >
                {{ props.item.name }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.name"
                    :rules="rules.required"
                    label="Bearbeiten"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.token`]="{ item }">
              <v-btn text icon small @click="show(item)">
                <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
              </v-btn>
              <v-btn class="ml-2" text icon small @click="copy(item)">
                <v-icon color="primary">{{ icons.mdiContentCopy }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon small @click="deleteItem(item)">
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent width="1000">
      <v-card>
        <v-card-title class="text-h5 primary white--text" primary-title
          >Fernseher Erstellen</v-card-title
        >
        <v-card-text class="mt-5">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-text-field
              v-model="editedItem.name"
              :rules="rules.required"
              label="Name"
              required
              @keydown.enter.native.prevent="save(editedItem)"
            ></v-text-field>
            <editor v-model="editedItem.template" style="mt-1" />
            <v-row class="mt-3">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  large
                  :disabled="!valid"
                  @click="save(editedItem)"
                  >Speichern</v-btn
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn outlined color="primary" large @click="dialog = false"
                  >Abbrechen</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog1" width="500">
      <v-card>
        <v-card-title class="text-h5 primary white--text" primary-title
          >Token</v-card-title
        >
        <v-card-text>
          <p class="mt-5">{{ secret }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Confirm ref="confirm"></Confirm>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiEye, mdiContentCopy, mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name"
      },
      {
        text: "Token",
        align: "left",
        value: "token"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    icons: { mdiEye, mdiContentCopy, mdiDelete, mdiMagnify },
    dialog1: false,
    secret: "",
    rules: {
      required: [v => !!v || "Benötigt"]
    },
    editedItem: {},
    dialog: false,
    valid: false
  }),
  created: function() {
    this.loadOAuthClients();
  },
  methods: {
    loadOAuthClients() {
      this.loader = true;
      const vm = this;
      Api.adminOAuthClients()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    show(item) {
      this.dialog1 = true;
      this.secret = item.secret;
    },
    copy(item) {
      this.$clipboard(item.secret);
      EventBus.$emit("showSnackbar", "Token kopiert!", "primary");
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.adminOAuthClientsDelete(item.id).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    save(item) {
      const vm = this;
      if (item.id) {
        Api.adminOAuthClientsEdit(item.id, { name: item.name }).then(() => {
          EventBus.$emit("showSnackbar", "Name geändert!", "primary");
        });
      } else {
        if (this.$refs.form.validate()) {
          Api.adminOAuthClientsCreate({ name: item.name }).then(response => {
            vm.data.push(response.data.data);
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
          });
        }
      }
      vm.dialog = false;
    },
    create() {
      this.dialog = true;
      this.editedItem = {};
    }
  }
};
</script>
