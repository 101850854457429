<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">
          Gescheiterte Jobs
        </h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn outlined color="primary" @click="deleteAll"
              >Alle löschen</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="id"
            sort-desc
            @click:row="showJob"
          >
            <template v-slot:[`item.payload`]="{ item }">{{
              item.payload.split('"maxTries"')[0] + "..."
            }}</template>
            <template v-slot:[`item.exception`]="{ item }">{{
              item.exception.split("Stack trace:")[0]
            }}</template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn text icon small @click="deleteItem(item)">
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <Confirm ref="confirm"></Confirm>
    </v-row>

    <v-dialog v-model="dialog" width="1000">
      <v-card>
        <v-card-title class="text-h5 primary white--text" primary-title
          >Job</v-card-title
        >
        <v-card-text class="mt-5">
          <p>
            <b>Job:</b>
            <br />
            {{ job.id }}
          </p>
          <p>
            <b>Connection:</b>
            <br />
            {{ job.connection }}
          </p>
          <p>
            <b>Queue:</b>
            <br />
            {{ job.queue }}
          </p>
          <p>
            <b>Payload:</b>
            <br />
            {{ job.payload }}
          </p>
          <p>
            <b>Exception:</b>
            <br />
            {{ job.exception }}
          </p>
          <p>
            <b>Failed at:</b>
            <br />
            {{ job.failed_at }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "id"
      },
      {
        text: "Exception",
        align: "left",
        value: "exception"
      },
      {
        text: "Payload",
        align: "left",
        value: "payload"
      },
      {
        text: "Fehlgeschlagen",
        align: "left",
        value: "failed_at"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    dialog: false,
    job: {},
    icons: { mdiDelete, mdiMagnify }
  }),
  created: function() {
    this.loadFailedJobs();
  },
  methods: {
    loadFailedJobs: function() {
      this.loader = true;
      const vm = this;
      Api.adminFailedJobs()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    showJob(item) {
      this.job = item;
      this.dialog = true;
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.adminFailedJobsDelete(item).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    deleteAll() {
      event.stopPropagation();
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Die Elemente können nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data = [];
            Api.adminFailedJobsDeleteAll().then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    }
  }
};
</script>
