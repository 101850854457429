<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation xs12>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">
            {{ page_title }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row v-if="hasTitle">
            <v-col class="pb-4" cols="12" md="6">
              <v-text-field
                v-model="title"
                :rules="rules.required"
                label="Betreff"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="hasBody">
            <v-col cols="12">
              <p class="v-label v-label--active ml-1 mb-2" style="opacity: 0.7">
                Inhalt
              </p>
              <editor v-model="body" style="mt-1" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-row v-if="hasMeeting">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="meeting"
                    label="Dienst"
                    :items="meetings"
                    item-value="id"
                    clearable
                    :filter="customFilterMeeting"
                    auto-select-first
                    :rules="rules.required"
                    required
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <meetingDate :value="data.item.start"></meetingDate>
                          {{ data.item.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <meetingDate :value="data.item.start"></meetingDate>
                      {{ data.item.title }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="pt-2">
                <v-col cols="12">
                  <p class="text-h6">Empfänger auswählen:</p>
                </v-col>
              </v-row>
              <v-row v-if="hasEvent">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="event"
                    label="Event"
                    :items="events"
                    item-value="id"
                    clearable
                    :filter="customFilterEvent"
                    auto-select-first
                    :rules="rules.required"
                    required
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.title }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="hasMeetings">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="meeting"
                    label="Dienste"
                    :items="meetings"
                    item-value="id"
                    multiple
                    chips
                    :filter="customFilterMeeting"
                    :search-input.sync="searchMeetings"
                    @change="searchMeetings = ''"
                    auto-select-first
                  >
                    <template v-slot:[`selection`]="data">
                      <v-chip
                        :input-value="data.input"
                        close
                        class="chip--select-multi"
                        @click:close="removeMeeting(data.item)"
                      >
                        <meetingDate :value="data.item.start"></meetingDate>
                        {{ data.item.title }}
                      </v-chip>
                    </template>
                    <template v-slot:[`item`]="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>
                            <meetingDate :value="data.item.start"></meetingDate>
                            {{ data.item.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="mb-2" v-if="hasMeetings && meeting != null">
                <v-col cols="12">
                  <p>Mit folgenden Anwesenheiten</p>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="yes"
                    label="Ja"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="9">
                  <v-checkbox
                    v-model="no"
                    label="Nein"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="maybe"
                    label="Vielleicht"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="not"
                    label="Nicht eingetragen"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row v-if="hasGroup">
                <v-col cols="12">
                  <v-autocomplete
                    auto-select-first
                    v-model="group"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    label="Helfergruppen"
                    multiple
                    :search-input.sync="searchGroups"
                    @change="searchGroups = ''"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="hasUser">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="user"
                    label="Helfer"
                    :items="users"
                    item-text="'${data.item.first_name} ${data.item.last_name}'"
                    item-value="id"
                    :disabled="toAll"
                    clearable
                    :filter="customFilterUser"
                    auto-select-first
                    :rules="rules.required"
                    required
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.first_name }}
                          {{ data.item.last_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template slot="selection" slot-scope="data">
                      <span>
                        {{ data.item.first_name }}
                        {{ data.item.last_name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="hasUsers">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="user"
                    label="Helfer"
                    :items="users"
                    item-text="'${data.item.first_name} ${data.item.last_name}'"
                    item-value="id"
                    :disabled="toAll"
                    chips
                    multiple
                    :filter="customFilterUser"
                    :search-input.sync="searchUsers"
                    @change="searchUsers = ''"
                    auto-select-first
                  >
                    <template v-slot:[`selection`]="data">
                      <v-chip
                        v-if="!toAll"
                        :input-value="data.input"
                        close
                        class="chip--select-multi"
                        @click:close="removeUser(data.item)"
                      >
                        {{ data.item.first_name }}
                        {{ data.item.last_name }}
                      </v-chip>
                      <v-chip v-if="toAll">An alle</v-chip>
                    </template>
                    <template v-slot:[`item`]="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              `${data.item.first_name} ${data.item.last_name}`
                            "
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="hasMany">
                  <v-checkbox
                    v-model="toAll"
                    label="An alle entsprechenden"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="mb-2" v-if="toAll">
                <v-col cols="12">
                  <p>Mit folgenden Anwesenheiten</p>
                </v-col>
                <v-col cols="12" md="3" v-if="hasMeeting">
                  <v-checkbox
                    v-model="yes"
                    label="Ja"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="9" v-if="hasMeeting">
                  <v-checkbox
                    v-model="no"
                    label="Nein"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3" v-if="hasMeeting">
                  <v-checkbox
                    v-model="maybe"
                    label="Vielleicht"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="3">
                  <v-checkbox
                    v-model="not"
                    label="Nicht eingetragen"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="mb-2" v-if="hasSendSummary">
                <v-col cols="12">
                  <v-checkbox
                    v-model="sendSummary"
                    label="Zusammenfassung schicken"
                    color="primary"
                    style="margin-top: 0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row v-if="hasAttachment">
                <v-col cols="12">
                  <p class="text-h6">Anhänge:</p>
                </v-col>
                <v-col cols="12">
                  <FileUploadDownload v-model="files" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                color="primary"
                class="ml-0"
                large
                :disabled="!valid"
                @click="save()"
              >
                Senden
                <v-icon right dark>{{ icons.mdiSend }}</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="primary"
                large
                @click="$router.push({ name: 'mail' })"
                >Abbrechen</v-btn
              >
            </v-col>
          </v-row>

          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Editor from "@/components/Editor";
import Api from "@/services/ApiService.js";
import _ from "lodash";
import EventBus from "@/eventBus";
import { mdiSend } from "@mdi/js";
import FileUploadDownload from "../../components/Files/FileUploadDownload.vue";

export default {
  data: () => ({
    hasTitle: false,
    hasBody: false,
    hasMeeting: false,
    hasEvent: false,
    hasMeetings: false,
    hasUser: false,
    hasUsers: false,
    hasMany: false,
    hasGroup: false,
    hasAttachment: false,
    hasSendSummary: false,
    title: "",
    body: "",
    user: null,
    toAll: false,
    maybe: false,
    yes: false,
    no: false,
    not: false,
    users: [],
    group: null,
    groups: [],
    meeting: null,
    meetings: [],
    event: null,
    events: [],
    loader: false,
    valid: false,
    sendSummary: true,
    searchMeetings: "",
    searchGroups: "",
    searchUsers: "",
    files: [],
    rules: {
      required: [(v) => !!v || "Benötigt."],
    },
    icons: { mdiSend },
  }),
  computed: {
    page_title() {
      return this.pageTitle();
    },
  },
  created: function() {
    this.loadGroups();
    this.loadUsers();
    this.loadMeetings();
    this.loadEvents();
  },
  methods: {
    pageTitle() {
      switch (this.$route.params.id) {
        case "dienst_erinnerung":
          this.hasMeeting = true;
          this.hasUsers = true;
          this.hasMany = true;
          return "Mail: Dienst Erinnerung";
        case "abfrage_erinnerung":
          this.hasEvent = true;
          this.hasUsers = true;
          this.hasMany = true;
          return "Mail: Dienst Erinnerung";
        case "aktivierung":
          this.hasUser = true;
          return "Mail: Aktivierung";
        case "passwort_zurücksetzen":
          this.hasUser = true;
          return "Mail: Passwort zurücksetzen";
        case "schreibe_mail":
          this.hasTitle = true;
          this.hasBody = true;
          this.hasGroup = true;
          this.hasMeetings = true;
          this.hasUsers = true;
          this.hasAttachment = true;
          this.hasSendSummary = true;
          return "Mail: Verfassen";
        default:
          return "Fehler";
      }
    },

    loadGroups: function() {
      const vm = this;
      Api.userGroups()
        .then(function(response) {
          vm.groups = response.data.data;
          vm.groups = _.sortBy(vm.groups, ["name"]);
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = response.data.data;
        vm.users = _.sortBy(vm.users, ["last_name", "first_name"]);
      });
    },
    loadMeetings: function() {
      const vm = this;
      Api.meetings().then(function(response) {
        vm.meetings = response.data.data;
      });
    },
    loadEvents: function() {
      const vm = this;
      Api.events().then(function(response) {
        vm.events = response.data.data;
      });
    },
    save() {
      const vm = this;
      if (this.$refs.form.validate()) {
        if (this.$refs.pond && this.$refs.pond._pond.status == 3) {
          vm.$emit(
            "showSnackbar",
            "Bitte Warte bis alle Dateien Hochgeladen wurden!"
          );
        }
        if (this.$refs.pond && this.$refs.pond._pond.status == 2) {
          vm.$emit(
            "showSnackbar",
            "Es ist ein Fehler beim hochladen einer Datei aufgetreten. Bitte kontroliere deine Uploads!"
          );
        }
        this.loader = true;
        let newMail = {};
        var route = "";
        switch (vm.$route.params.id) {
          case "dienst_erinnerung":
            if (vm.user == null && vm.toAll == false) {
              EventBus.$emit("showSnackbar", "Wähle einen Empfänger!");
              vm.loader = false;
              return false;
            }
            if (vm.toAll && !vm.yes && !vm.no && !vm.maybe && !vm.not) {
              EventBus.$emit("showSnackbar", "Wähle einen Empfänger!");
              vm.loader = false;
              return false;
            }
            route = "/meeting/" + vm.meeting;
            if (vm.user == null) {
              vm.user = [];
            }
            newMail.users = vm.user;
            newMail.to_all = vm.toAll;
            newMail.maybe = vm.maybe;
            newMail.yes = vm.yes;
            newMail.no = vm.no;
            newMail.not_registered = vm.not;
            break;
          case "abfrage_erinnerung":
            if (vm.user == null && vm.toAll == false) {
              EventBus.$emit("showSnackbar", "Wähle einen Empfänger!");
              vm.loader = false;
              return false;
            }
            route = "/event/" + vm.event;
            if (vm.user == null) {
              vm.user = [];
            }
            newMail.users = vm.user;
            newMail.to_all = vm.toAll;
            newMail.only_missing_registration = vm.not;
            break;
          case "aktivierung":
            route = "/activation/" + vm.user;
            newMail = null;
            break;
          case "passwort_zurücksetzen":
            route = "/reminder/" + vm.user;
            newMail = null;
            break;
          case "schreibe_mail":
            if (vm.body == "") {
              EventBus.$emit("showSnackbar", "Inhalt muss ausgefüllt sein!");
              vm.loader = false;
              return false;
            }
            if (vm.user == null && vm.group == null && vm.meeting == null) {
              EventBus.$emit("showSnackbar", "Wähle einen Empfänger!");
              vm.loader = false;
              return false;
            }
            route = "/custom";
            newMail.subject = vm.title;
            newMail.body = vm.body;
            newMail.users = vm.user;
            newMail.user_groups = vm.group;
            newMail.meetings = vm.meeting;
            newMail.maybe = vm.maybe;
            newMail.yes = vm.yes;
            newMail.no = vm.no;
            newMail.not_registered = vm.not;
            newMail.files = _.map(vm.files, "id");
            newMail.send_summary = vm.sendSummary;
            break;
          default:
            break;
        }
        Api.notificationsCreate(route, newMail)
          .then(() => {
            vm.files.forEach((element) => {
              element.isTemporary = false;
            });

            vm.loader = false;
            vm.$router.push({ name: "mail" }, () => {
              EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
            });
          })
          .catch(() => {
            vm.loader = false;
          });
      }
    },
    customFilterMeeting(item, queryText) {
      const textOne = item.title.toLowerCase();
      const textTwo = format(item.start, "dd, Do MMMM YYYY HH:mm", {
        locale: deLocale,
      }).toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    customFilterEvent(item, queryText) {
      const textOne = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilterUser(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    removeMeeting(item) {
      const index = this.meeting.indexOf(item.id);
      if (index >= 0) this.meeting.splice(index, 1);
    },
    removeUser(item) {
      const index = this.user.indexOf(item.id);
      if (index >= 0) this.user.splice(index, 1);
    },
  },
  components: {
    Editor,
    FileUploadDownload,
    meetingDate: {
      props: ["value"],
      template: "<span>{{meetingStartDate}}&nbsp</span>",
      computed: {
        meetingStartDate() {
          return this.value
            ? format(this.value, "dd, Do MMMM YYYY HH:mm", {
                locale: deLocale,
              })
            : "";
        },
      },
    },
  },
};
</script>

<style>
.addPointer {
  cursor: pointer;
}
</style>
