<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Passwort zurücksetzen</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                v-model="credentials.password"
                required
                v-on:keyup.enter="reset()"
                :rules="rules.password"
                validate-on-blur
                :prepend-icon="icons.mdiLock"
                name="password"
                label="Passwort"
                id="password"
                type="password"
                autocomplete="new-password"
              ></v-text-field>
              <v-text-field
                v-model="credentials.password_confirmation"
                v-on:keyup.enter="reset()"
                required
                :rules="passwordConfirmationRule"
                validate-on-blur
                :prepend-icon="icons.mdiLock"
                name="password_confirmation"
                label="Passwort bestätigen"
                id="password_confirmation"
                type="password"
                autocomplete="new-password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="reset" :disabled="!valid" color="primary"
              >Zurücksetzen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-4">
          Bitte warten!
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiLock } from "@mdi/js";

export default {
  data: () => ({
    credentials: {
      password: "",
      password_confirmation: ""
    },
    valid: true,
    loader: false,
    rules: {
      required: [v => !!v || "Benötigt"],
      password: [
        v => !!v || "Benötigt",
        v => v.length >= 8 || "Mindestens 8 Zeichen"
      ]
    },
    icons: { mdiLock }
  }),
  methods: {
    reset() {
      if (this.$refs.form.validate()) {
        this.credentials["reminder_code"] = this.$route.params.reminder_code;
        this.credentials["user"] = this.$route.params.user_id;
        Api.resetPassword(this.credentials).then(() => {
          // TODO: Show success
          this.$router.push({ name: "home" });
          EventBus.$emit(
            "showSnackbar",
            "Passwort erfolgreich zurückgesetzt!",
            "primary"
          );
        });
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return [
        v => !!v || "Benötigt",
        () =>
          this.credentials.password == this.credentials.password_confirmation ||
          "Passwort muss übereinstimmen"
      ];
    }
  }
};
</script>

<style></style>
