<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="text-h3 font-weight-bold primary--text">
          {{ page_title }}
        </h1>
      </v-col>
      <v-col cols="12" md="4">
        <h1 class="text-h5 text-md-right">Datum: {{ compareDate_t }}</h1>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="scbas.length > 0 && page_title == 'Atemschutz-Tauglichkeit'"
      >
        <p class="text-h5 my-2">Tauglich: {{ scba_able }}</p>
      </v-col>
      <v-col cols="12" md="6" v-if="scbas.length > 0 && page_title == 'CBRN'">
        <p class="text-h5 my-2">Tauglich: {{ cbrn_able }}</p>
      </v-col>
      <v-col class="hidden-print-only" cols="12" md="6">
        <v-menu
          v-model="compareDate_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:[`activator`]="{ on }">
            <v-text-field
              v-on="on"
              v-model="compareDate_t"
              label="Vergleichsdatum"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="compareDate"
            @change="compareDate_menu = false"
            color="primary"
            locale="de-de"
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        class="hidden-print-only"
        v-if="page_title == 'Atemschutz-Tauglichkeit' || page_title == 'CBRN'"
      >
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:[`activator`]="{ on }">
            <v-btn outlined color="primary" v-on="on">Eintragen</v-btn>
          </template>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
              <v-card-title>
                <span class="text-h5">Eintragen</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-checkbox
                        v-model="add.next_examination"
                        label="Untersuchung"
                        color="primary"
                        class="my-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-checkbox
                        v-model="add.last_instruction"
                        label="Belehrung"
                        color="primary"
                        class="my-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-checkbox
                        v-model="add.last_excercise"
                        label="Belastung"
                        color="primary"
                        class="my-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-checkbox
                        v-model="add.last_deployment"
                        label="Einsatzübung"
                        color="primary"
                        class="my-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-checkbox
                        v-model="add.last_cbrn_deployment"
                        label="CBRN-Übung"
                        color="primary"
                        class="my-0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:[`activator`]="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="date_t"
                            label="Datum"
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="add.date"
                          @input="date_menu = false"
                          color="primary"
                          locale="de-de"
                          first-day-of-week="1"
                          :scrollable="true"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="add.users"
                        label="Helfer"
                        :items="users"
                        item-text="'${data.item.first_name} ${data.item.last_name}'"
                        item-value="id"
                        multiple
                        chips
                        :filter="customFilter"
                        :search-input.sync="searchUsers"
                        @change="searchUsers = ''"
                        auto-select-first
                      >
                        <template v-slot:[`selection`]="data">
                          <v-chip
                            :input-value="data.input"
                            close
                            class="chip--select-multi"
                            @click:close="removeSelected(data.item)"
                          >
                            {{ data.item.first_name }}
                            {{ data.item.last_name }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item`]="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="
                                  `${data.item.first_name} ${data.item.last_name}`
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Abbrechen</v-btn>
                <v-btn color="primary" :disabled="!valid" text @click="save"
                  >Speichern</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-col>
      <v-col cols="12">
        <v-card class="py-1 px-4">
          <v-col cols="12">
            <template v-if="page_title == 'Atemschutz-Tauglichkeit'">
              <template v-for="(item, index) in scbas">
                <v-row :key="item.user.id" class="pt-2">
                  <v-col class="text-h5 py-0" cols="12" md="6"
                    >{{ item.user.first_name }} {{ item.user.last_name }}</v-col
                  >
                  <v-col
                    class="text-h5 py-0"
                    cols="12"
                    md="6"
                    :class="canScba(item) ? 'green--text' : 'red--text'"
                    >{{ canScba(item) ? "Tauglich" : "Untauglich" }}</v-col
                  >
                </v-row>
                <v-row :key="'k' + item.user.id" class="pb-2">
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="3"
                    :class="
                      compareDate <= item.next_examination ? '' : 'red--text'
                    "
                  >
                    Untersuchung:
                    <Date :value="item.next_examination"></Date>
                  </v-col>
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="3"
                    :class="
                      compareDateM1 <= item.last_instruction ? '' : 'red--text'
                    "
                  >
                    Belehrung:
                    <Date :value="item.last_instruction" :yearMod="1"></Date>
                  </v-col>
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="3"
                    :class="
                      compareDateM1 <= item.last_excercise ? '' : 'red--text'
                    "
                  >
                    Belastung:
                    <Date :value="item.last_excercise" :yearMod="1"></Date>
                  </v-col>
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="3"
                    :class="
                      compareDateM1 <= item.last_deployment ? '' : 'red--text'
                    "
                  >
                    Übung:
                    <Date :value="item.last_deployment" :yearMod="1"></Date>
                  </v-col>
                </v-row>
                <v-divider
                  v-if="index + 1 < scbas.length"
                  :key="'d' + index"
                ></v-divider>
              </template>
            </template>
            <template v-if="page_title == 'CBRN'">
              <template v-for="(item, index) in scbas">
                <v-row :key="item.user.id" class="pt-2">
                  <v-col class="text-h5 py-0" cols="12" md="6"
                    >{{ item.user.first_name }} {{ item.user.last_name }}</v-col
                  >
                  <v-col
                    class="text-h5 py-0"
                    cols="12"
                    md="6"
                    :class="
                      canScba(item) &&
                      compareDateM1 <= item.last_cbrn_deployment
                        ? 'green--text'
                        : 'red--text'
                    "
                  >
                    {{
                      canScba(item) && compareDate >= item.last_cbrn_deployment
                        ? "Tauglich"
                        : "Untauglich"
                    }}
                  </v-col>
                </v-row>
                <v-row :key="'k' + item.user.id" class="pb-2">
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="6"
                    :class="canScba(item) ? '' : 'red--text'"
                  >
                    Atemschutz-Tauglichkeit:
                    <Date :value="lowestScba(item)" :yearMod="1"></Date>
                  </v-col>
                  <v-col
                    class="text-subtitle-1 py-0"
                    cols="12"
                    md="6"
                    :class="
                      compareDateM1 <= item.last_cbrn_deployment
                        ? ''
                        : 'red--text'
                    "
                  >
                    CBRN-Übung:
                    <Date
                      :value="item.last_cbrn_deployment"
                      :yearMod="1"
                    ></Date>
                  </v-col>
                </v-row>
                <v-divider
                  v-if="index + 1 < scbas.length"
                  :key="'d' + index"
                ></v-divider>
              </template>
            </template>
            <v-row class="hidden-sm-and-down" v-if="drivers.length >= 1">
              <v-col class="text-h5" cols="12" md="3">B</v-col>
              <v-col class="text-h5" cols="12" md="3">BE</v-col>
              <v-col class="text-h5" cols="12" md="3">C</v-col>
              <v-col class="text-h5" cols="12" md="3">CE</v-col>
            </v-row>
            <v-divider
              v-if="drivers.length >= 1 && !$vuetify.breakpoint.smAndDown"
            ></v-divider>
            <template v-for="(item, index) in drivers">
              <v-row :key="item.user.id">
                <v-col class="text-h5" cols="12" md="6"
                  >{{ item.user.first_name }} {{ item.user.last_name }}</v-col
                >
                <v-col
                  class="text-h5"
                  cols="12"
                  md="6"
                  v-if="item.expiry_date"
                  :class="
                    item.expiry_date >= compareDate
                      ? 'green--text'
                      : 'red--text'
                  "
                >
                  {{
                    item.expiry_date >= compareDate ? "" : "Verlängerung fällig"
                  }}
                </v-col>
                <v-col
                  class="text-h5"
                  cols="12"
                  md="6"
                  v-if="!item.expiry_date"
                ></v-col>
              </v-row>
              <v-row :key="'k' + item.user.id" class="pt-2">
                <v-col class="text-subtitle-1" cols="12" md="3">
                  <span v-if="$vuetify.breakpoint.smAndDown">B</span>
                  <v-icon
                    medium
                    :class="item.b ? 'green--text' : 'red--text'"
                    >{{ item.b ? icons.mdiCheck : icons.mdiClose }}</v-icon
                  >
                </v-col>
                <v-col class="text-subtitle-1" cols="12" md="3">
                  <span v-if="$vuetify.breakpoint.smAndDown">BE</span>
                  <v-icon
                    medium
                    :class="item.be ? 'green--text' : 'red--text'"
                    >{{ item.be ? icons.mdiCheck : icons.mdiClose }}</v-icon
                  >
                </v-col>
                <v-col class="text-subtitle-1" cols="12" md="3">
                  <span v-if="$vuetify.breakpoint.smAndDown">C</span>
                  <v-icon
                    medium
                    :class="
                      item.c && compareDate <= item.expiry_date
                        ? 'green--text'
                        : 'red--text'
                    "
                    v-html="
                      item.c && compareDate <= item.expiry_date
                        ? icons.mdiCheck
                        : icons.mdiClose
                    "
                  ></v-icon>
                  <span v-if="item.c">: bis&nbsp;</span>
                  <Date v-if="item.c" :value="item.expiry_date"></Date>
                </v-col>
                <v-col class="text-subtitle-1" cols="12" md="3">
                  <span v-if="$vuetify.breakpoint.smAndDown">CE</span>
                  <v-icon
                    medium
                    :class="
                      item.ce && compareDate <= item.expiry_date
                        ? 'green--text'
                        : 'red--text'
                    "
                    v-html="
                      item.ce && compareDate <= item.expiry_date
                        ? icons.mdiCheck
                        : icons.mdiClose
                    "
                  ></v-icon>
                  <span v-if="item.ce">: bis&nbsp;</span>
                  <Date v-if="item.ce" :value="item.expiry_date"></Date>
                </v-col>
              </v-row>
              <v-divider
                v-if="index + 1 < drivers.length"
                :key="'d' + index"
              ></v-divider>
            </template>
            <p
              v-if="drivers.length == 0 && scbas.length == 0"
              class="text-subtitle-1 mt-4"
            >
              Keine Helfer mit dieser Qualifikation verfügbar!
            </p>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
var addYears = require("date-fns/add_years");
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiCheck, mdiClose } from "@mdi/js";

export default {
  data: () => ({
    scbas: [],
    drivers: [],
    dialog: false,
    users: [],
    searchUsers: "",
    add: {
      next_examination: false,
      last_instruction: false,
      last_excercise: false,
      last_deployment: false,
      last_cbrn_deployment: false,
      date: "",
      users: undefined,
    },
    types: [
      { name: "B", val: "b" },
      { name: "BE", val: "be" },
      { name: "C", val: "c" },
      { name: "CE", val: "ce" },
    ],
    date_menu: false,
    valid: false,
    rules: {
      required: [(v) => !!v || "Benötigt"],
    },
    compareDate: format(new Date(), "YYYY-MM-DD"),
    compareDate_menu: false,
    icons: { mdiCheck, mdiClose },
  }),
  computed: {
    page_title() {
      switch (this.$route.params.id) {
        case "atemschutz":
          return "Atemschutz-Tauglichkeit";
        case "fahrerlaubnis":
          return "Fahrerlaubnis";
        case "cbrn":
          return "CBRN";
        default:
          return "Fehler";
      }
    },
    compareDateM1() {
      return new Date(
        new Date(this.compareDate).setFullYear(
          new Date(this.compareDate).getFullYear() - 1
        )
      )
        .toISOString()
        .substring(0, 10);
    },
    compareDate_t() {
      return format(this.compareDate, "DD.MM.YYYY");
    },
    scba_able() {
      let i = 0;
      this.scbas.forEach((element) => {
        if (this.canScba(element)) {
          i++;
        }
      });
      return i;
    },
    cbrn_able() {
      let i = 0;
      this.scbas.forEach((element) => {
        if (
          this.canScba(element) &&
          this.compareDateM1 <= element.last_cbrn_deployment
        ) {
          i++;
        }
      });
      return i;
    },
    scba_users() {
      return this.scbas.map((a) => a.user);
    },
    date_t() {
      return this.add.date ? format(this.add.date, "DD.MM.YYYY") : "";
    },
  },
  created: function() {
    switch (this.$route.params.id) {
      case "atemschutz":
        this.loadScbas();
        this.loadUsers();
        break;
      case "fahrerlaubnis":
        this.loadDrivers();
        break;
      case "cbrn":
        this.loadScbas();
        this.loadUsers();
        break;
      default:
        return "Fehler";
    }
  },
  methods: {
    loadScbas: function() {
      const vm = this;
      Api.qualificationsScba().then(function(response) {
        vm.scbas = _.orderBy(
          response.data.data,
          ["user.last_name", "user.first_name"],
          ["asc", "asc"]
        );
      });
    },
    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = _.orderBy(
          response.data.data,
          ["last_name", "first_name"],
          ["asc", "asc"]
        );
      });
    },
    loadDrivers: function() {
      const vm = this;
      Api.qualificationsDriver().then(function(response) {
        vm.drivers = _.orderBy(
          response.data.data,
          ["user.last_name", "user.first_name"],
          ["asc", "asc"]
        );
      });
    },
    canScba(item) {
      if (
        this.compareDate <= item.next_examination &&
        this.compareDateM1 <= item.last_instruction &&
        this.compareDateM1 <= item.last_excercise &&
        this.compareDateM1 <= item.last_deployment
      )
        return true;
      else return false;
    },
    lowestScba(item) {
      return _.min([
        new Date(
          new Date(item.next_examination).setFullYear(
            new Date(item.next_examination).getFullYear() - 1
          )
        ),
        new Date(item.last_instruction),
        new Date(item.last_excercise),
        new Date(item.last_deployment),
      ]);
    },
    canDrive() {
      return true;
    },
    save() {
      const vm = this;
      Api.qualificationsAdd(this.add).then(() => {
        this.loadScbas();
        EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
        vm.close();
      });
    },
    customFilter(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    removeSelected(item) {
      let index = -1;
      if (typeof this.add.users[0] === "object")
        index = _.findIndex(this.add.users, item);
      else index = this.add.users.indexOf(item.id);
      this.add.users.splice(index, 1);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.add = {
          type: "",
          date: "",
          users: undefined,
        };
      }, 300);
    },
  },
  components: {
    Date: {
      props: ["value", "yearMod"],
      template: "<span>{{Date}}</span>",
      computed: {
        Date() {
          return this.value
            ? format(
                addYears(
                  new Date(this.value),
                  typeof this.yearMod !== "undefined" ? this.yearMod : 0
                ),
                "DD.MM.YYYY",
                {
                  locale: deLocale,
                }
              )
            : "";
        },
      },
    },
  },
};
</script>

<style scoped>
@media only print {
  .v-card {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .row .flex {
    flex-basis: unset;
    flex-grow: inherit;
  }
  .flex .text-subtitle-1 {
    font-size: 15px !important;
  }
}
</style>
