<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation xs12>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">
            Dienst {{ this.$route.params.id ? "Bearbeiten" : "Erstellen" }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="meeting.title"
                :rules="rules.required"
                label="Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="start_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="start_date_t"
                    :rules="rules.required"
                    label="Anfangsdatum"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  @change="startDateLogic()"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="start_time"
                v-model="start_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="start_time"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="start_time"
                    :rules="rules.end_date"
                    label="Anfangszeit"
                    readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="start_time"
                  format="24hr"
                  color="primary"
                  @click:minute="$refs.start_time.save(start_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="end_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="end_date_t"
                    :rules="endDateRule"
                    label="Enddatum"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  @change="end_date_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="end_time"
                v-model="end_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="end_time"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="end_time"
                    :rules="endTimeRule"
                    label="Endzeit"
                    readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="end_time"
                  format="24hr"
                  color="primary"
                  @click:minute="$refs.end_time.save(end_time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="register_until_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="register_until_t"
                    :rules="registerUntilRule"
                    label="Deadline zum Eintragen"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="meeting.register_until"
                  @change="register_until_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :min="today"
                  :max="start_date"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="meeting.user_groups"
                :items="member_groups"
                item-text="name"
                item-value="id"
                label="Helfergruppen"
                multiple
                :search-input.sync="searchGroups"
                @change="searchGroups = ''"
                :rules="rules.required"
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="meeting.responsible"
                label="Ansprechpartner"
                :items="users"
                item-text="'${data.item.first_name} ${data.item.last_name}'"
                item-value="id"
                :filter="customFilter"
                auto-select-first
                clearable
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="`${data.item.first_name} ${data.item.last_name}`"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                  <span
                    >{{ data.item.first_name }} {{ data.item.last_name }}</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="meeting.users"
                label="Helfer"
                :items="users"
                item-text="'${data.item.first_name} ${data.item.last_name}'"
                item-value="id"
                auto-select-first
                multiple
                :search-input.sync="searchUsers"
                @change="searchUsers = ''"
                chips
                :filter="customFilter"
              >
                <template v-slot:[`selection`]="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="removeSelected(data.item)"
                  >
                    {{ data.item.first_name }}
                    {{ data.item.last_name }}
                  </v-chip>
                </template>
                <template v-slot:[`item`]="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="
                          `${data.item.first_name} ${data.item.last_name}`
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <p class="v-label v-label--active ml-1 mb-2" style="opacity: 0.7">
            Beschreibung
          </p>
          <editor v-model="meeting.description" style="mt-1" />

          <v-row>
            <v-col>
              <v-checkbox
                v-model="meeting.comment"
                label="Kommentarfeld anzeigen"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                color="primary"
                large
                class="ml-0"
                :disabled="!valid"
                @click="save()"
                >{{ this.$route.params.id ? "Speichern" : "Erstellen" }}</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="primary"
                large
                @click="$router.push({ name: 'meeting' })"
                >Abbrechen
              </v-btn>
            </v-col>
          </v-row>

          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import _ from "lodash";
import Editor from "@/components/Editor";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";

export default {
  components: { Editor },
  data: () => ({
    meeting: {
      title: "",
      start: "",
      end: "",
      description: "",
      user_groups: [],
      comment: true,
      responsible: [],
      users: [],
      register_until: ""
    },
    loader: false,
    today: new Date().toISOString().slice(0, 10),
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    start_date_menu: false,
    start_time_menu: false,
    end_date_menu: false,
    end_time_menu: false,
    register_until_menu: false,
    member_groups: null,
    users: [],
    searchUsers: "",
    searchGroups: "",
    valid: false,
    rules: {
      required: [v => !!v || "Benötigt."]
    }
  }),
  created: function() {
    this.loadMembergroups();
    this.loadUsers();
    this.loadMeeting();
  },
  computed: {
    start_date_t() {
      return this.start_date
        ? format(this.start_date, "dddd, Do MMMM YYYY", {
            locale: deLocale
          })
        : "";
    },
    register_until_t() {
      return this.meeting.register_until
        ? format(this.meeting.register_until, "dddd, Do MMMM YYYY", {
            locale: deLocale
          })
        : "";
    },
    end_date_t() {
      return this.end_date
        ? format(this.end_date, "dddd, Do MMMM YYYY", {
            locale: deLocale
          })
        : "";
    },
    registerUntilRule() {
      return [
        v =>
          !this.start_date ||
          !v ||
          this.meeting.register_until <= this.start_date ||
          "Die Deadline zum eintragen darf nicht nach dem Anfangsdatum liegen"
      ];
    },
    endDateRule() {
      return [
        v => !!v || "Benötigt",
        this.end_date >= this.start_date || "Ende muss nach Start liegen"
      ];
    },
    endTimeRule() {
      return [
        v => !!v || "Benötigt",
        (this.end_date != this.start_date
          ? true
          : this.start_time < this.end_time) || "Ende muss nach Start liegen"
      ];
    }
  },
  methods: {
    loadMeeting() {
      if (this.$route.params.id) {
        const vm = this;
        vm.id = this.$route.params.id;
        Api.meetingsGet(vm.id).then(function(response) {
          vm.meeting = response.data.data;
          var start = response.data.data.start.split(" ");
          vm.start_date = start[0];
          vm.start_time = start[1].substring(0, 5);
          var end = response.data.data.end.split(" ");
          vm.end_date = end[0];
          vm.end_time = end[1].substring(0, 5);
        });
      }
    },
    removeSelected(item) {
      let index = -1;
      if (typeof this.meeting.users[0] === "object")
        index = _.findIndex(this.meeting.users, item);
      else index = this.meeting.users.indexOf(item.id);
      this.meeting.users.splice(index, 1);
    },
    save() {
      const vm = this;
      if (this.$refs.form.validate()) {
        this.loader = true;
        let newMeeting = vm.meeting;
        newMeeting.start = vm.start_date + "T" + vm.start_time;
        newMeeting.end = vm.end_date + "T" + vm.end_time;
        if (typeof vm.meeting.user_groups[0] === "object") {
          var userGroupids = [];
          vm.meeting.user_groups.forEach(element => {
            userGroupids.push(element.id);
          });
          newMeeting.user_groups = userGroupids;
        }
        if (typeof vm.meeting.users[0] === "object") {
          var usersIDs = [];
          vm.meeting.users.forEach(element => {
            usersIDs.push(element.id);
          });
          newMeeting.users = usersIDs;
        }
        if (
          vm.meeting.responsible &&
          typeof vm.meeting.responsible === "object"
        ) {
          newMeeting.responsible = vm.meeting.responsible.id;
        }

        if (vm.$route.params.id) {
          Api.meetingsEdit(vm.id, newMeeting)
            .then(() => {
              vm.loader = false;
              vm.$router.push({ name: "meeting" }, () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              });
            })
            .catch(() => {
              vm.loader = false;
            });
        } else {
          Api.meetingsCreate(newMeeting)
            .then(() => {
              vm.loader = false;
              vm.$router.push({ name: "meeting" }, () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              });
            })
            .catch(() => {
              vm.loader = false;
            });
        }
      }
    },
    loadMembergroups: function() {
      const vm = this;
      Api.userGroups().then(function(response) {
        vm.member_groups = _.sortBy(response.data.data, ["name"]);
      });
    },
    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = _.orderBy(
          response.data.data,
          ["last_name", "first_name"],
          ["asc", "asc"]
        );
      });
    },
    startDateLogic() {
      this.start_date_menu = false;
      if (!this.end_date) {
        this.end_date = this.start_date;
        var d = new Date(this.start_date);
        if (!this.end_time && !this.start_time && d.getDay() == 1) {
          this.start_time = "19:00";
          this.end_time = "21:30";
        }
        if (!this.end_time && !this.start_time && d.getDay() == 5) {
          this.start_time = "19:00";
          this.end_time = "22:30";
        }
        if (!this.end_time && !this.start_time && d.getDay() == 6) {
          this.start_time = "08:00";
          this.end_time = "15:30";
        }
      }
    },
    customFilter(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    }
  }
};
</script>

<style></style>
