<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-12" cols="12" md="6" v-if="false">
        <h1 class="text-h3 font-weight-bold primary--text mb-4">Einsatz</h1>
        <v-row class="xs12">
          <v-col cols="12">
            <v-card class="primary">
              <v-list two-line class="primary white--text">
                <template v-for="(item, index) in alarmItems">
                  <v-list-item
                    :key="index"
                    avatar
                    ripple
                    class="primary py-2"
                    dark
                    to="einsätze/"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">{{
                        item.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="white--text">{{
                        item.headline
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="white--text">{{
                        item.subtitle
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text class="white--text text-subtitle-1">{{
                        item.action
                      }}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    dark
                    v-if="index + 1 < alarmItems.length"
                    :key="'d' + index"
                  ></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-col>
          <v-col class="mt-4" cols="12">
            <v-card
              class="pa-4 primary text-h5 font-weight-bold"
              dark
              to="einsätze/"
            >
              <v-icon medium class="mr-6">{{ icons.mdiAlarmLight }}</v-icon
              >Einsatzverwaltung
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <h1 class="text-h3 font-weight-bold primary--text mb-4">
          Verwaltung
        </h1>
        <v-row class="pa-2 pa-md-1" id="menuItems">
          <v-col
            cols="6"
            class="pa-1 pa-md-2"
            v-for="(item, index) in canMenuItems"
            :key="item.text"
          >
            <v-card
              dark
              class="pa-4"
              :to="{ name: item.link }"
              :color="
                index % 4 == 0 || index % 4 == 3 ? 'secondary' : 'primary'
              "
            >
              <v-img
                height="70px"
                :contain="true"
                position="right"
                :src="item.image"
              ></v-img>
              <v-card-text
                class="pa-0 mt-2 white--text"
                :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : 'title'"
                >{{ item.text }}</v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAlarmLight } from "@mdi/js";

export default {
  name: "home",
  data: () => ({
    alarmItems: [
      {
        action: "10.02.2020",
        headline: "VU Schwer LKW",
        title: "Fachberateralarm",
        subtitle: "BAB 3/ Höchstadt Ost"
      },
      {
        action: "22.01.2020",
        headline: "Explosion Gebäude",
        title: "Vollalarm",
        subtitle: "Baiersdorf, Baiersdorfer Mühle"
      }
    ],
    menuItems: [
      {
        text: "Helfer",
        image: "/img/THW-members.svg",
        link: "user",
        permission: [
          "users.general",
          "divisions.general",
          "user_groups.general"
        ]
      },
      {
        text: "Dienste",
        image: "/img/Calendar-edit.svg",
        link: "meeting",
        permission: ["meetings.general", "meetings.advanced"]
      },
      {
        text: "Doodle",
        image: "/img/Calendar.svg",
        link: "doodle",
        permission: "any"
      },
      {
        text: "Abfragen",
        image: "/img/Availability.svg",
        link: "event",
        permission: "any"
      },
      {
        text: "Mein Barcode",
        image: "/img/Barcode.svg",
        link: "barcode",
        permission: "any"
      },
      {
        text: "Aufgaben",
        image: "/img/Task.svg",
        link: "task",
        permission: "any"
      },
      {
        text: "Anwesenheit",
        image: "/img/Time.svg",
        link: "presence",
        permission: "users.presence"
      },
      {
        text: "Mails",
        image: "/img/Mail.svg",
        link: "mail",
        permission: ["notifications.general", "notifications.administration"]
      },
      {
        text: "Qualifikationen",
        image: "/img/Qualification.svg",
        link: "qualification",
        permission: "qualifications.general"
      },
      {
        text: "Administration",
        image: "/img/Admin.svg",
        link: "admin",
        permission: ["admin.general", "roles.general"]
      },
      {
        text: "Meine Daten",
        image: "/img/Person.svg",
        link: "usershow",
        permission: "any"
      },
      {
        text: "Mein Kalender",
        image: "/img/Calendar-download.svg",
        link: "calendar",
        permission: "any"
      }
    ],
    icons: { mdiAlarmLight }
  }),
  computed: {
    canMenuItems() {
      return this.menuItems.filter(h => this.$can(h.permission));
    }
  }
};
</script>

<style></style>
