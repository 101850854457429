import store from "./store";
import Vue from "vue";

Vue.prototype.$can = function(permission) {
  if (permission == "any") return true;
  var permissions = store.getters.getPermissions;
  if (typeof permissions == "undefined") {
    if (localStorage.user) {
      permissions = JSON.parse(localStorage.user).permissions;
    }
  }
  if (permissions) {
    if (Array.isArray(permission)) {
      let check = false;
      permission.forEach(element => {
        let ok = permissions.hasOwnProperty(element);
        if (ok) {
          check = true;
        }
      });
      return check;
    }
    return permissions.hasOwnProperty(permission);
  }
};
