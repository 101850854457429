<template>
  <div>
    <div class="file-list">
      <File
        v-for="item in internalValue"
        :key="item.id"
        v-bind:value="item"
        v-on:remove="remove"
        v-on:show="show"
        v-on:image="addImage"
        :disabled="disabled"
      ></File>
    </div>
    <div class="pt-4 pl-1">
      <div>Gesamt: {{ cumulatedFileSizesInMb }} MB</div>
    </div>
    <v-overlay style="max-height: 100vh" v-model="showOverlay">
      <v-carousel height="90vh" v-model="currentImage">
        <v-carousel-item
          v-for="image in sortedImages"
          :key="image.id"
          class="carousel-item"
        >
          <v-img contain height="100%" width="100%" :src="image.url"> </v-img>
        </v-carousel-item>
        <v-btn
          class="exit-icon"
          icon
          absolute
          right
          top
          v-on:click="showOverlay = false"
        >
          <v-icon>{{ icons.mdiClose }} </v-icon>
        </v-btn>
      </v-carousel>
    </v-overlay>
  </div>
</template>
<script>
import File from "@/components/Files/File";
import Api from "@/services/ApiService.js";
import _ from "lodash";
import { mdiClose } from "@mdi/js";

export default {
  name: "FileList",
  destroyed() {
    //delete all temporary Files from Server
    this.internalValue.forEach((element) => {
      if (element.isTemporary) {
        Api.filesDelete(element.id);
      }
    });
  },
  components: {
    File,
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      icons: {
        mdiClose,
      },
      internalValue: this.value,
      showOverlay: false,
      currentImage: 0,
      images: [],
    };
  },
  methods: {
    remove(id) {
      this.internalValue = this.internalValue.filter(function(
        value,
        index,
        arr
      ) {
        if (value.id !== id) {
          return true;
        }
        if (value.isTemporary) {
          Api.filesDelete(id).catch(function(error) {
            vm.$emit("showSnackbar", error);
          });
        }
        return false;
      });
      this.images = this.images.filter(function(value, index, arr) {
        if (value.id !== id) {
          return true;
        }
      });
    },
    show(id) {
      let index = _.findIndex(this.sortedImages, ["id", id]);
      this.currentImage = index;
      this.showOverlay = true;
    },
    addImage(image) {
      this.images.push(image);
    },
    onSaved() {
      this.internalValue.forEach((element) => (element.isTemporary = false));
    },
  },
  computed: {
    sortedImages: function() {
      let arr = [...this.images].sort((a, b) => a.id - b.id);
      return arr;
    },
    cumulatedFileSizesInMb: function() {
      let value = _.sumBy(this.internalValue, (x) => x.size) / 1000000;

      return value.toFixed(2).replace(".", ",");
    },
  },
  watch: {
    value: function(newVal) {
      this.internalValue = newVal;
    },
    internalValue: function() {
      this.$emit("input", this.internalValue);
    },
  },
};
</script>
<style scoped>
.file-list {
  --spacing: 1vmin;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing);
}

@media only screen and (min-width: 600px) and (max-width: 960px) {
  .file-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1264px) {
  .file-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>

<style scoped>
.carousel-item {
  width: 90vw;
}
</style>
