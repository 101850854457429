<template>
  <v-dialog
    v-model="passwordChange"
    :max-width="options.width"
    @keydown.esc="cancel"
    @keyup.enter="changePassword"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <v-card>
      <v-toolbar :color="options.color" dense text>
        <v-toolbar-title class="white--text">Passwort ändern</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        Hier kannst du ein neues Passwort festlegen
        <v-form
          v-model="valid"
          ref="form"
          @submit="changePassword"
          onSubmit="return false;"
          lazy-validation
        >
          <input type="text" hidden autocomplete="username" />
          <v-text-field
            v-model="credentials.password"
            v-on:keyup.enter="changePassword()"
            :prepend-icon="icons.mdiLock"
            name="password1"
            label="Altes Passwort"
            id="password1"
            type="password"
            :rules="rules.required"
            autocomplete="current-password"
          ></v-text-field>
          <v-text-field
            v-on:keyup.enter="changePassword()"
            v-model="credentials.new_password"
            :prepend-icon="icons.mdiLock"
            name="password2"
            label="Neues Passwort"
            id="password2"
            type="password"
            :rules="rules.password"
            validate-on-blur
            autocomplete="new-password"
          ></v-text-field>
          <v-text-field
            v-on:keyup.enter="changePassword()"
            v-model="credentials.new_password_confirmation"
            :prepend-icon="icons.mdiLock"
            name="password3"
            label="Bestätigung Passwort"
            id="password3"
            type="password"
            :rules="passwordConfirmationRule"
            validate-on-blur
            autocomplete="new-password"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text="text" @click="passwordChange = false"
          >Abbrechen</v-btn
        >
        <v-btn @click="changePassword" :color="options.color" text="text"
          >Ändern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiLock } from "@mdi/js";

export default {
  data: () => ({
    credentials: {
      password: "",
      new_password: "",
      new_password_confirmation: ""
    },
    passwordChange: false,
    valid: true,
    options: {
      color: "primary",
      width: 350,
      zIndex: 200
    },
    rules: {
      required: [v => !!v || "Benötigt"],
      password: [
        v => !!v || "Benötigt",
        v => v.length >= 8 || "Mindestens 8 Zeichen"
      ]
    },
    icons: { mdiLock }
  }),
  methods: {
    changePassword() {
      const vm = this;
      if (this.$refs.form.validate()) {
        Api.changePassword(this.credentials).then(() => {
          vm.$emit("showsuccess");
          this.passwordChange = false;
          this.credentials = {
            password: "",
            new_password: "",
            new_password_confirmation: ""
          };
        });
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return [
        v => !!v || "Benötigt",
        () =>
          this.credentials.new_password ==
            this.credentials.new_password_confirmation ||
          "Passwort muss übereinstimmen"
      ];
    }
  }
};
</script>
