<template>
  <v-app>
    <v-navigation-drawer
      :expand-on-hover="$vuetify.breakpoint.lgAndUp && expandOnHover"
      :permanent="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-if="typeof $store.getters.getUser.permissions !== 'undefined'"
    >
      <v-list>
        <v-list-item
          v-for="navitem in canNavitems"
          :key="navitem.title"
          :to="{ name: navitem.link }"
        >
          <v-list-item-action>
            <v-icon>{{ navitem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ navitem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="colorselect = true">
          <v-list-item-action>
            <v-icon>{{ icons.mdiEyedropperVariant }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Farbauswahl</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="goDark = !goDark">
          <v-list-item-action>
            <v-icon>{{ icons.mdiCompare }}</v-icon>
          </v-list-item-action>
          <v-list-item-content style="height: 48px">
            <v-list-item-title>{{
              goDark ? "Light Theme" : "Dark Theme"
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="openFeedback()">
          <v-list-item-action>
            <v-icon>{{ icons.mdiMessageAlertOutline }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Feedback</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$refs.changePassword.passwordChange = true">
          <v-list-item-action>
            <v-icon>{{ icons.mdiLockReset }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Passwort ändern</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>{{ icons.mdiLogout }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="!$vuetify.breakpoint.lgAndUp"
        aria-label="Menü"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-h5">
        <router-link :to="{ name: 'home' }" class="toolbar-title">
          <span class="primary--text">{{ appName }}</span>
          <span class="font-weight-light mx-1">-</span>
          <span
            class="font-weight-light"
            :class="goDark ? 'white--text' : 'black--text'"
            >{{ organisationName }}</span
          >
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text class="text-subtitle-1">{{ time }}</v-btn>
        <v-btn
          text
          class="text-subtitle-1"
          :to="{ name: 'usershow' }"
          v-if="typeof $store.getters.getUser.permissions !== 'undefined'"
        >
          <v-icon large class="primary--text">
            {{ icons.mdiAccountCircle }}
          </v-icon>
          <span class="ml-4">{{ user.first_name }} {{ user.last_name }}</span>
        </v-btn>
        <v-btn
          @click="logout"
          text
          class="text-subtitle-1"
          v-if="typeof $store.getters.getUser.permissions !== 'undefined'"
        >
          <v-icon large class="primary--text">{{ icons.mdiLogout }}</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <ChangePassword ref="changePassword" v-on:showsuccess="showSuccsess" />

    <Feedback ref="feedback" />

    <v-content>
      <router-view></router-view>

      <v-snackbar
        data-cy="snackbar"
        v-model="snackbar.active"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :timeout="snackbar.timeout"
        :vertical="snackbar.mode === 'vertical'"
      >
        {{ snackbar.text }}
        <v-btn dark text @click="snackbar.active = false">Schließen</v-btn>
      </v-snackbar>

      <v-snackbar v-model="updateExists" color="primary">
        Es ist ein Update verfügbar
        <v-spacer />
        <v-btn text @click.native="updateApp">Aktualisieren</v-btn>
      </v-snackbar>

      <v-dialog v-model="cookies" width="500" persistent>
        <v-card>
          <v-card-title
            data-cy="cookie-title"
            class="text-h5 primary white--text"
            primary-title
            >Cookie Richtline</v-card-title
          >

          <v-card-text class="pt-4">
            Um unsere Webseite für optimal zu gestalten und fortlaufend
            verbessern zu können, verwenden wir Cookies. Durch die weitere
            Nutzung der Webseite stimmst du der Verwendung von Cookies zu.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="cookies = false"
              data-cy="cookie-button"
              >Akzeptieren</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="colorselect" width="500">
        <v-card>
          <v-card-title class="text-h5 primary white--text" primary-title
            >Farbauswahl</v-card-title
          >

          <v-card-text class="pt-4">
            <v-container>
              <v-row>
                <v-col
                  v-for="seltheme in themess"
                  :key="seltheme.color"
                  cols="4"
                >
                  <v-card
                    @click="theme = seltheme.name"
                    :color="seltheme.color"
                    class="text-center py-4 white--text"
                    >{{ seltheme.name }}</v-card
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>

      <v-overlay :value="appLoader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-content>
  </v-app>
</template>

<script>
import ChangePassword from "@/components/ChangePassword";
import Feedback from "@/components/Feedback";
import { mapGetters } from "vuex";
import colors from "vuetify/es5/util/colors";
import EventBus from "@/eventBus";
import {
  mdiViewDashboardOutline,
  mdiAccountGroupOutline,
  mdiCalendarText,
  mdiCalendarCheck,
  mdiCalendar,
  mdiBarcode,
  mdiClipboardTextOutline,
  mdiCartOutline,
  mdiHistory,
  mdiEmailOutline,
  mdiAccountCheckOutline,
  mdiChartTree,
  mdiAccountCardDetailsOutline,
  mdiCalendarMonth,
  mdiEyedropperVariant,
  mdiCompare,
  mdiMessageAlertOutline,
  mdiLockReset,
  mdiLogout,
  mdiAccountCircle
} from "@mdi/js";
import update from "@/mixins/update.js";

export default {
  name: "App",
  components: { ChangePassword, Feedback },
  data() {
    return {
      goDark: false,
      online: false,
      cookies: false,
      colorselect: false,
      theme: "Standard",
      themes: ["Standard", "Blau", "Orange", "Rot", "Pink"],
      themess: [
        {
          color: "teal darken2",
          name: "Standard"
        },
        {
          color: "blue",
          name: "Blau"
        },
        {
          color: "orange darken4",
          name: "Orange"
        },
        {
          color: "red darken2",
          name: "Rot"
        },
        {
          color: "pink lighten2",
          name: "Pink"
        }
      ],
      icons: {
        mdiViewDashboardOutline,
        mdiAccountGroupOutline,
        mdiCalendarText,
        mdiCalendar,
        mdiBarcode,
        mdiClipboardTextOutline,
        mdiCartOutline,
        mdiHistory,
        mdiEmailOutline,
        mdiAccountCheckOutline,
        mdiChartTree,
        mdiAccountCardDetailsOutline,
        mdiCalendarMonth,
        mdiEyedropperVariant,
        mdiCompare,
        mdiMessageAlertOutline,
        mdiLockReset,
        mdiLogout,
        mdiAccountCircle
      },
      drawer: false,
      expandOnHover: true,
      time: "11:22",
      appName: process.env.VUE_APP_NAME,
      organisationName: process.env.VUE_APP_ORGANISATION_NAME,
      appLoader: false,
      snackbar: {
        active: false,
        color: "primary",
        mode: "",
        timeout: 4000,
        text: ""
      },
      navitems: [
        {
          title: "Home",
          icon: mdiViewDashboardOutline,
          link: "home",
          permission: "any"
        },
        {
          title: "Helfer",
          icon: mdiAccountGroupOutline,
          link: "user",
          permission: [
            "users.general",
            "divisions.general",
            "user_groups.general"
          ]
        },
        {
          title: "Dienste",
          icon: mdiCalendarText,
          link: "meeting",
          permission: "meetings.general"
        },
        {
          title: "Doodle",
          icon: mdiCalendar,
          link: "doodle",
          permission: "any"
        },
        {
          title: "Abfragen",
          icon: mdiCalendarCheck,
          link: "event",
          permission: "any"
        },
        {
          title: "Mein Barcode",
          icon: mdiBarcode,
          link: "barcode",
          permission: "any"
        },
        {
          title: "Aufgaben",
          icon: mdiClipboardTextOutline,
          link: "task",
          permission: "any"
        },
        {
          title: "Anwesenheit",
          icon: mdiHistory,
          link: "presence",
          permission: "users.presence"
        },
        {
          title: "Mails",
          icon: mdiEmailOutline,
          link: "mail",
          permission: ["notifications.general", "notifications.administration"]
        },
        {
          title: "Qualifikationen",
          icon: mdiAccountCheckOutline,
          link: "qualification",
          permission: "qualifications.general"
        },
        {
          title: "Administration",
          icon: mdiChartTree,
          link: "admin",
          permission: ["admin.general", "roles.general"]
        },
        {
          title: "Meine Daten",
          icon: mdiAccountCardDetailsOutline,
          link: "usershow",
          permission: "any"
        },

        {
          title: "Mein Kalender",
          icon: mdiCalendarMonth,
          link: "calendar",
          permission: "any"
        }
      ]
    };
  },
  mixins: [update],
  mounted() {
    this.online = navigator.onLine;
    window.addEventListener("online", () => {
      this.online = true;
    });
    window.addEventListener("offline", () => {
      this.online = false;
      EventBus.$emit(
        "showSnackbar",
        "Du bist offline. Einige Funktionen sind nicht verfügbar!",
        "orange"
      );
    });
    if (localStorage.goDark) {
      this.goDark = localStorage.goDark == "true" ? true : false;
    }
    if (localStorage.theme) {
      this.theme = localStorage.theme;
      this.switchTheme(this.theme);
    }
    if (localStorage.cookies) {
      this.cookies = localStorage.cookies == "true" ? true : false;
    } else {
      this.cookies = true;
    }
    document.title =
      this.appName + " - " + this.$route.meta.title || "Allgemein";
  },
  watch: {
    goDark(newDark) {
      localStorage.goDark = newDark;
      this.$vuetify.theme.dark = newDark;
    },
    cookies(newCookie) {
      localStorage.cookies = newCookie;
    },
    theme(newTheme) {
      localStorage.theme = newTheme;
      this.switchTheme(newTheme);
    },
    $route(to, from) {
      document.title = this.appName + " - " + to.meta.title || "Allgemein";
    }
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    canNavitems() {
      return this.navitems.filter(h => this.$can(h.permission));
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("AUTH_LOGOUT").catch(err => window.console.log(err));
    },
    can() {
      return true;
    },
    showSuccsess() {
      this.showSnackbar("Erfolgreich!", "primary");
    },
    showSnackbar(text, color, mode, timeout) {
      if (typeof text !== "string") {
        if (text.response) this.snackbar.text = text.response.data.message;
        else this.snackbar.text = "Das angeforderte Objekt existiert nicht!";
      } else {
        this.snackbar.text = text;
      }
      this.snackbar.color = color || "red";
      this.snackbar.mode = mode || "";
      this.snackbar.timeout = timeout || 5000;
      this.snackbar.active = true;
    },
    switchTheme(newTheme) {
      switch (newTheme) {
        case "Standard":
          this.$vuetify.theme.themes.light.primary = colors.teal.darken2;
          this.$vuetify.theme.themes.light.secondary = colors.teal.lighten2;
          this.$vuetify.theme.themes.dark.primary = colors.teal.lighten2;
          this.$vuetify.theme.themes.dark.secondary = colors.teal.darken2;
          break;
        case "Pink":
          this.$vuetify.theme.themes.light.primary = colors.pink.lighten2;
          this.$vuetify.theme.themes.light.secondary = colors.pink.lighten3;
          this.$vuetify.theme.themes.dark.primary = colors.pink.lighten2;
          this.$vuetify.theme.themes.dark.secondary = colors.pink.lighten3;
          break;
        case "Blau":
          this.$vuetify.theme.themes.light.primary = colors.blue;
          this.$vuetify.theme.themes.light.secondary = colors.blue.darken3;
          this.$vuetify.theme.themes.dark.primary = colors.blue;
          this.$vuetify.theme.themes.dark.secondary = colors.blue.darken3;
          break;
        case "Rot":
          this.$vuetify.theme.themes.light.primary = colors.red.darken2;
          this.$vuetify.theme.themes.light.secondary = colors.red.lighten2;
          this.$vuetify.theme.themes.dark.primary = colors.red.darken2;
          this.$vuetify.theme.themes.dark.secondary = colors.red.lighten2;
          break;
        case "Orange":
          this.$vuetify.theme.themes.light.primary = colors.orange.darken4;
          this.$vuetify.theme.themes.light.secondary = colors.orange.darken1;
          this.$vuetify.theme.themes.dark.primary = colors.orange.darken4;
          this.$vuetify.theme.themes.dark.secondary = colors.orange.darken1;
          break;
      }
    },
    updateTime() {
      var cd = new Date();
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ":" +
        this.zeroPadding(cd.getMinutes(), 2);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    openFeedback() {
      this.$refs.feedback.open();
    },
  },
  created() {
    const vm = this;
    EventBus.$on("error", text => {
      vm.showSnackbar(text);
    });
    EventBus.$on("showSnackbar", (text, color, mode, timeout) => {
      vm.showSnackbar(text, color, mode, timeout);
    });
    if (vm.$store.getters.isAuthenticated) {
      vm.appLoader = true;
      vm.$store.dispatch("USER_REQUEST").finally(() => {
        vm.appLoader = false;
      });
    }
    setInterval(vm.updateTime, 10000);
    vm.updateTime();
  }
};
</script>

<style>
.no-line .v-input__slot::after,
.no-line .v-input__slot::before {
  display: none;
}
.no-line .v-select__selections {
  font-size: 16px !important;
}
.a {
  text-decoration: none;
}
.toolbar-title {
  color: inherit;
  text-decoration: inherit;
}
@media only print {
  html {
    font-size: 12px !important;
  }
  nav {
    display: none !important;
  }
  header {
    display: none !important;
  }
  aside {
    display: none !important;
  }
  .v-content {
    padding: 0 !important;
  }
  .theme--light.application {
    background: white;
  }
  .v-bottom-navigation {
    box-shadow: none !important;
  }
  .v-card {
    box-shadow: none;
    background-color: white !important;
    color: black !important;
  }
  .v-card__text {
    color: black !important;
  }
  .v-card__actions span {
    color: black !important;
  }
  i {
    font-size: 24px !important;
  }
  .row .flex {
    flex-grow: inherit;
  }
  .row .col-md-1 {
    max-width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-2 {
    max-width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-3 {
    max-width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-4 {
    max-width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-5 {
    max-width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-6 {
    max-width: 50% !important;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-7 {
    max-width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-8 {
    max-width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-9 {
    max-width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-10 {
    max-width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-11 {
    max-width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col-md-12 {
    max-width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}
</style>
