<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <h1 class="text-h3 font-weight-bold primary--text">Doodle</h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="primary"
              @click="calendar = !calendar"
              outlined
              large
              right
            >
              <span>{{ calendar ? "Listenansicht" : "Kalenderansicht" }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card v-if="!calendar">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @update:page="goTop()"
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="start"
            @click:row="editItem"
          >
            <template v-slot:[`item.start`]="{ item }">
              <meetingDate :value="item.start"></meetingDate>
            </template>
            <template v-slot:[`item.user.attendance`]="{ item }">
              <v-menu offset-y>
                <template v-slot:[`activator`]="{ on }">
                  <v-btn text icon small v-on="on">
                    <meetingIcon :value="item.user.attendance"></meetingIcon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(element, index) in getAttendances(
                      item.user.attendance
                    )"
                    :key="index"
                    @click="changeAttendance(item, element)"
                  >
                    <v-list-item-content>
                      <meetingIcon :value="element"></meetingIcon>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
        <v-card v-if="calendar">
          <v-row
            class="fill-height"
            v-touch="{
              left: () => calNext(),
              right: () => calPrev()
            }"
          >
            <v-col>
              <div
                v-if="$vuetify.breakpoint.smAndDown"
                width="100%"
                style="text-align: center"
              >
                {{ calTitle }}
              </div>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    @click="calSetToday"
                    color="primary"
                    >Heute</v-btn
                  >
                  <v-btn fab text small @click="calPrev">
                    <v-icon small>{{ icons.mdiChevronLeft }}</v-icon>
                  </v-btn>
                  <v-btn fab text small @click="calNext">
                    <v-icon small>{{ icons.mdiChevronRight }}</v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown">{{
                    calTitle
                  }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:[`activator`]="{ on }">
                      <v-btn outlined v-on="on" color="primary">
                        <span>{{ cal.typeToLabel[cal.type] }}</span>
                        <v-icon right>{{ icons.mdiMenuDown }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cal.type = 'day'">
                        <v-list-item-title>Tag</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="cal.type = 'week'">
                        <v-list-item-title>Woche</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="cal.type = 'month'">
                        <v-list-item-title>Monat</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="cal.focus"
                  color="primary"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :events="calendarMeetings"
                  event-name="text-h4"
                  :event-color="calGetEventColor"
                  :event-margin-bottom="3"
                  :now="cal.today"
                  :type="cal.type"
                  @click:event="calEditItem"
                  @click:more="calViewDay"
                  @click:date="calViewDay"
                  @change="calUpdateRange"
                ></v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import { VIcon } from "vuetify/lib";
import EventBus from "@/eventBus";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenuDown,
  mdiCheck,
  mdiClose,
  mdiRadioboxBlank,
  mdiPlus,
  mdiMagnify
} from "@mdi/js";

export default {
  data: () => ({
    loader: false,
    calendar: false,
    token: undefined,
    data: [],
    pastMeetings: [],
    search: "",
    headers: [
      {
        text: "Datum",
        align: "left",
        value: "start"
      },
      {
        text: "Name",
        align: "left",
        value: "title"
      },
      {
        text: "Teilnahme",
        align: "right",
        value: "user.attendance"
      }
    ],
    icons: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiMenuDown,
      mdiMagnify
    },
    attendances: ["yes", "no", "maybe"],
    cal: {
      today: format(new Date(), "YYYY-MM-DD"),
      focus: format(new Date(), "YYYY-MM-DD"),
      type: "month",
      typeToLabel: {
        month: "Monat",
        week: "Woche",
        day: "Tag"
      },
      start: null,
      end: null
    }
  }),
  created: function() {
    this.token = localStorage.doodletoken;
    this.loadMeetings();
  },
  mounted: function() {
    if (this.$route.params.token) {
      localStorage.doodletoken = this.$route.params.token;
      this.token = localStorage.doodletoken;
    }
    if (this.$store.getters.isAuthenticated) {
      localStorage.doodletoken = "";
    }
    if (this.$route.query.calendar || localStorage.doodleCalendar == "true") {
      this.calendar = true;
      this.loadArchivedMeetings();
    }
  },
  computed: {
    calendarMeetings() {
      return this.data.concat(this.pastMeetings);
    },
    calTitle() {
      const { start, end } = this.cal;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + ".";
      const endDay = end.day + ".";

      switch (this.cal.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
        case "day":
          return `${startDay} ${startMonth} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    }
  },
  watch: {
    calendar: function(newVal, oldVal) {
      if (oldVal != null && newVal != oldVal) {
        if (newVal) {
          if (!this.$route.query.calendar) {
            localStorage.doodleCalendar = true;
            if (this.pastMeetings.length == 0) {
              this.loadArchivedMeetings();
            }
            this.$router.replace({
              name: "doodle",
              query: { calendar: newVal }
            });
          }
        } else {
          localStorage.doodleCalendar = false;
          this.$router.replace({ name: "doodle" });
        }
      }
    }
  },
  methods: {
    loadMeetings: function() {
      this.loader = true;
      const vm = this;
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodle(vm.token)
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    loadArchivedMeetings: function() {
      const vm = this;
      Api.doodleArchive(vm.token).then(function(response) {
        vm.pastMeetings = response.data.data;
      });
    },
    editItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "doodleshow", params: { id: item.id } });
    },
    calEditItem(item) {
      this.$router.push({
        name: "doodleshow",
        params: { id: item.event.id }
      });
    },
    calViewDay({ date }) {
      this.cal.focus = date;
      this.cal.type = "day";
    },
    calGetEventColor(event) {
      switch (event.user.attendance) {
        case "yes":
          return "#0C8043";

        case "no":
          return "#D50200";

        case "maybe":
          return "#F6BF28";

        default:
          return "#616161";
      }
    },
    calSetToday() {
      this.cal.focus = this.cal.today;
    },
    calPrev() {
      this.$refs.calendar.prev();
    },
    calNext() {
      this.$refs.calendar.next();
    },
    calUpdateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.cal.start = start;
      this.cal.end = end;
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    },
    changeAttendance(meeting, attendance) {
      const vm = this;
      let newDoodle = {
        id: meeting.user.id,
        attendance: attendance,
        comment: meeting.user.comment
      };
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodleEdit(meeting.id, newDoodle, vm.token).then(response => {
        meeting.user.attendance = attendance;
        vm.meeting = response.data.data;
      });
    },
    getAttendances(attendance) {
      return this.attendances.filter(e => e != attendance);
    }
  },
  components: {
    meetingDate: {
      props: ["value"],
      template: '<span v-html="meetingStartDate"></span>',
      computed: {
        meetingStartDate() {
          return this.value
            ? format(this.value, "dd, Do MMMM YYYY HH:mm", {
                locale: deLocale
              })
            : "";
        }
      }
    },
    meetingIcon: {
      props: ["value"],
      components: {
        VIcon
      },
      template:
        '<v-icon :color="meetingColorComputed">{{ meetingIconComputed }}</v-icon>',
      computed: {
        meetingIconComputed() {
          return this.value ? this.icon(this.value) : mdiPlus;
        },
        meetingColorComputed() {
          return this.value ? this.color(this.value) : "primary";
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiPlus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "primary";
          }
        }
      }
    }
  }
};
</script>

<style></style>
