<template>
  <v-card>
    <FileList
      ref="FileList"
      :disabled="disabled"
      v-model="internalValue"
    ></FileList>
    <FileUpload
      :disabled="disabled"
      v-on:file-uploaded="OnFileUploaded"
    ></FileUpload>
  </v-card>
</template>
<script>
import FileList from "@/components/Files/FileList";
import FileUpload from "@/components/Files/FileUpload";

export default {
  name: "FileUploadDownload",
  components: {
    FileList,
    FileUpload,
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      internalValue: this.value,
    };
  },

  methods: {
    OnFileUploaded(file) {
      this.internalValue.push(file);
    },
    OnSaved() {
      this.$refs.FileList.onSaved();
    },
  },
  watch: {
    value: function(newVal) {
      this.internalValue = newVal;
    },
    internalValue: function() {
      this.$emit("input", this.internalValue);
    },
  },
};
</script>
<style scoped>
.v-card {
  padding: 1vmin;
}
</style>
