export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    };
  },

  created() {
    document.addEventListener("SW_UPDATED", this.updateAvailable, {
      once: true
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }

      this.refreshing = true;
      window.location.reload();
    });
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },

    updateApp() {
      this.updateExists = false;

      if (!this.registration || !this.registration.waiting) {
        return;
      }

      this.registration.waiting.postMessage("SKIP_WAITING");
    }
  }
};
