<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="mb-4" cols="12" md="6">
            <v-btn
              v-if="!this.$store.getters.isAuthenticated"
              :to="{
                name: 'events'
              }"
              outlined
              color="primary"
              class="mb-2"
            >
              <span>Abfragen</span>
            </v-btn>
            <v-skeleton-loader :loading="loading" type="article">
              <v-card class="mx-auto" color="primary" dark>
                <v-card-title>
                  <v-container class="pa-0">
                    <v-row>
                      <v-col cols="auto">
                        <v-icon large left>{{ icons.mdiCalendar }}</v-icon>
                        <span
                          class="text-h4 font-weight-light"
                          style="word-break: break-word"
                          >{{ event.title }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-title>

                <v-card-text class="text-subtitle-1 px-4 white--text link-color">
                  <p class="text-body-2 mb-1">Gruppen: {{ meetingGroup }}</p>
                  <span v-html="event.description"
                    >Keine Beschreibung vorhanden</span
                  >
                </v-card-text>

                <v-card-actions class="px-0">
                  <v-list-item class="grow">
                    <v-list-item-content align-center>
                      <span>
                        <v-icon class="mr-2">{{
                          icons.mdiInformationOutline
                        }}</v-icon>
                        <span style="vertical-align: middle">
                          {{
                            event.responsible != null
                              ? event.responsible.first_name +
                                " " +
                                event.responsible.last_name
                              : "Kein Verantwortlicher"
                          }}
                        </span>
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-actions>
              </v-card>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto">
              <v-list-item v-for="day in upcomingOptions" :key="day.id">
                <v-list-item-content>
                  <dayInfo :value="day" :allUsers="event.all_users"></dayInfo>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th
                      class="text-left"
                      v-for="day in upcomingOptions"
                      :key="day.id"
                    >
                      <formatDate :value="day.date"></formatDate>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in event.all_users" :key="user.id">
                    <td>
                      {{ user.first_name }} {{ user.last_name }}
                      <v-tooltip bottom>
                        <template v-slot:[`activator`]="{ on }">
                          <v-icon
                            color="green"
                            v-if="user.vegetarian"
                            v-on="on"
                            >{{ icons.mdiAlphaVCircleOutline }}</v-icon
                          >
                        </template>
                        <span>Vegetarier</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:[`activator`]="{ on }">
                          <v-icon
                            color="red"
                            v-if="
                              user.health_information != null &&
                                user.health_information != ''
                            "
                            v-on="on"
                            >{{ icons.mdiMedicalBag }}</v-icon
                          >
                        </template>
                        <span>{{ user.health_information }}</span>
                      </v-tooltip>
                    </td>
                    <td v-for="day in upcomingOptions" :key="day.id">
                      <dayPresence
                        :value="user.id"
                        :registrations="day.registrations"
                      ></dayPresence>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import _ from "lodash";
import isPast from "date-fns/is_past";
import isToday from "date-fns/is_today";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import { VIcon, VTooltip } from "vuetify/lib";
import EventBus from "@/eventBus";
import {
  mdiCalendar,
  mdiInformationOutline,
  mdiCheck,
  mdiRadioboxBlank,
  mdiAccountBadgeHorizontal,
  mdiPhone,
  mdiEmail,
  mdiClose,
  mdiMinus,
  mdiText,
  mdiAlphaVCircleOutline,
  mdiMedicalBag
} from "@mdi/js";

export default {
  data: () => ({
    loading: true,
    token: "",
    event: {
      title: "",
      description: "",
      responsible: {},
      force_comment: false,
      users: [],
      user_groups: [],
      all_users: [],
      date_options: []
    },
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    attendance: "",
    attendances: [
      { name: "Ja", val: "yes" },
      { name: "Nein", val: "no" },
      { name: "Vielleicht", val: "maybe" }
    ],
    longComment: false,
    dialog: false,
    icons: {
      mdiCalendar,
      mdiInformationOutline,
      mdiCheck,
      mdiRadioboxBlank,
      mdiAccountBadgeHorizontal,
      mdiPhone,
      mdiEmail,
      mdiAlphaVCircleOutline,
      mdiMedicalBag
    }
  }),
  created: function() {
    this.token = localStorage.doodletoken;
    this.loadEvent();
  },
  mounted: function() {
    if (this.$route.params.token) {
      localStorage.doodletoken = this.$route.params.token;
      this.token = localStorage.doodletoken;
    } else {
      this.token = localStorage.doodletoken;
    }
    if (this.$store.getters.isAuthenticated) {
      localStorage.doodletoken = "";
    }
  },
  computed: {
    upcomingOptions() {
      return _.filter(this.event.date_options, function(o) {
        return !isPast(o.date) || isToday(o.date);
      });
    },
    meetingGroup() {
      var array = [];
      this.event.user_groups.forEach(function(element) {
        array.push(element.name);
      });
      return array.length != 0 ? array.join(", ") : "Keine";
    }
  },
  methods: {
    loadEvent() {
      const vm = this;
      vm.id = this.$route.params.id;
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.eventsAdvanced(vm.id, vm.token).then(function(response) {
        vm.event = response.data.data;
        vm.event.date_options = _.sortBy(vm.event.date_options, ["date"]);
        vm.event.all_users = _.sortBy(vm.event.all_users, [
          "last_name",
          "first_name"
        ]);
        vm.loading = false;
      });
    },
    save() {
      const vm = this;
      let newEvent = {
        id: vm.event.user.id,
        attendance: vm.event.user.attendance,
        comment: vm.event.user.comment
      };
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.eventsEdit(vm.id, newEvent, vm.token).then(response => {
        vm.event = response.data.data;
      });
    }
  },
  components: {
    dayPresence: {
      props: ["value", "registrations"],
      components: {
        VIcon,
        VTooltip
      },
      template:
        '<span><v-icon :color="presenceColorComputed">{{ presenceIconComputed }}</v-icon><v-tooltip bottom v-if="comment"><template v-slot:[`activator="{ on }"><v-icon :color="presenceColorComputed" v-on="on">{{ textIcon }}</v-icon></template><span v-text`]="myData.comment"></span></v-tooltip></span>',
      computed: {
        myData() {
          return _.filter(this.registrations, { user_id: this.value })[0];
        },
        comment() {
          return typeof this.myData != "undefined" && this.myData.comment;
        },
        presenceIconComputed() {
          return this.myData ? this.icon(this.myData.availability) : mdiMinus;
        },
        presenceColorComputed() {
          return this.myData ? this.color(this.myData.availability) : "grey";
        },
        presenceComment() {
          return this.myData && this.myData.comment ? true : false;
        },
        textIcon() {
          return mdiText;
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiMinus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "grey";
          }
        }
      }
    },
    dayInfo: {
      props: ["value", "allUsers"],
      template:
        "<span>{{meetingDate}}: Stärke <b><span v-html='staerke'></span></b><p class='caption mb-0'>Vegetarier: {{vegetarian}} Gesundheitsinfo: {{healthInfo}}</p></span>",
      computed: {
        meetingDate() {
          return this.value.date
            ? format(this.value.date, "DD.MM.YYYY", {
                locale: deLocale
              })
            : "";
        },
        presentUsers() {
          return _.filter(this.value.registrations, {
            availability: "yes"
          }).map(a => this.allUsers.find(x => x.id === a.user_id));
        },
        staerke() {
          let zfue = 0;
          let ufue = 0;
          let he = 0;

          _.forEach(this.presentUsers, function(user) {
            if (!user.position) {
              he++;
              return;
            }
            if (user.position.id == 6) {
              zfue++;
              return;
            }
            if (
              user.position.id == 2 ||
              user.position.id == 3 ||
              user.position.id == 4
            ) {
              ufue++;
              return;
            }
            he++;
          });

          return (
            zfue +
            "/" +
            ufue +
            "/" +
            he +
            "/ <u>" +
            this.presentUsers.length +
            "</u>"
          );
        },
        vegetarian() {
          return _.filter(this.presentUsers, { vegetarian: true }).length;
        },
        healthInfo() {
          return _.filter(this.presentUsers, function(o) {
            return !!o.health_information;
          }).length;
        }
      },
      methods: {}
    },
    formatDate: {
      props: ["value"],
      template: '<span v-html="meetingDate"></span>',
      computed: {
        meetingDate() {
          return this.value
            ? format(this.value, "DD.MM.YYYY", {
                locale: deLocale
              })
            : "";
        }
      }
    }
  }
};
</script>

<style>
.link-color > p > a {
  color: white;
}
</style>
