<template>
  <v-container>
    <v-form v-model="valid" lazy-validation ref="form">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">
            Aufgabe {{ this.$route.params.id ? "Bearbeiten" : "Erstellen" }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6" class="pb-0 mb-0">
              <v-text-field
                v-model="Task.title"
                :rules="rules.required"
                :disabled="!$can('tasks.general')"
                label="Titel"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="Task.task_category.id"
                :disabled="!$can('tasks.general')"
                label="Kategorie"
                auto-select-first
                :items="kategorien"
                item-text="name"
                item-value="id"
                :rules="rules.required"
                required
                xs12
                md6
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field label="Ersteller" v-model="creatorName" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                auto-select-first
                v-model="Task.responsible"
                :disabled="!$can('tasks.general')"
                label="Ansprechpartner"
                :items="users"
                item-text="'${data.item.first_name} ${data.item.last_name}'"
                item-value="id"
                :rules="rules.required"
                required
                xs12
                md6
                :filter="customFilter"
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="`${data.item.first_name} ${data.item.last_name}`"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="data">
                  <span>
                    {{ data.item.first_name }}
                    {{ data.item.last_name }}
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="duedate_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    :disabled="!$can('tasks.general')"
                    v-on="on"
                    :value="computedDateFormattedDatefns"
                    :rules="rules.required"
                    label="Fälligkeit"
                    required
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="Task.duedate"
                  @change="duedate_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="Task.priority"
                :disabled="!$can('tasks.general')"
                :items="priority"
                item-text="value"
                item-value="id"
                :rules="rules2.required"
                required
                xs12
                md6
                label="Priorität"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <p class="v-label v-label--active ml-1 mb-2" style="opacity: 0.7">
                Beschreibung
              </p>
              <Editor v-model="Task.description" style="mt-1" withTodo />
            </v-col>

            <v-col cols="12" md="6">
              <p class="v-label v-label--active ml-1 mb-2" style="opacity: 0.7">
                Aktuelles/Probleme
              </p>

              <Editor v-model="Task.addition" withTodo />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" order-md="2">
              <FileUploadDownload
                ref="FileUploadDownload"
                v-model="Task.files"
              />
            </v-col>
            <v-col cols="12" md="6" order-md="1">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="9" sm="10" xl="11">
                      <v-slider
                        v-model="Task.state"
                        label="Aktueller Stand"
                        thumb-label
                      ></v-slider>
                    </v-col>
                    <v-col cols="3" sm="2" xl="1">
                      <v-text-field
                        min="0"
                        max="100"
                        class="no-spinners pt-0"
                        v-model="Task.state"
                        v-mask="mask"
                        hide-details
                        :rules="ruleTextField.value"
                        single-line
                        type="text"
                        suffix="%"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="9" sm="10" md="9" lg="10">
                      <v-autocomplete
                        v-model="Task.users"
                        :disabled="!$can('tasks.general')"
                        label="Teilnehmer"
                        :items="users"
                        item-text="'${data.item.first_name} ${data.item.last_name}'"
                        item-value="id"
                        :rules="rules.required"
                        required
                        auto-select-first
                        multiple
                        :search-input.sync="searchUsers"
                        @change="searchUsers = ''"
                        chips
                        :filter="customFilter"
                      >
                        <template v-slot:[`selection`]="data">
                          <v-chip
                            :input-value="data.selected"
                            :close="$can('tasks.general')"
                            class="chip--select-multi"
                            @click:close="removeSelected(data.item)"
                          >
                            {{ data.item.first_name }}
                            {{ data.item.last_name }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item`]="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="
                                  `${data.item.first_name} ${data.item.last_name}`
                                "
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn
                        icon
                        @click="addMe"
                        style="bottom:-0.8em"
                        depressed
                        outlined
                        color="primary"
                      >
                        <v-icon>
                          {{
                            Task.users.includes(myuser.id)
                              ? icons.mdiAccountMinus
                              : icons.mdiAccountPlus
                          }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                :disabled="computedReadyForSave"
                @click="saveTask(true)"
                v-shortkey.once="['ctrl', 's']"
                @shortkey="saveTask(false)"
                color="primary"
                large
                class="ml-0"
                >{{ this.$route.params.id ? "Speichern" : "Erstellen" }}</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="primary"
                large
                @click="$router.push({ name: 'task' })"
                >Abbrechen</v-btn
              >
            </v-col>
          </v-row>

          <Confirm ref="confirm"></Confirm>
          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import Editor from "@/components/Editor";

import _ from "lodash";
import { mask } from "vue-the-mask";

import EventBus from "@/eventBus";

import { mapGetters } from "vuex";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");

import Api from "@/services/ApiService.js";
import { mdiAccountMinus, mdiAccountPlus } from "@mdi/js";
import FileUploadDownload from "../../components/Files/FileUploadDownload.vue";

export default {
  directives: {
    mask,
  },
  components: { Editor, Confirm, FileUploadDownload },
  name: "app",
  beforeRouteLeave(to, from, next) {
    const vm = this;

    if (vm.unsavedChanges) {
      vm.$refs.confirm
        .open(
          "Achtung!",
          "Du hast noch ungespeicherte Änderungen, wenn du diese Seite verlässt gehen diese verloren!",
          { color: "red" }
        )
        .then((confirm) => {
          next(confirm);
        });
    } else {
      next();
    }
  },

  data() {
    return {
      Task: {
        title: "",
        description: "",
        addition: "",
        duedate: "",
        inform_executives: false,
        responsible: null,
        task_category: {
          id: null,
          name: null,
        },
        priority: 0,
        state: 0,
        users: [],
        files: [],
      },
      task_category: {
        id: null,
        name: null,
      },

      dowloadable: [],
      pictures: [],
      loader: false,
      duedate_menu: false,
      oldTask: null,

      kategorien: [],
      users: [],
      confirmTitle: "Du hast ungespeicherte Änderungen",
      confirmText:
        "Wenn die diese Seite ohne zu Speichern verlässt, gehen deine ungespeicherten Änderungen verloren!",
      searchUsers: "",
      valid: true,

      rules: {
        required: [(v) => !!v || "Benötigt"],
      },

      rules2: {
        required: [(v) => (v != null && v !== undefined) || "Benötigt"],
      },
      ruleTextField: {
        value: [
          (v) =>
            (v >= 0 && v <= 100) ||
            "Es ist nur ein Wert zwischen 0 und 100 erlaubt",
        ],
      },
      mask: "###",
      isEditing: false,
      model: null,
      priority: [
        {
          id: 0,
          value: "Normal",
        },
        {
          id: 1,
          value: "Priorisiert",
        },
      ],
      icons: { mdiAccountMinus, mdiAccountPlus },
    };
  },
  created: function() {
    this.loadCategories();
    this.loadUsers();
    this.loadTask();
  },
  methods: {
    Name: (item) => item.first_name + " " + item.last_name,
    removeSelected(item) {
      let index = this.Task.users.indexOf(item.id);
      this.Task.users.splice(index, 1);
    },
    loadCategories: function() {
      const vm = this;
      Api.taskCategoryGet().then(function(response) {
        vm.kategorien = response.data.data;
        vm.kategorien = _.sortBy(vm.kategorien, ["name"]);
      });
    },
    loadTask: function() {
      const vm = this;
      if (vm.$route.params.id) {
        vm.id = this.$route.params.id;
        Api.taskGet(vm.id)
          .then(function(response) {
            vm.Task = response.data.data;
            if (!vm.Task.task_category) {
              vm.Task.task_category = vm.task_category;
            }
            if (response.data.data.responsible) {
              vm.Task.responsible = response.data.data.responsible.id;
            }
            if (vm.Task.users) {
              vm.Task.users = _.map(vm.Task.users, "id");
            } else {
              vm.Task.users = [];
            }

            if (!vm.Task.files) {
              vm.Task.files = [];
            }
            vm.setOldTask();
          })
          .catch(() => {
            vm.$router.push({ name: "task" });
          });
      } else {
        vm.Task.responsible = vm.myuser.id; //Set currentUser as Default, when new Task created
      }
    },

    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = response.data.data;
        vm.users = _.sortBy(vm.users, ["last_name", "first_name"]);
      });
    },

    addMe() {
      if (!this.participating) {
        this.Task.users.push(this.myuser.id);
      } else {
        let index = this.Task.users.indexOf(this.myuser.id);
        this.Task.users.splice(index, 1);
      }
    },

    saveTask(leaveRoute) {
      // Todo do not save while upload
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loader = true;
        let taskId = vm.$route.params.id;
        let newTask = {
          description: vm.Task.description,
          addition: vm.Task.addition,
          state: vm.Task.state,
          files: _.map(vm.Task.files, "id"),
        };
        if (!vm.$route.params.id) {
          vm.addPropertiesForCreateAndUpdate(vm, newTask);
          vm.createTask(vm, newTask, leaveRoute);
        } else if (vm.$can("tasks.general")) {
          vm.addPropertiesForCreateAndUpdate(vm, newTask);
          vm.updateTask(vm, newTask, taskId, leaveRoute);
        } else {
          vm.lightUpdateTask(vm, newTask, taskId, leaveRoute);
        }
      }
    },
    addPropertiesForCreateAndUpdate(vm, newTask) {
      newTask.responsible = vm.Task.responsible;
      newTask.task_category = vm.Task.task_category.id;
      newTask.inform_executives = false;
      newTask.priority = vm.Task.priority;
      newTask.duedate = vm.Task.duedate;
      newTask.title = vm.Task.title;
      newTask.users = vm.Task.users;
    },
    setOldTask() {
      const vm = this;
      vm.oldTask = JSON.stringify(vm.Task);
    },
    createTask(vm, newTask, leaveRoute) {
      Api.taskCreate(newTask)
        .then((response) => {
          vm.loader = false;
          vm.$refs.FileUploadDownload.OnSaved();

          vm.setOldTask();
          if (leaveRoute) {
            vm.goToTaskOverviewWithSuccess(vm);
          } else {
            vm.$router.push(
              {
                name: "taskedit",
                params: { id: response.data.data.id },
              },
              () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              }
            );
          }
        })
        .catch((error) => {
          vm.handleRequestException(vm, error);
        });
    },
    updateTask(vm, newTask, taskId, leaveRoute) {
      Api.taskUpdate(taskId, newTask)
        .then(() => {
          vm.loader = false;
          vm.$refs.FileUploadDownload.OnSaved();
          vm.setOldTask();
          if (leaveRoute) {
            vm.goToTaskOverviewWithSuccess(vm);
          } else {
            vm.afterSaveSuccessWithoutLeave(vm);
          }
        })
        .catch((error) => {
          vm.handleRequestException(vm, error);
        });
    },

    lightUpdateTask(vm, newTask, taskId, leaveRoute) {
      newTask.participating = vm.participating;
      Api.taskLightPatch(taskId, newTask)
        .then(() => {
          vm.loader = false;
          vm.$refs.FileUploadDownload.OnSaved();
          vm.setOldTask();
          if (leaveRoute) {
            vm.goToTaskOverviewWithSuccess(vm);
          } else {
            vm.afterSaveSuccessWithoutLeave(vm);
          }
        })
        .catch((error) => {
          vm.handleRequestException(vm, error);
        });
    },
    handleRequestException(vm, error) {
      vm.loader = false;
      var errorResponse = error.response.data.errors;
      var errorMessage = errorResponse[Object.keys(errorResponse)[0]];
      vm.$emit("showSnackbar", errorMessage[Object.keys(errorMessage)[0]]);
    },
    goToTaskOverviewWithSuccess(vm) {
      vm.$router.push({ name: "task" }, () => {
        EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
      });
    },
    afterSaveSuccessWithoutLeave(vm) {
      vm.$emit("showSnackbar", "Erfolgreich!", "primary");
    },

    customFilter(item, queryText) {
      const textOne = item.first_name.toLowerCase();
      const textTwo = item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
  },
  computed: {
    creatorName() {
      if (!this.Task.creator) {
        return;
      }

      return this.Task.creator.first_name + " " + this.Task.creator.last_name;
    },
    participating() {
      return this.Task.users.includes(this.myuser.id);
    },
    computedDateFormattedDatefns() {
      return this.Task.duedate
        ? format(this.Task.duedate, "dddd, Do MMMM YYYY", {
            locale: deLocale,
          })
        : "";
    },
    unsavedChanges() {
      const oldTask = this.oldTask;
      const newTask = this.Task;

      return JSON.stringify(newTask) !== oldTask;
    },
    computedReadyForSave() {
      return !this.valid;
    },
    ...mapGetters({ myuser: "getUser" }),
    ...mapGetters({ myPermissions: "getPermissions" }),
  },
};
</script>

<style>
.no-spinners [type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinners v-text-field__slot::before {
  border: none;
}
</style>
