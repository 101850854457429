<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Mein Kalender</h1>
      </v-col>
      <v-col cols="12">
        <v-row class="mb-4">
          <v-col>
            <p>
              Hier kannst du Links zu deinen Persönlichen Kalender zum Download
              oder zum synchronisieren in deinem vorhandenen Kalender erstellen.
              Falls du Hilfe bei der Einrichtung brauchst, findest du
              <a @click="dialog = true">hier eine Anleitung</a>.
            </p>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col cols="12">
            <p>Wähle aus welche Anwesenheiten der Link beinhalten soll:</p>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="yes"
              label="Ja"
              color="primary"
              style="margin-top: 0"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="9">
            <v-checkbox
              v-model="no"
              label="Nein"
              color="primary"
              style="margin-top: 0"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="maybe"
              label="Vielleicht"
              color="primary"
              style="margin-top: 0"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="not"
              label="Nicht eingetragen"
              color="primary"
              style="margin-top: 0"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Kalender</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-col>
                  <v-btn
                    :disabled="!validSelection"
                    fab
                    small
                    color="primary"
                    class="mr-2"
                    @click="link()"
                  >
                    <v-icon>{{ icons.mdiLink }}</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!validSelection"
                    fab
                    small
                    color="secondary"
                    @click="apple()"
                  >
                    <v-icon>{{ icons.mdiApple }}</v-icon>
                  </v-btn>
                </v-col>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          style="top: 0px; position: fixed; width: 100%"
        >
          <v-toolbar-title>Kalender Anleitung</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p style="padding-top: 80px">
            Mit dieser Funkiton können alle Helfer des THW Baiersdorf die
            Diensttermine als Kalender im iCal-Format abonnieren.
          </p>
          <p>
            Der iCal-Kalender kann mit unterschiedlichsten Geräten (z.B.
            <a href="#iPhone">iPhone</a> oder
            <a href="#Android">Android-Smartphones</a>) und Programmen (z.B.
            <a href="#Outlook">Microsoft Outlook</a> oder Thunderbird) verwendet
            werden. Es muss lediglich einmal der Link zu unserem iCal-Kalender
            hinzugefügt werden, damit in Zukunft alle Termine des THW Baiersdorf
            automatisch zu deinem Kalender hinzugefügt werden. Dieser Service
            ist natürlich kostenlos und völlig unverbindlich.
          </p>
          <p>
            Um unseren iCal-Kalender-Service nutzen zu können, musst du die
            Links aus deinem persönlichen Kalenderbereich in deinem Gerät /
            Programm hinzufügen.
          </p>
          <h4 id="Android">Android</h4>
          <p>
            Damit unsere Dienste automatisch im Kalender deines
            Android-Smartphones erscheinen, musst Du unseren iCal-Kalender zu
            deinem Online-Google-Kalender hinzufügen. Dazu benötigst Du ein
            Google-Konto, welches in der Regel bei der Erstinbetriebnahme des
            Smartphones angelegt wird. Dein Smartphone synchronisiert sich
            regelmäßig mit dem Online-Google-Kalender und holt sich dann so auch
            die Termine des THW Baiersdorf.
          </p>
          <p>
            Solltst Du noch kein Google-Konto besitzen, musst du vorher eines
            erstellen und dieses zu den Konten auf deinem Smartphone hinzufügen.
            Am einfachsten ist es, dafür den Einrichtungs-Assistenten von
            Android zu verwenden.
          </p>
          <p>Anschließend folge bitte diesen Anweisungen:</p>
          <ol>
            <li>
              Logge dich auf deinem PC mit deinem Google-Konto (z.B.
              max.muster@googlemail.com) hier in den Google-Kalender ein:
              <br />
              <a href="https://calendar.google.com" target="_blank"
                >https://calendar.google.com</a
              >
            </li>
            <li>
              Klicke auf das kleine Plus rechts neben "Weitere Kalender
              hinzufüg..."
            </li>
            <li>Wähle in dem Menü "Per URL"</li>
            <li>
              Kopiere deinen Link aus
              <a href="/kalender" target="_blank">deinem Kalenderbereich</a>
            </li>
            <li>
              Fügen den Link ein, die du in Schritt 4 kopiert hast, indem Du
              <strong>STRG+V</strong> drückst
            </li>
            <li>
              Fertig! (Evtl. musst Du in den "Konten- &amp;
              Synchronisierungs-Einstellungen" deines Android-Smartphones noch
              den Google-Account und dort speziell den Kalender aktivieren)
            </li>
          </ol>
          <p>
            Die Dienste werden nun automatisch in deinen Kalender übernommen,
            sobald diese auf unserer Webseite eingetragen werden. Diese
            Synchronisierung kann aber bis zu 24 Stunden dauern. Dies ist durch
            Google bedingt und kann durch uns nicht verändert werden.
          </p>

          <h4 id="iPhone">iPhone</h4>
          <p>Bitte folge diesen Anweisungen:</p>
          <ol>
            <li>
              Mit dem iPhone-Browser
              <a href="/kalender" target="_blank">deinem Kalenderbereich</a>
              aufrufen
            </li>
            <li>Auf das Apple Logo klicken</li>
            <li>Im Popup auf "Kalender abonnieren" klicken</li>
            <li>
              Fertig! (Evtl. musst du in den Einstellungen des Kalenders die
              Aktualisierungsfrequenz aktivieren oder einstellen)
            </li>
          </ol>
          <p></p>

          <h4 id="Outlook">iCal-Kalender mit Outlook abonnieren</h4>
          <p>Bitte folge diesen Anweisungen:</p>
          <ol>
            <li>
              Du benötigst
              <strong>Outlook 2007</strong>, oder eine
              <strong>neuere</strong> Version!
            </li>
            <li>
              Kopiere deinen Link aus
              <a href="/kalender" target="_blank">deinem Kalenderbereich</a>
            </li>
            <li>
              Klicke in Outlook im Menü auf
              <strong>Kontoeinstellungen</strong>
            </li>
            <li>
              Klicke auf der Registerkarte
              <strong>Internetkalender</strong> auf
              <strong>Neu</strong>
            </li>
            <li>
              Füge den Link ein, die du in Schritt 2 kopiert haben, indem Du
              <strong>STRG+V</strong> drückst
            </li>
            <li>
              Klicke auf
              <strong>Hinzufügen</strong>
            </li>
            <li>
              Gebe im Feld Ordnername den Namen des Kalenders ein, der in
              Outlook angezeigt werden soll, und klicke dann auf
              <strong>OK</strong>
            </li>
            <li>
              Fertig! (Evtl. musst du den neu erstellten Kalender in der linken
              Spalte bei "Alle Kalenderelemente" noch aktivieren)
            </li>
          </ol>
          <p>
            Die Termine werden nun automatisch in deinen Kalender übernommen,
            sobald diese auf unserer Webseite eingetragen werden.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EventBus from "@/eventBus";
import { mdiLink, mdiApple, mdiClose } from "@mdi/js";

export default {
  data: () => ({
    items: [
      {
        title: "Alle Dienste",
        file: "/alle_dienste.ics"
      },
      {
        title: "Dienste mit Teilnahme",
        file: "/angemeldete_dienste.ics"
      }
    ],
    maybe: true,
    yes: true,
    no: true,
    not: true,
    dialog: false,
    icons: { mdiLink, mdiApple, mdiClose }
  }),
  computed: {
    generatedLink: function() {
      let link =
        process.env.VUE_APP_API_URI +
        "/calendar/" +
        this.$store.getters.getUser.calendar_token +
        "?";
      if (this.yes) link += "yes";
      if (this.no) {
        if (this.yes) link += "&";
        link += "no";
      }
      if (this.maybe) {
        if (this.yes || this.no) link += "&";
        link += "maybe";
      }
      if (this.not) {
        if (this.yes || this.no || this.maybe) link += "&";
        link += "not_registered";
      }
      return link;
    },
    validSelection: function() {
      return this.yes || this.no || this.maybe || this.not;
    }
  },
  methods: {
    link() {
      this.$clipboard("https://" + this.generatedLink);
      EventBus.$emit("showSnackbar", "Link kopiert!", "primary");
    },
    apple() {
      window.open("webcal://" + this.generatedLink, "_blank");
    }
  }
};
</script>

<style></style>
