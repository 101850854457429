<template>
  <v-row>
    <v-col cols="12">
      <v-card-title>
        <v-btn outlined color="primary" :to="{ name: 'usercreate' }"
          >Erstellen</v-btn
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @update:page="goTop()"
        :headers="computedHeaders"
        :items="data"
        :search="search"
        :loading="loader"
        sort-by="last_name"
        @click:row="linkDataTableRow"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            text
            icon
            small
            :to="{ name: 'useredit', params: { id: item.id } }"
          >
            <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <v-btn text icon small @click="deleteItem(item)">
            <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <Confirm ref="confirm"></Confirm>
  </v-row>
</template>

<script>
import Confirm from "@/components/Confirm";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiPencil, mdiDelete, mdiMagnify } from "@mdi/js";

export default {
  components: { Confirm },
  data: () => ({
    loader: false,
    data: [],
    search: "",
    headers: [
      {
        text: "Vorname",
        align: "left",
        value: "first_name"
      },
      {
        text: "Nachname",
        align: "left",
        value: "last_name"
      },
      {
        text: "Einheit",
        align: "left",
        value: "division.name",
        hide: "smAndDown"
      },
      {
        text: "Einheit",
        align: "left",
        value: "division.short_name",
        hide: "mdAndUp"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    icons: { mdiPencil, mdiDelete, mdiMagnify }
  }),
  created: function() {
    this.loadUsers();
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        h => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    }
  },
  methods: {
    loadUsers: function() {
      this.loader = true;
      const vm = this;
      Api.users()
        .then(function(response) {
          vm.data = response.data.data;
          vm.loader = false;
        })
        .catch(() => {
          vm.loader = false;
        });
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.usersDelete(item).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    linkDataTableRow(v) {
      const vm = this;
      vm.$router.push({ name: "useredit", params: { id: v.id } });
    },
    editItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "useredit", params: { id: item.id } });
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  }
};
</script>

<style></style>
