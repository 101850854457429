import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_REFRESH
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import router from "../../router";
import Api from "@/services/ApiService.js";

const state = {
  accessToken: localStorage.getItem("accessToken") || null
};

const mutations = {
  [AUTH_SUCCESS]: (state, { accessToken, refreshToken }) => {
    // console.log(AUTH_SUCCESS)
    // console.log(accessToken)
    state.accessToken = accessToken;
    // axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  },
  [AUTH_ERROR]: state => {
    state.accessToken = null;
    // delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  },
  [AUTH_LOGOUT]: state => {
    state.accessToken = null;
    // delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    router.push({ name: "login" });
  }
};
const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      Api.login(credentials)
        .then(resp => {
          // Successfully logged in
          const accessToken = resp.data.access_token;
          const refreshToken = resp.data.refresh_token;

          commit(AUTH_SUCCESS, { accessToken, refreshToken });
          dispatch(USER_REQUEST);
          resolve(resp);
        })
        .catch(err => {
          // Login failed
          commit(AUTH_ERROR);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Api.logout()
        .then(resp => {
          resolve(resp);
        })
        .finally(() => {
          commit(AUTH_LOGOUT);
        })
        .catch(err => reject(err));
    });
  },
  [AUTH_REFRESH]: ({ commit, dispatch }, failedRequest) => {
    return new Promise((resolve, reject) => {
      const oldRefreshToken = localStorage.getItem("refreshToken");
      if (!oldRefreshToken) {
        commit(AUTH_ERROR);
        reject();
        return;
      }
      Api.refresh(oldRefreshToken)
        .then(resp => {
          // Successfully refreshed access token in
          const accessToken = resp.data.access_token;
          const refreshToken = resp.data.refresh_token;

          commit(AUTH_SUCCESS, { accessToken, refreshToken });
          // console.log(failedRequest)
          if (failedRequest) {
            failedRequest.response.config.headers["Authorization"] =
              "Bearer " + accessToken;
          }

          dispatch(USER_REQUEST);
          resolve(resp);
        })
        .catch(err => {
          // Refresh failed
          commit(AUTH_ERROR);
          router.push({ name: "login" });
          reject(err);
        });
    });
  }
};
const getters = {
  isAuthenticated: state => !!state.accessToken,
  bearer: state => "Bearer " + state.accessToken
};

export default {
  state,
  mutations,
  actions,
  getters
};
