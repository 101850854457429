<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title data-cy="login-title">Anmelden</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-text-field
                v-model="credentials.username"
                v-on:keyup.enter="login()"
                required
                :rules="rules.required"
                :prepend-icon="icons.mdiAccount"
                name="login"
                label="Benutzername oder E-Mail"
                type="text"
                autocomplete="username"
                data-cy="username"
              ></v-text-field>
              <v-text-field
                v-model="credentials.password"
                v-on:keyup.enter="login()"
                required
                :rules="rules.required"
                :prepend-icon="icons.mdiLock"
                name="password"
                label="Passwort"
                id="password"
                type="password"
                autocomplete="current-password"
                data-cy="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="passwordModal = true" data-cy="login-reset"
              >Passwort vergessen</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :disabled="!valid"
              @click="login"
              data-cy="login-button"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="passwordModal"
      :max-width="options.width"
      @keydown.esc="cancel"
      @keydown.enter="resetPassword()"
      v-bind:style="{ zIndex: options.zIndex }"
    >
      <v-card>
        <v-toolbar :color="options.color" dense text>
          <v-toolbar-title class="white--text"
            >Passwort vergessen</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="pt-4">
          Hier kannst du einen Passwort-Reset anfordern
          <v-form
            ref="resetValid"
            lazy-validation
            @submit="resetPassword"
            onsubmit="return false;"
            v-model="valid2"
          >
            <v-text-field
              v-model="reset.email"
              v-on:keyup.enter="resetPassword()"
              :rules="[rules.required && rules.email]"
              validate-on-blur
              label="E-mail"
              data-cy="reset-mail"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey" text="text" @click="passwordModal = false"
            >Abbrechen</v-btn
          >
          <v-btn
            @click="resetPassword"
            :color="options.color"
            :disabled="!valid2"
            text="text"
            data-cy="reset-button"
            >Passwort anfordern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-4">
          Bitte warten!
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import { mdiAccount, mdiLock } from "@mdi/js";

export default {
  name: "login",
  data: () => ({
    credentials: {
      username: "",
      password: ""
    },
    passwordModal: false,
    options: {
      color: "primary",
      width: 400,
      zIndex: 200
    },
    reset: {
      email: ""
    },
    valid: true,
    valid2: true,
    loader: false,
    rules: {
      required: [v => !!v || "Benötigt."],
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Ungültige E-Mail.";
      }
    },
    icons: { mdiAccount, mdiLock }
  }),
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loader = true;
        this.$store
          .dispatch("AUTH_REQUEST", this.credentials)
          .then(() => {
            this.loader = false;
            this.$router.push({ name: "home" });
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    resetPassword() {
      if (this.$refs.resetValid.validate()) {
        const vm = this;
        this.loader = true;
        Api.requestPasswordReset(this.reset)
          .then(() => {
            EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
            this.loader = false;
            this.passwordModal = false;
          })
          .catch(() => {
            vm.loader = false;
          });
      }
    }
  },
  created() {
    // if (this.$store.getters.loggedIn) this.$router.push({ name: 'home' });
  }
};
</script>

<style></style>
