<template>
  <div
    class="editor"
    v-bind:class="{
      darkActive: this.$vuetify.theme.dark
    }"
  >
    <v-card>
      <v-card-title>
        <editor-menu-bar :editor="editor" class="pb-4">
          <v-btn-toggle
            v-model="toggle_multiple"
            multiple
            slot-scope="{ commands, isActive }"
          >
            <v-btn
              text
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <v-icon>{{ icons.mdiFormatBold }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <v-icon>{{ icons.mdiFormatItalic }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.strike() }"
              class="hidden-sm-and-down"
              @click="commands.strike"
            >
              <v-icon>{{ icons.mdiFormatStrikethrough }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <v-icon>{{ icons.mdiFormatUnderline }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </editor-menu-bar>
        <v-divider class="mx-2 mb-4" vertical></v-divider>
        <editor-menu-bar :editor="editor" class="pb-4">
          <v-btn-toggle
            v-model="toggle_multiple1"
            multiple
            slot-scope="{ commands, isActive }"
          >
            <v-btn
              text
              :class="{ 'is-active': isActive.paragraph() }"
              class="hidden-sm-and-down"
              @click="commands.paragraph"
            >
              <v-icon>{{ icons.mdiFormatTextdirectionLToR }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              <v-icon>{{ icons.mdiFormatHeader1 }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              <v-icon>{{ icons.mdiFormatHeader2 }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              <v-icon>{{ icons.mdiFormatHeader3 }}</v-icon>
            </v-btn>
            <v-btn
              text
              v-if="withTodo"
              :class="{ 'is-active': isActive.todo_list() }"
              @click="commands.todo_list"
            >
              <v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </editor-menu-bar>
        <v-divider class="mx-2 mb-4" vertical></v-divider>
        <editor-menu-bar :editor="editor" class="pb-4">
          <v-btn-toggle
            v-model="toggle_multiple2"
            multiple
            slot-scope="{ commands, isActive }"
          >
            <v-btn
              text
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <v-icon>{{ icons.mdiFormatListBulleted }}</v-icon>
            </v-btn>
            <v-btn
              text
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <v-icon>{{ icons.mdiFormatListNumbered }}</v-icon>
            </v-btn>
            <v-btn
              text
              @click="commands.horizontal_rule"
              class="hidden-sm-and-down"
            >
              <v-icon>{{ icons.mdiMinus }}</v-icon>
            </v-btn>
            <v-btn text @click="commands.undo">
              <v-icon>{{ icons.mdiUndo }}</v-icon>
            </v-btn>
            <v-btn text @click="commands.redo">
              <v-icon>{{ icons.mdiRedo }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </editor-menu-bar>
      </v-card-title>

      <v-card-text @click="!editor.view.focused ? editor.focus() : ''">
        <editor-content class="editor__content" :editor="editor" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";
import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiFormatTextdirectionLToR,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatListChecks,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiMinus,
  mdiUndo,
  mdiRedo
} from "@mdi/js";
export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    value: String,
    withTodo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toggle_multiple: [],
      toggle_multiple1: [],
      toggle_multiple2: [],
      editor: null,
      emitAfterOnUpdate: false,
      icons: {
        mdiFormatBold,
        mdiFormatItalic,
        mdiFormatStrikethrough,
        mdiFormatUnderline,
        mdiFormatTextdirectionLToR,
        mdiFormatHeader1,
        mdiFormatHeader2,
        mdiFormatHeader3,
        mdiFormatListChecks,
        mdiFormatListBulleted,
        mdiFormatListNumbered,
        mdiMinus,
        mdiUndo,
        mdiRedo
      }
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        new BulletList(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new TodoItem({
          nested: true
        }),
        new TodoList(),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Bold(),
        new Italic(),
        new Link(),
        new Strike(),
        new Underline(),
        new History()
      ],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        this.emitAfterOnUpdate = true;
        this.$emit("input", getHTML());
      }
    });
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false;
        return;
      }
      if (this.editor) this.editor.setContent(val);
    }
  },
  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  }
};
</script>

<style scoped>
.ProseMirror {
  outline: none !important;
}

.v-btn-toggle .v-btn.v-btn--active {
  opacity: 0.8;
}

.v-btn-toggle .v-btn.is-active {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.13);
}

.v-btn--active:before,
.v-btn:focus:before,
.v-btn:hover:before {
  background-color: unset;
}
</style>

<style>
.editor .v-card__title {
  padding-bottom: 0;
}

.editor__content blockquote {
  border-left: 3px solid lightgray;
  padding-left: 15px;
  margin-left: 1em;
}

.editor__content .ProseMirror-focused {
  outline: none;
}

.editor__content .ProseMirror {
  font-size: 16px;
}

.editor__content code {
  background-color: black;
  color: white;
  font-size: 12px;
}

.editor__content pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background: #000;
  color: #fff;
  font-size: 0.8rem;
  overflow-x: auto;
}

.editor__content p code {
  display: inline-block;
  padding: 0 0.4rem;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
}

ul[data-type="todo_list"] {
  padding-left: 0;
  margin-bottom: 16px;
}
li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
}
.todo-checkbox {
  border: 2px solid black;
  height: 0.9em;
  width: 0.9em;
  box-sizing: border-box;
  margin-right: 10px;
  margin-top: 0.15rem;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}
.darkActive .todo-checkbox {
  border: 2px solid white;
}
.todo-content {
  flex: 1;
}
.todo-content > p:last-of-type {
  margin-bottom: 0;
}
.todo-content > ul[data-type="todo_list"] {
  margin: 0.5rem 0;
}
li[data-done="true"] > .todo-content > p {
  text-decoration: line-through;
}
li[data-done="true"] > .todo-checkbox {
  background-color: black;
}
.darkActive li[data-done="true"] > .todo-checkbox {
  background-color: white;
}
li[data-done="false"] {
  text-decoration: none;
}
</style>
