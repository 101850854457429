<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="mb-12" cols="12" md="6">
              <v-btn
                v-if="!this.$store.getters.isAuthenticated"
                :to="{
                  name: 'events'
                }"
                outlined
                color="primary"
                class="mb-2"
              >
                <span>Abfragen</span>
              </v-btn>
              <v-skeleton-loader :loading="loading" type="article">
                <v-card class="mx-auto" color="primary" dark>
                  <v-card-title>
                    <v-container class="pa-0">
                      <v-row>
                        <v-col cols="auto">
                          <v-icon large left>{{ icons.mdiCalendar }}</v-icon>
                          <span
                            class="text-h4 font-weight-light"
                            style="word-break: break-word"
                            >{{ event.title }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>

                  <v-card-text
                    class="text-subtitle-1 px-4 white--text link-color"
                    v-html="event.description"
                    >Keine Beschreibung vorhanden</v-card-text
                  >

                  <v-card-actions class="px-0">
                    <v-list-item class="grow">
                      <v-list-item-content
                        align-center
                        @click.stop="dialog = true"
                      >
                        <span>
                          <v-icon class="mr-2">
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                          <span style="vertical-align: middle">
                            {{
                              event.responsible != null
                                ? event.responsible.first_name +
                                  " " +
                                  event.responsible.last_name
                                : "Kein Verantwortlicher"
                            }}
                          </span>
                        </span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-row justify="center">
                <v-col
                  class="mb-1 hidden-print-only"
                  cols="12"
                  v-if="upcomingOptions.length >= 1"
                >
                  <v-row align="end" justify="center">
                    <v-skeleton-loader :loading="loading" type="heading">
                      <p class="text-h5 font-weight-black pb-1 pr-4">
                        Alle setzen
                      </p>
                    </v-skeleton-loader>
                    <v-select
                      @input="saveAll"
                      :items="attendancesList"
                      item-text="name"
                      item-value="val"
                      label="Verfügbarkeit"
                      style="max-width:100px"
                    ></v-select>
                  </v-row>
                </v-col>
                <v-col class="mt-4" cols="12" sm="8">
                  <v-card>
                    <v-list>
                      <p v-if="upcomingOptions.length == 0" class="ml-6 mt-4">
                        Keine Termine zum eintragen vorhanden!
                      </p>
                      <template v-for="(day, index) in upcomingOptions">
                        <v-list-item :key="day.id">
                          <v-list-item-content>
                            <v-list-item-title>
                              <formatDate :value="day.date"></formatDate>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <meetingComment
                                :value="
                                  getComment(day, event.authenticated_user.id)
                                "
                                day="day"
                                v-on:change="
                                  save(
                                    day,
                                    getAvailability(
                                      day,
                                      event.authenticated_user.id
                                    ),
                                    $event
                                  )
                                "
                              ></meetingComment>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-menu offset-y>
                              <template v-slot:[`activator`]="{ on }">
                                <v-btn text icon small v-on="on">
                                  <meetingIcon
                                    :value="
                                      getAvailability(
                                        day,
                                        event.authenticated_user.id
                                      )
                                    "
                                  ></meetingIcon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(element, index) in getAttendances(
                                    getAvailability(
                                      day,
                                      event.authenticated_user.id
                                    )
                                  )"
                                  :key="index"
                                  @click="
                                    save(
                                      day,
                                      element,
                                      getComment(
                                        day,
                                        event.authenticated_user.id
                                      )
                                    )
                                  "
                                >
                                  <v-list-item-content>
                                    <meetingIcon :value="element"></meetingIcon>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider
                          v-if="index + 1 < event.date_options.length"
                          :key="'k' + index"
                        ></v-divider>
                      </template>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th
                    class="text-left"
                    v-for="day in upcomingOptions"
                    :key="day.id"
                  >
                    <formatDate :value="day.date"></formatDate>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in event.all_users" :key="user.id">
                  <td>{{ user.first_name }} {{ user.last_name }}</td>
                  <td v-for="day in upcomingOptions" :key="day.id">
                    <dayPresence
                      :value="user.id"
                      :registrations="day.registrations"
                    ></dayPresence>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-dialog v-model="dialog" max-width="600" v-if="event.responsible">
          <v-card class="primary">
            <v-row class="mx-0">
              <v-card-title class="white--text pl-md-12">
                <div
                  class="text-h4 pl-md-6 pt-md-12"
                  style="word-break: break-word"
                >
                  {{
                    event.responsible.first_name +
                      " " +
                      event.responsible.last_name
                  }}
                </div>
              </v-card-title>
            </v-row>

            <v-list two-line>
              <v-list-item>
                <v-list-item-action>
                  <v-icon color="primary">
                    {{ icons.mdiAccountBadgeHorizontal }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ event.responsible.position }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Funktion</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="event.responsible.division">
                <v-list-item-action></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ event.responsible.division.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Einheit</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="
                  event.responsible.mobile != null ||
                    event.responsible.phone != null
                "
                inset
              ></v-divider>

              <v-list-item
                v-if="event.responsible.mobile != null"
                :href="'tel:' + event.responsible.mobile"
              >
                <v-list-item-action>
                  <v-icon color="primary">{{ icons.mdiPhone }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ event.responsible.mobile }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Mobil</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="event.responsible.phone != null"
                :href="'tel:' + event.responsible.phone"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ event.responsible.phone }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Festnetz</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider
                v-if="event.responsible.email != null"
                inset
              ></v-divider>

              <v-list-item
                v-if="event.responsible.email != null"
                :href="'mailto:' + event.responsible.email"
              >
                <v-list-item-action>
                  <v-icon color="primary">{{ icons.mdiEmail }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ event.responsible.email }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Email</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>

        <v-dialog v-model="missingVal" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5" v-if="incompleteVals.presence == ''"
                >Bitte gib eine Verfügbarkeit an</span
              >
              <span class="text-h5" v-if="incompleteVals.comment == ''"
                >Bitte gib einen Kommentar an</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" v-if="incompleteVals.comment == ''">
                    <v-textarea
                      class="mb-n7"
                      v-model="myComment"
                      placeholder="Kommentar"
                      auto-grow
                      rows="1"
                      solo
                      flat
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" v-if="incompleteVals.presence == ''">
                    <v-menu offset-y>
                      <template v-slot:[`activator`]="{ on }">
                        <v-btn text icon small v-on="on">
                          <meetingIcon
                            :value="incompleteVals.presence"
                          ></meetingIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(element, index) in getAttendances(
                            incompleteVals.presence
                          )"
                          :key="index"
                          @click="
                            save(
                              incompleteVals.day,
                              element,
                              incompleteVals.comment
                            ),
                              (missingVal = false)
                          "
                        >
                          <v-list-item-content>
                            <meetingIcon :value="element"></meetingIcon>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions v-if="incompleteVals.comment == ''">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="
                  save(incompleteVals.day, incompleteVals.presence, myComment),
                    (missingVal = false)
                "
                >Übernehmen</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import _ from "lodash";
import isPast from "date-fns/is_past";
import isToday from "date-fns/is_today";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import { VIcon, VTextarea, VTooltip } from "vuetify/lib";
import EventBus from "@/eventBus";
import {
  mdiCalendar,
  mdiInformationOutline,
  mdiCheck,
  mdiRadioboxBlank,
  mdiAccountBadgeHorizontal,
  mdiPhone,
  mdiEmail,
  mdiClose,
  mdiPlus,
  mdiMinus,
  mdiText
} from "@mdi/js";

export default {
  data: () => ({
    loading: true,
    token: "",
    comment: "",
    event: {
      title: "",
      description: "",
      responsible: {},
      force_comment: false,
      users: [],
      user_groups: [],
      date_options: []
    },
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    attendance: "",
    attendances: ["yes", "no", "maybe"],
    attendancesList: [
      { name: "Ja", val: "yes" },
      { name: "Nein", val: "no" },
      { name: "Bedingt", val: "maybe" }
    ],
    longComment: false,
    dialog: false,
    valid: false,
    missingVal: false,
    incompleteVals: {
      day: {},
      presence: "",
      comment: ""
    },
    myComment: "",
    icons: {
      mdiCalendar,
      mdiInformationOutline,
      mdiCheck,
      mdiRadioboxBlank,
      mdiAccountBadgeHorizontal,
      mdiPhone,
      mdiEmail
    }
  }),
  created: function() {
    this.token = localStorage.doodletoken;
    this.loadEvent();
  },
  mounted: function() {
    if (this.$route.params.token) {
      localStorage.doodletoken = this.$route.params.token;
      this.token = localStorage.doodletoken;
    } else {
      this.token = localStorage.doodletoken;
    }
    if (this.$store.getters.isAuthenticated) {
      localStorage.doodletoken = "";
    }
  },
  computed: {
    upcomingOptions() {
      return _.filter(this.event.date_options, function(o) {
        return !isPast(o.date) || isToday(o.date);
      });
    }
  },
  methods: {
    loadEvent() {
      const vm = this;
      vm.id = this.$route.params.id;
      if (!vm.token) {
        vm.token = this.$route.params.token;
      }
      Api.doodleEventsGet(vm.id, vm.token).then(function(response) {
        vm.event = response.data.data;
        vm.event.date_options = _.sortBy(vm.event.date_options, ["date"]);
        vm.event.all_users = _.sortBy(vm.event.all_users, [
          "last_name",
          "first_name"
        ]);
        vm.loading = false;
      });
    },
    save(day, presence, comment) {
      const vm = this;
      let newDateOption = {
        availability: presence,
        comment: comment,
        date_options: day.id ? [day.id] : day
      };
      if (
        (presence == "" && comment != "") ||
        (this.event.force_comment && presence != "yes" && comment == "")
      ) {
        this.incompleteVals.day = day;
        this.incompleteVals.presence = presence;
        this.incompleteVals.comment = comment;
        this.myComment = comment;
        this.missingVal = true;
      } else {
        if (!vm.token) {
          vm.token = this.$route.params.token;
        }
        Api.doodleEventsEdit(vm.id, newDateOption, vm.token).then(response => {
          vm.event = response.data.data;
          vm.event.date_options = _.sortBy(vm.event.date_options, ["date"]);
          vm.event.all_users = _.sortBy(vm.event.all_users, [
            "last_name",
            "first_name"
          ]);
        });
      }
    },
    saveAll(presence) {
      this.save(
        this.upcomingOptions.map(a => a.id),
        presence,
        ""
      );
    },
    getAttendances(attendance) {
      return this.attendances.filter(e => e != attendance);
    },
    getAvailability(day, id) {
      let e = _.filter(day.registrations, { user_id: id })[0];
      return e && e.availability ? e.availability : "";
    },
    getComment(day, id) {
      let e = _.filter(day.registrations, { user_id: id })[0];
      return e && e.comment ? e.comment : "";
    }
  },
  components: {
    meetingIcon: {
      props: ["value"],
      components: {
        VIcon
      },
      template:
        '<v-icon :color="meetingColorComputed">{{ meetingIconComputed }}</v-icon>',
      computed: {
        meetingIconComputed() {
          return this.value ? this.icon(this.value) : mdiPlus;
        },
        meetingColorComputed() {
          return this.value ? this.color(this.value) : "primary";
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiPlus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "primary";
          }
        }
      }
    },
    meetingComment: {
      props: ["value", "day"],
      components: {
        VTextarea
      },
      data: () => ({
        val: "newval"
      }),
      template:
        '<v-textarea class="mb-n7" :value="value" v-on:change="updated" placeholder="Kommentar" auto-grow rows="1" solo flat></v-textarea>',
      methods: {
        updated(text) {
          this.$emit("change", text);
        }
      }
    },
    formatDate: {
      props: ["value"],
      template: '<span v-html="meetingDate"></span>',
      computed: {
        meetingDate() {
          return this.value
            ? format(this.value, "DD.MM.YYYY", {
                locale: deLocale
              })
            : "";
        }
      }
    },
    dayPresence: {
      props: ["value", "registrations"],
      components: {
        VIcon,
        VTooltip
      },
      template:
        '<span><v-icon :color="presenceColorComputed">{{ presenceIconComputed }}</v-icon><v-tooltip bottom v-if="comment"><template v-slot:[`activator="{ on }"><v-icon :color="presenceColorComputed" v-on="on">{{ textIcon }}</v-icon></template><span v-text`]="myData.comment"></span></v-tooltip></span>',
      computed: {
        myData() {
          return _.filter(this.registrations, { user_id: this.value })[0];
        },
        comment() {
          return typeof this.myData != "undefined" && this.myData.comment;
        },
        presenceIconComputed() {
          return this.myData ? this.icon(this.myData.availability) : mdiMinus;
        },
        presenceColorComputed() {
          return this.myData ? this.color(this.myData.availability) : "grey";
        },
        presenceComment() {
          return this.myData && this.myData.comment ? true : false;
        },
        textIcon() {
          return mdiText;
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiMinus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "grey";
          }
        }
      }
    }
  }
};
</script>

<style>
.link-color > p > a {
  color: white;
}
</style>
