<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <h1 class="text-h3 font-weight-bold primary--text">
              Erweiterte Dienstansicht
            </h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pb-0">
            <v-btn
              class="mr-3"
              :to="{
                name: 'meetingedit',
                params: { id: meeting.id || '0' }
              }"
              v-if="$can('meetings.general')"
              color="primary"
              outlined
              right
            >
              <span>Bearbeiten</span>
            </v-btn>
            <v-btn
              :to="{
                name: 'doodleshow',
                params: { id: meeting.id || '/' }
              }"
              :disabled="!isCurrentUserMeetingParticipator"
              color="primary"
              outlined
              right
            >
              <span>Doodle-Ansicht</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              :loading="loading"
              type="sentences, paragraph, sentences"
            >
              <p class="text-h6 mb-0">{{ meeting.title }}</p>
              <p class="text-subtitle-1 mb-1">{{ date_t }}</p>
              <p class="text-body-2 mb-1">Gruppen: {{ meetingGroup }}</p>
              <p class="text-body-2 mb-1">Deadline: {{ registerUntilDateText }}</p>
              <p class="text-body-2">
                Verantwortlich:
                {{
                  meeting.responsible != null
                    ? meeting.responsible.first_name +
                      " " +
                      meeting.responsible.last_name
                    : "Kein Verantwortlicher"
                }}
              </p>
              <p
                class="text-body-1"
                v-html="meeting.description || 'Keine Beschreibung vorhanden'"
              ></p>
            </v-skeleton-loader>
            <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
          </v-col>
          <v-col cols="12" md="4">
            <p class="text-h6">Teilnahme</p>
            <v-list style="background: none">
              <v-list-item @click="showUsers(meeting.attendance_yes, 'Ja')">
                <v-list-item-content>Ja</v-list-item-content>
                <v-list-item-action>{{
                  meeting.attendance_yes.length
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="showUsers(meeting.attendance_maybe, 'Vielleicht')"
              >
                <v-list-item-content>Vielleicht</v-list-item-content>
                <v-list-item-action>{{
                  meeting.attendance_maybe.length
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item @click="showUsers(meeting.attendance_no, 'Nein')">
                <v-list-item-content>Nein</v-list-item-content>
                <v-list-item-action>{{
                  meeting.attendance_no.length
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item
                @click="showUsers(meeting.not_registered, 'Nicht eingetragen')"
              >
                <v-list-item-content>Nicht eingetragen</v-list-item-content>
                <v-list-item-action>{{
                  meeting.not_registered.length
                }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>Vegetarier</v-list-item-content>
                <v-list-item-action>{{ vegies }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content
                  >Wichtige Gesundheitsinfo</v-list-item-content
                >
                <v-list-item-action>{{ healthInfo }}</v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
          </v-col>
          <v-col cols="12" md="4">
            <p class="text-h6">Versendete E-Mails</p>
            <v-list style="background: none">
              <template v-for="(item, index) in meeting.meeting_mails">
                <v-list-item :key="item.id">
                  <v-list-item-content>
                    <meetingDate :value="item.date"></meetingDate>
                  </v-list-item-content>

                  <v-list-item-action>
                    <mailIcon :value="item.sent"></mailIcon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < meeting.meeting_mails.length"
                  :key="'i' + index"
                ></v-divider>
              </template>
            </v-list>
            <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>
          </v-col>
          <v-col cols="12">
            <p class="text-h6">Teilnehmer</p>
            <v-card>
              <v-data-table
                @update:page="goTop()"
                :headers="headers"
                :items="data"
                :search="search"
                sort-by="last_name"
                style="background: none"
                class="datatable-nobottom"
              >
                <template v-slot:[`item.attendance`]="{ item }">
                  <meetingIcon :value="item.attendance"></meetingIcon>
                </template>
                <template v-slot:[`item.vegetarian`]="{ item }">
                  <mailIcon :value="item.vegetarian"></mailIcon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-dialog v-model="dialog" width="300">
        <v-card>
          <v-card-title class="text-h5 primary white--text" primary-title>{{
            dialogTitle
          }}</v-card-title>
          <v-card-text>
            <v-list>
              <template v-for="(item, index) in dialogUsers">
                <v-list-item :key="'d' + item.id">
                  <v-list-item-content>
                    {{ item.first_name }}
                    {{ item.last_name }}
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < dialogUsers.length"
                  :key="'h' + index"
                ></v-divider>
              </template>
              <v-list-item v-if="dialogUsers.length == 0">
                <v-list-item-content>Keine Helfer!</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import { VIcon } from "vuetify/lib";
import { mapGetters } from "vuex";
import EventBus from "@/eventBus";
import { mdiCheck, mdiClose, mdiRadioboxBlank, mdiPlus } from "@mdi/js";

export default {
  data: () => ({
    loading: true,
    meeting: {
      attendance_maybe: [],
      attendance_no: [],
      attendance_yes: [],
      comment: false,
      description: null,
      end: "",
      id: "",
      meeting_mails: [],
      not_registered: [],
      register_until: "",
      start: "",
      title: "",
      user_groups: []
    },
    search: "",
    headers: [
      {
        text: "Vorname",
        align: "left",
        value: "first_name"
      },
      {
        text: "Nachname",
        align: "left",
        value: "last_name"
      },
      {
        text: "Teilnahme",
        align: "left",
        value: "attendance"
      },
      {
        text: "Kommentar",
        align: "left",
        value: "comment"
      },
      {
        text: "Gesundheitsinfo",
        align: "left",
        value: "health_information"
      },
      {
        text: "Vegetarier",
        align: "left",
        value: "vegetarian"
      }
    ],
    icons: { mdiCheck, mdiClose, mdiRadioboxBlank, mdiPlus },
    dialog: false,
    dialogTitle: "",
    dialogUsers: []
  }),
  created: function() {
    this.loadMeeting();
  },
  methods: {
    loadMeeting() {
      if (this.$route.params.id) {
        const vm = this;
        vm.id = this.$route.params.id;
        Api.meetingsAdvanced(vm.id).then(function(response) {
          vm.meeting = response.data.data;
          vm.meeting.attendance_yes = _.orderBy(
            response.data.data.attendance_yes,
            ["last_name", "first_name"],
            ["asc", "asc"]
          );
          vm.meeting.attendance_maybe = _.orderBy(
            response.data.data.attendance_maybe,
            ["last_name", "first_name"],
            ["asc", "asc"]
          );
          vm.meeting.attendance_no = _.orderBy(
            response.data.data.attendance_no,
            ["last_name", "first_name"],
            ["asc", "asc"]
          );
          vm.meeting.not_registered = _.orderBy(
            response.data.data.not_registered,
            ["last_name", "first_name"],
            ["asc", "asc"]
          );
          vm.loading = false;
        });
      }
    },
    showUsers(users, title) {
      this.dialogUsers = users;
      this.dialogTitle = title;
      this.dialog = true;
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    }
  },
  computed: {
    date_t() {
      if (this.start_date == this.end_date) {
        return (
          format(this.meeting.start, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          }) +
          " - " +
          format(this.meeting.end, "HH:mm", {
            locale: deLocale
          })
        );
      } else {
        return (
          format(this.meeting.start, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          }) +
          " - " +
          format(this.meeting.end, "dd, Do MMMM YYYY HH:mm", {
            locale: deLocale
          })
        );
      }
    },
    registerUntilDateText() {
      if (this.meeting.register_until) {
        return format(this.meeting.register_until, "dd, Do MMMM YYYY", {
          locale: deLocale
        });
      } else {
        return "Keine";
      }
    },
    meetingGroup() {
      var array = [];
      this.meeting.user_groups.forEach(function(element) {
        array.push(element.name);
      });
      return array.length != 0 ? array.join(", ") : "Keine";
    },
    vegies() {
      let yes = this.meeting.attendance_yes.filter(function(element) {
        return element.vegetarian == true;
      });
      let maybe = this.meeting.attendance_maybe.filter(function(element) {
        return element.vegetarian == true;
      });
      let text = yes.length.toString();
      if (maybe.length >= 1) {
        text += " (" + maybe.length.toString() + ")";
      }
      return text;
    },
    healthInfo() {
      let yes = this.meeting.attendance_yes.filter(function(element) {
        return element.health_information != null;
      });
      let maybe = this.meeting.attendance_maybe.filter(function(element) {
        return element.health_information != null;
      });
      let text = yes.length.toString();
      if (maybe.length >= 1) {
        text += " (" + maybe.length.toString() + ")";
      }
      return text;
    },
    isCurrentUserMeetingParticipator() {
      const vm = this;
      if (
        vm.meeting.attendance_maybe.find(function(element) {
          return element.id === vm.myuser.id;
        })
      ) {
        return true;
      }
      if (
        vm.meeting.attendance_yes.find(function(element) {
          return element.id === vm.myuser.id;
        })
      ) {
        return true;
      }
      if (
        vm.meeting.attendance_no.find(function(element) {
          return element.id === vm.myuser.id;
        })
      ) {
        return true;
      }
      if (
        vm.meeting.not_registered.find(function(element) {
          return element.id === vm.myuser.id;
        })
      ) {
        return true;
      } else return false;
    },
    data() {
      var participants = [];
      this.meeting.attendance_yes.forEach(element => {
        element.attendance = "yes";
        participants.push(element);
      });
      this.meeting.attendance_maybe.forEach(element => {
        element.attendance = "maybe";
        participants.push(element);
      });
      return participants;
    },
    ...mapGetters({ myuser: "getUser" })
  },
  components: {
    meetingDate: {
      props: ["value"],
      template: '<span v-html="mailDate"></span>',
      computed: {
        mailDate() {
          return this.value
            ? format(this.value, "dddd, Do MMMM YYYY", {
                locale: deLocale
              })
            : "";
        }
      }
    },
    mailIcon: {
      props: ["value"],
      components: {
        VIcon
      },
      template:
        '<v-icon v-html="mailIconComputed" :color="mailColorComputed"></v-icon>',
      computed: {
        mailIconComputed() {
          return this.icon(this.value);
        },
        mailColorComputed() {
          return this.color(this.value);
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case true:
              return mdiCheck;
            case false:
              return mdiClose;
          }
        },
        color(state) {
          switch (state) {
            case true:
              return "green";
            case false:
              return "red";
          }
        }
      }
    },
    meetingIcon: {
      props: ["value"],
      components: {
        VIcon
      },
      template:
        '<v-icon v-html="meetingIconComputed" :color="meetingColorComputed"></v-icon>',
      computed: {
        meetingIconComputed() {
          return this.value ? this.icon(this.value) : mdiPlus;
        },
        meetingColorComputed() {
          return this.value ? this.color(this.value) : "primary";
        }
      },
      methods: {
        icon(state) {
          switch (state) {
            case "yes":
              return mdiCheck;
            case "no":
              return mdiClose;
            case "maybe":
              return mdiRadioboxBlank;
            default:
              return mdiPlus;
          }
        },
        color(state) {
          switch (state) {
            case "yes":
              return "green";
            case "no":
              return "red";
            case "maybe":
              return "orange";
            default:
              return "primary";
          }
        }
      }
    }
  }
};
</script>

<style>
.datatable-nobottom .v-datatable__actions {
  display: none;
}
</style>
