import axios from "axios";
import store from "@/store";
import { AUTH_REFRESH } from "@/store/actions/auth";
import createAuthRefreshInterceptor from "@/refresh";
import handleError from "@/axiosErrorHandler";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

apiClient.defaults.headers.common["Accept"] = "application/json";
apiClient.interceptors.request.use(request => {
  if (store.getters.isAuthenticated) {
    request.headers["Authorization"] = store.getters.bearer;
  }
  return request;
});

apiClient.interceptors.response.use(res => res, handleError);

createAuthRefreshInterceptor(apiClient, failedRequest =>
  store.dispatch(AUTH_REFRESH, failedRequest)
);

export default {
  login(credentials) {
    return apiClient.post("/login", credentials);
  },
  logout() {
    return apiClient.post("/logout");
  },
  activate(credentials) {
    return apiClient.post("/activate", credentials);
  },
  resetPassword(credentials) {
    return apiClient.post("/reset_password", credentials);
  },
  refresh(oldRefreshToken) {
    return apiClient.post("/refresh", { refresh_token: oldRefreshToken });
  },
  me() {
    return apiClient.get("/me");
  },
  selfUpdate(user) {
    return apiClient.post("/self_update", user);
  },
  requestPasswordReset(reset) {
    return apiClient.post("/request_password_reset", reset);
  },
  changePassword(credentials) {
    return apiClient.post("/change_password", credentials);
  },
  userGroups() {
    return apiClient.get("/user_groups");
  },
  userGroupsGet(id) {
    return apiClient.get("/user_groups/" + id);
  },
  userGroupsCreate(userGroup) {
    return apiClient.post("/user_groups", userGroup);
  },
  userGroupsEdit(userGroup) {
    return apiClient.put("/user_groups/" + userGroup.id, userGroup);
  },
  userGroupsDelete(userGroup) {
    return apiClient.delete("/user_groups/" + userGroup.id);
  },
  divisions() {
    return apiClient.get("/divisions");
  },
  divisionsGet(id) {
    return apiClient.get("/divisions/" + id);
  },
  divisionsCreate(division) {
    return apiClient.post("/divisions", division);
  },
  divisionsEdit(division) {
    return apiClient.put("/divisions/" + division.id, division);
  },
  divisionsDelete(division) {
    return apiClient.delete("/divisions/" + division.id);
  },
  positions() {
    return apiClient.get("/positions");
  },
  users() {
    return apiClient.get("/users");
  },
  usersGet(id) {
    return apiClient.get("/users/" + id);
  },
  usersCreate(user) {
    return apiClient.post("/users", user);
  },
  usersEdit(id, user) {
    return apiClient.put("/users/" + id, user);
  },
  usersDelete(user) {
    return apiClient.delete("/users/" + user.id);
  },
  usersSimple() {
    return apiClient.get("/users_simple");
  },
  meetings(old) {
    var e = "/meetings";
    if (old) {
      e = "/meetings_archive";
    }
    return apiClient.get(e);
  },
  meetingsAdvanced(id) {
    return apiClient.get("/meetings/advanced/" + id);
  },
  meetingsGet(id) {
    return apiClient.get("/meetings/" + id);
  },
  meetingsCreate(meeting) {
    return apiClient.post("/meetings", meeting);
  },
  meetingsEdit(id, meeting) {
    return apiClient.put("/meetings/" + id, meeting);
  },
  meetingsDelete(meeting) {
    return apiClient.delete("/meetings/" + meeting.id);
  },
  notificationsCreate(route, mail) {
    if (mail) {
      return apiClient.post("/notifications" + route, mail);
    } else {
      return apiClient.post("/notifications" + route);
    }
  },
  doodle(token) {
    if (token) return apiClient.get("/token_doodle/" + token);
    else return apiClient.get("/doodle");
  },
  doodleArchive(token) {
    if (token) return apiClient.get("/token_doodle_archive/" + token);
    else return apiClient.get("/doodle_archive");
  },
  doodleShow(id, token) {
    if (token) return apiClient.get("/token_doodle/" + id + "/" + token);
    else return apiClient.get("/doodle/" + id);
  },
  doodleEdit(id, doodle, token) {
    if (token)
      return apiClient.put("/token_doodle/" + id + "/" + token, doodle);
    else return apiClient.put("/doodle/" + id, doodle);
  },
  events() {
    return apiClient.get("/events");
  },
  eventsGet(id) {
    return apiClient.get("/events/" + id);
  },
  eventsCreate(event) {
    return apiClient.post("/events", event);
  },
  eventsEdit(id, event) {
    return apiClient.put("/events/" + id, event);
  },
  eventsAdvanced(id) {
    return apiClient.get("/events/advanced/" + id);
  },
  eventsDelete(event) {
    return apiClient.delete("/events/" + event.id);
  },
  doodleEvents(token) {
    if (token) return apiClient.get("/token_event_doodle/" + token);
    else return apiClient.get("/event_doodle");
  },
  doodleEventsGet(id, token) {
    if (token) return apiClient.get("/token_event_doodle/" + id + "/" + token);
    else return apiClient.get("/event_doodle/" + id);
  },
  doodleEventsEdit(id, event, token) {
    if (token)
      return apiClient.put("/token_event_doodle/" + id + "/" + token, event);
    else return apiClient.put("/event_doodle/" + id, event);
  },
  roles() {
    return apiClient.get("/roles");
  },
  rolesGet(id) {
    return apiClient.get("/roles/" + id);
  },
  rolesCreate(role) {
    return apiClient.post("/roles", role);
  },
  rolesEdit(id, role) {
    return apiClient.put("/roles/" + id, role);
  },
  rolesDelete(role) {
    return apiClient.delete("/roles/" + role.id);
  },
  qualificationsScba() {
    return apiClient.get("/qualification/scba");
  },
  qualificationsDriver() {
    return apiClient.get("/qualification/driver");
  },
  qualificationsAdd(add) {
    return apiClient.post("/qualification/scba", add);
  },
  fileCancelToken() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
  },
  filesUpload(file, source) {
    return apiClient({
      data: file,
      cancelToken: source.token,
      url: process.env.VUE_APP_API_URL + "/files",
      method: "POST",

    });
  },

  filesDelete(id) {
    return apiClient.delete("/files/" + id);
  },
  filesGet(id) {
    return apiClient({
      url: process.env.VUE_APP_API_URL + "/files/" + id,
      method: "GET",
      responseType: "blob"
    });
  },
  taskCategoryGet() {
    return apiClient.get("/task_categories");
  },
  taskCategoryCreate(taskCategory) {
    return apiClient.post("/task_categories", { name: taskCategory });
  },
  taskCategoryDelete(id) {
    return apiClient.delete("/task_categories/" + id);
  },
  taskCategoryUpdate(id, taskCategory) {
    return apiClient.put("/task_categories/" + id, { name: taskCategory });
  },
  task() {
    return apiClient.get("/tasks");
  },
  taskGet(id) {
    return apiClient.get("/tasks/" + id);
  },
  taskCreate(task) {
    return apiClient.post("/tasks", task);
  },
  taskDelete(id) {
    return apiClient.delete("/tasks/" + id);
  },
  taskUpdate(id, task) {
    return apiClient.put("/tasks/" + id, task);
  },
  taskGetArchive() {
    return apiClient.get("/tasks_archive");
  },
  taskLightPatch(id, task) {
    return apiClient.patch("/tasks_light/" + id, task);
  },
  orders(old) {
    var e = "/order_items";
    if (old) {
      e = "/order_items_archive";
    }
    return apiClient.get(e);
  },
  ordersGet(id) {
    return apiClient.get("/order_items/" + id);
  },
  ordersCreate(order) {
    return apiClient.post("/order_items", order);
  },
  ordersDelete(id) {
    return apiClient.delete("/order_items/" + id);
  },
  ordersEdit(id, order) {
    return apiClient.put("/order_items/" + id, order);
  },
  ordersArchive() {
    return apiClient.get("/order_items_archive");
  },
  shops() {
    return apiClient.get("/shops");
  },
  shopsCreate(shop) {
    return apiClient.post("/shops", shop);
  },
  shopsDelete(id) {
    return apiClient.delete("/shops/" + id);
  },
  shopsEdit(id, shop) {
    return apiClient.put("/shops/" + id, shop);
  },
  presence() {
    return apiClient.get("/presence");
  },
  presenceEdit(id, presence) {
    return apiClient.put("/presence/" + id, presence);
  },
  adminActivations() {
    return apiClient.get("/admin/activations");
  },
  adminActivationsGet(id) {
    return apiClient.get("/admin/activations/" + id);
  },
  adminFailedJobs() {
    return apiClient.get("/admin/failed_jobs");
  },
  adminFailedJobsGet(id) {
    return apiClient.get("/admin/failed_jobs/" + id);
  },
  adminFailedJobsDelete(id, failedJob) {
    return apiClient.delete("/admin/failed_jobs/" + id, failedJob);
  },
  adminFailedJobsDeleteAll() {
    return apiClient.delete("/admin/failed_jobs/flush");
  },
  adminFeedbackRecipients() {
    return apiClient.get("/admin/feedback_recipients");
  },
  adminFeedbackRecipientsGet(id) {
    return apiClient.get("/admin/feedback_recipients/" + id);
  },
  adminFeedbackRecipientsCreate(recipient) {
    return apiClient.post("/admin/feedback_recipients", recipient);
  },
  adminFeedbackRecipientsEdit(id, recipient) {
    return apiClient.put("/admin/feedback_recipients/" + id, recipient);
  },
  adminFeedbackRecipientsDelete(id) {
    return apiClient.delete("/admin/feedback_recipients/" + id);
  },
  adminFeedbackTemplates() {
    return apiClient.get("/admin/feedback_templates");
  },
  adminFeedbackTemplatesGet(id) {
    return apiClient.get("/admin/feedback_templates/" + id);
  },
  adminFeedbackTemplatesCreate(template) {
    return apiClient.post("/admin/feedback_templates", template);
  },
  adminFeedbackTemplatesEdit(id, template) {
    return apiClient.put("/admin/feedback_templates/" + id, template);
  },
  adminFeedbackTemplatesDelete(id) {
    return apiClient.delete("/admin/feedback_templates/" + id);
  },
  feedbackCreate(feedback) {
    return apiClient.post("/feedback", feedback);
  },
  adminOAuthClients() {
    return apiClient.get("/admin/oauth_clients");
  },
  adminOAuthClientsGet(id) {
    return apiClient.get("/admin/oauth_clients/" + id);
  },
  adminOAuthClientsCreate(client) {
    return apiClient.post("/admin/oauth_clients", client);
  },
  adminOAuthClientsEdit(id, client) {
    return apiClient.put("/admin/oauth_clients/" + id, client);
  },
  adminOAuthClientsDelete(id) {
    return apiClient.delete("/admin/oauth_clients/" + id);
  }
};
