<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">
            Helfer {{ this.$route.params.id ? "bearbeiten" : "erstellen" }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                id="thwin_id"
                v-model="user.thwin_id"
                :counter="8"
                v-mask="masks.thwin_id"
                :rules="rules.thwin_id"
                label="ID"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                id="first_name"
                v-model="user.first_name"
                :rules="rules.required"
                label="Vorname"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                id="last_name"
                v-model="user.last_name"
                :rules="rules.required"
                label="Nachname"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                id="gender"
                v-model="user.gender"
                :items="genders"
                item-text="name"
                item-value="val"
                label="Geschlecht"
                :rules="rules.required"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="birth_date_menu"
                v-model="birth_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    id="birth"
                    v-on="on"
                    v-model="birth_date_t"
                    label="Geburtsdatum"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="birth_date"
                  v-model="user.birth"
                  :max="new Date().toISOString().substr(0, 10)"
                  @input="birth_date_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                id="division"
                v-model="user.division"
                :items="divisions"
                item-text="name"
                item-value="id"
                label="Einheit"
                :rules="rules.required"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                id="position"
                v-model="user.position"
                :items="positions"
                :item-text="positionT"
                item-value="id"
                label="Funktion"
                :rules="rules.required"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                id="user_group"
                auto-select-first
                v-model="user.user_groups"
                :items="user_groups"
                item-text="name"
                item-value="id"
                label="Helfergruppe"
                multiple
                :search-input.sync="searchGroups"
                @change="searchGroups = ''"
                :rules="rules.required"
                required
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                id="mobile"
                v-model="user.mobile"
                label="Handynummer"
                placeholder="+49..."
                v-mask="masks.mobile"
                validate-on-blur
                :rules="rules.mobile"
                type="tel"
                autocomplete="tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                id="phone"
                v-model="user.phone"
                label="Festnetznummer"
                :rules="rules.phone"
                validate-on-blur
                placeholder="+49..."
                v-mask="masks.phone"
                type="tel"
                autocomplete="tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                id="mail"
                v-model="user.email"
                :rules="rules.email"
                validate-on-blur
                required
                label="E-Mail"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="scbaCheck"
                label="Atemschutz"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="scbaCheck">
            <v-col cols="12" md="3">
              <v-menu
                v-model="next_examination_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="next_examination_t"
                    label="Gültigkeit G26.3 Untersuchung"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.scba.next_examination"
                  @input="next_examination_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="last_instruction_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="last_instruction_t"
                    label="Letzte jährliche Unterweisung"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.scba.last_instruction"
                  @input="last_instruction_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="last_excercise_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="last_excercise_t"
                    label="Letzte jährliche Belastungsübung"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.scba.last_excercise"
                  @input="last_excercise_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="last_deployment_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="last_deployment_t"
                    label="Letzte jährliche Einsatzübung/Einsatz"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.scba.last_deployment"
                  @input="last_deployment_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-if="scbaCheck">
            <v-col>
              <v-checkbox
                v-model="cbrn"
                label="CBRN Ausbildung"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="cbrn && scbaCheck">
            <v-col cols="12" md="3">
              <v-menu
                v-model="last_cbrn_deployment_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="last_cbrn_deployment_t"
                    label="Letzte CBRN Übung/Einsatz"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="user.scba.last_cbrn_deployment"
                  @input="last_cbrn_deployment_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="driver"
                :items="drivers_licences"
                item-text="name"
                item-value="val"
                label="Führerscheinklasse"
                multiple
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="driver.includes('c') || driver.includes('ce')"
            >
              <v-menu
                v-model="driver_date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:[`activator`]="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="driver_date_t"
                    label="Ablaufdatum Führerschein"
                    clearable
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="driver_date"
                  @input="driver_date_menu = false"
                  color="primary"
                  locale="de-de"
                  first-day-of-week="1"
                  :scrollable="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                color="primary"
                large
                class="ml-0"
                :disabled="!valid"
                @click="save()"
                >{{ this.$route.params.id ? "Speichern" : "Erstellen" }}</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="primary"
                large
                @click="$router.push({ name: 'user' })"
                >Abbrechen</v-btn
              >
            </v-col>
          </v-row>

          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import format from "date-fns/format";
import _ from "lodash";
import { mask } from "vue-the-mask";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";

export default {
  directives: {
    mask
  },
  data: () => ({
    user: {
      thwin_id: "",
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      birth: "",
      mobile: "",
      phone: "",
      division_id: "",
      position_id: "",
      user_groups: [],
      scba: {
        next_examination: "",
        last_instruction: "",
        last_excercise: "",
        last_deployment: "",
        cbrn: false,
        last_cbrn_deployment: ""
      },
      driver: [],
      driver_date: ""
    },
    driver: [],
    driver_date: "",
    cbrn: false,
    scbaCheck: false,
    genders: [
      { name: "Männlich", val: "m" },
      { name: "Weiblich", val: "f" },
      { name: "Divers", val: "x" }
    ],
    loader: false,
    divisions: null,
    positions: null,
    user_groups: null,
    searchGroups: "",
    birth_date_menu: false,
    next_examination_menu: false,
    last_instruction_menu: false,
    last_excercise_menu: false,
    last_deployment_menu: false,
    last_cbrn_deployment_menu: false,
    drivers_licences: [
      { name: "B", val: "b" },
      { name: "BE", val: "be" },
      { name: "C", val: "c" },
      { name: "CE", val: "ce" }
    ],
    driver_date_menu: false,
    valid: true,
    rules: {
      required: [v => !!v || "Benötigt"],
      thwin_id: [v => v.length >= 8 || "ID muss genau 8 Ziffern lang sein"],
      email: [
        v => !!v || "Benötigt",
        v =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Ungültige E-Mail"
      ],
      mobile: [
        v =>
          !v ||
          /(^$|^(\+\d{1,15})$)/.test(v) ||
          "Bitte in folgendem Format: +49..."
      ],
      phone: [
        v =>
          !v ||
          /(^$|^(\+\d{1,15})$)/.test(v) ||
          "Bitte in folgendem Format: +49..."
      ]
    },
    masks: {
      thwin_id: "########",
      mobile: "+###############",
      phone: "+###############"
    }
  }),
  created: function() {
    this.loadUsergroups();
    this.loadDivisions();
    this.loadPositions();
    this.loadUser();
  },
  computed: {
    birth_date_t: {
      get: function() {
        return this.user.birth
          ? format(this.user.birth, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.birth = value;
      }
    },
    next_examination_t: {
      get: function() {
        return this.user.scba.next_examination
          ? format(this.user.scba.next_examination, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.next_examination = value;
      }
    },
    last_instruction_t: {
      get: function() {
        return this.user.scba.last_instruction
          ? format(this.user.scba.last_instruction, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_instruction = value;
      }
    },
    last_excercise_t: {
      get: function() {
        return this.user.scba.last_excercise
          ? format(this.user.scba.last_excercise, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_excercise = value;
      }
    },
    last_deployment_t: {
      get: function() {
        return this.user.scba.last_deployment
          ? format(this.user.scba.last_deployment, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_deployment = value;
      }
    },
    last_cbrn_deployment_t: {
      get: function() {
        return this.user.scba.last_cbrn_deployment
          ? format(this.user.scba.last_cbrn_deployment, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.user.scba.last_cbrn_deployment = value;
      }
    },
    driver_date_t: {
      get: function() {
        return this.driver_date
          ? format(this.driver_date, "dddd, Do MMMM YYYY", {
              locale: deLocale
            })
          : "";
      },
      set: function(value) {
        this.driver_date = value;
      }
    }
  },
  watch: {
    birth_date_menu(val) {
      val && setTimeout(() => (this.$refs.birth_date.activePicker = "YEAR"));
    }
  },
  methods: {
    loadUser() {
      if (this.$route.params.id) {
        const vm = this;
        vm.id = this.$route.params.id;
        Api.usersGet(vm.id).then(function(response) {
          vm.user = response.data.data;
          if (response.data.data.driver == null) {
            vm.driver = [];
          } else {
            vm.driver_date = vm.user.driver.expiry_date;
            let e = [];
            if (vm.user.driver.b) e.push("b");
            if (vm.user.driver.be) e.push("be");
            if (vm.user.driver.c) e.push("c");
            if (vm.user.driver.ce) e.push("ce");
            vm.driver = e;
          }
          if (response.data.data.scba == null) {
            vm.user.scba = {
              next_examination: "",
              last_instruction: "",
              last_excercise: "",
              last_deployment: "",
              cbrn: false,
              last_cbrn_deployment: ""
            };
          } else {
            vm.scbaCheck = true;
            vm.cbrn = response.data.data.scba.cbrn;
          }
        });
      }
    },
    save() {
      const vm = this;
      if (this.$refs.form.validate()) {
        this.loader = true;
        let newUser = vm.user;
        let drive = vm.driver;
        if (!vm.scbaCheck) {
          newUser.scba = null;
        } else newUser.scba.cbrn = vm.cbrn;
        if (vm.user.division.id) newUser.division = vm.user.division.id;
        else newUser.division = vm.user.division;
        if (vm.user.position.id) newUser.position = vm.user.position.id;
        else newUser.position = vm.user.position;
        if (typeof vm.user.user_groups[0] === "object") {
          var ids = [];
          vm.user.user_groups.forEach(element => {
            ids.push(element.id);
          });
          newUser.user_groups = ids;
        }
        newUser.driver = {};
        if (drive.includes("b")) newUser.driver.b = true;
        if (drive.includes("be")) newUser.driver.be = true;
        if (drive.includes("c")) newUser.driver.c = true;
        if (drive.includes("ce")) newUser.driver.ce = true;
        newUser.driver.expiry_date = vm.driver_date;
        if (vm.$route.params.id) {
          Api.usersEdit(vm.id, newUser).then(() => {
            vm.loader = false;
            vm.$router.push({ name: "user" }, () => {
              EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
            });
          });
        } else {
          Api.usersCreate(newUser)
            .then(() => {
              vm.loader = false;
              vm.$router.push({ name: "user" }, () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              });
            })
            .catch(() => {
              vm.loader = false;
            });
        }
      }
    },
    loadUsergroups: function() {
      const vm = this;
      Api.userGroups().then(function(response) {
        vm.user_groups = [];
        response.data.data.forEach(element => {
          if (!element.generated) {
            vm.user_groups.push(element);
          }
          vm.user_groups = _.sortBy(vm.user_groups, "name");
        });
      });
    },
    loadDivisions: function() {
      const vm = this;
      Api.divisions().then(function(response) {
        vm.divisions = response.data.data;
        vm.divisions = _.sortBy(vm.divisions, ["name"]);
      });
    },
    loadPositions: function() {
      const vm = this;
      Api.positions().then(function(response) {
        vm.positions = response.data.data;
        vm.positions = _.sortBy(vm.positions, ["male"]);
      });
    },
    positionT: function(item) {
      const vm = this;
      switch (vm.user.gender) {
        case "m":
          return item.male;
        case "f":
          return item.female;
        case "x":
          return item.inter;
        default:
          return item.inter;
      }
    }
  }
};
</script>

<style></style>
