/* eslint-disable no-console */

import { register } from "register-service-worker";

export default function registerServiceWorker() {
  register("/service-worker.js", {
    ready() {
      console.log("Service worker is ready.");
    },
    registered(registration) {
      console.log("Service worker has been registered.");

      // Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update();
      }, 1000 * 60 * 60); // hourly checks
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh.");

      // Used to display of a 'refresh' banner following a service worker update.
      document.dispatchEvent(
        new CustomEvent("SW_UPDATED", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    }
  });
}
