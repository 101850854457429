<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 font-weight-bold primary--text">Administration</h1>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-list>
            <template v-for="(item, index) in canItems">
              <v-list-item
                :key="item.title"
                :to="{ name: item.link }"
                style="cursor: pointer"
              >
                <v-list-item-content>
                  <v-list-item-title v-html="item.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon color="primary">{{ icons.mdiSend }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < canItems.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiSend } from "@mdi/js";

export default {
  data: () => ({
    items: [
      {
        title: "Benutzerübersicht",
        link: "activations",
        permission: "admin.general"
      },
      {
        title: "Gescheiterte Jobs",
        link: "failedjobs",
        permission: "admin.general"
      },
      {
        title: "Feedback-Templates",
        link: "feedbacktemplates",
        permission: "admin.general"
      },
      {
        title: "Feedback-Empfänger",
        link: "feedbackrecipients",
        permission: "admin.general"
      },
      {
        title: "Clients verwalten",
        link: "oauthclients",
        permission: "admin.general"
      },
      {
        title: "Benutzerrollen verwalten",
        link: "role",
        permission: "roles.general"
      }
    ],
    icons: { mdiSend }
  }),
  computed: {
    canItems() {
      return this.items.filter(h => this.$can(h.permission));
    }
  }
};
</script>

<style></style>
