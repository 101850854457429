var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-h3 font-weight-bold primary--text"},[_vm._v("Doodle")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","large":"","right":""},on:{"click":function($event){_vm.calendar = !_vm.calendar}}},[_c('span',[_vm._v(_vm._s(_vm.calendar ? "Listenansicht" : "Kalenderansicht"))])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(!_vm.calendar)?_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loader,"sort-by":"start"},on:{"update:page":function($event){return _vm.goTop()},"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('meetingDate',{attrs:{"value":item.start}})]}},{key:"item.user.attendance",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","small":""}},on),[_c('meetingIcon',{attrs:{"value":item.user.attendance}})],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.getAttendances(
                    item.user.attendance
                  )),function(element,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.changeAttendance(item, element)}}},[_c('v-list-item-content',[_c('meetingIcon',{attrs:{"value":element}})],1)],1)}),1)],1)]}}],null,true)})],1):_vm._e(),(_vm.calendar)?_c('v-card',[_c('v-row',{directives:[{name:"touch",rawName:"v-touch",value:({
            left: function () { return _vm.calNext(); },
            right: function () { return _vm.calPrev(); }
          }),expression:"{\n            left: () => calNext(),\n            right: () => calPrev()\n          }"}],staticClass:"fill-height"},[_c('v-col',[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticStyle:{"text-align":"center"},attrs:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.calTitle)+" ")]):_vm._e(),_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.calSetToday}},[_vm._v("Heute")]),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":_vm.calPrev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronLeft))])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":_vm.calNext}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.calTitle))]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outlined":"","color":"primary"}},on),[_c('span',[_vm._v(_vm._s(_vm.cal.typeToLabel[_vm.cal.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icons.mdiMenuDown))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.cal.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Tag")])],1),_c('v-list-item',{on:{"click":function($event){_vm.cal.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Woche")])],1),_c('v-list-item',{on:{"click":function($event){_vm.cal.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Monat")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","weekdays":[1, 2, 3, 4, 5, 6, 0],"events":_vm.calendarMeetings,"event-name":"text-h4","event-color":_vm.calGetEventColor,"event-margin-bottom":3,"now":_vm.cal.today,"type":_vm.cal.type},on:{"click:event":_vm.calEditItem,"click:more":_vm.calViewDay,"click:date":_vm.calViewDay,"change":_vm.calUpdateRange},model:{value:(_vm.cal.focus),callback:function ($$v) {_vm.$set(_vm.cal, "focus", $$v)},expression:"cal.focus"}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }