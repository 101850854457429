import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/es5/util/colors";
import de from "vuetify/es5/locale/de";
import { Touch } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Touch
  }
});

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    themes: {
      light: {
        primary: colors.teal.darken2,
        secondary: colors.teal.lighten2,
        accent: colors.indigo.base // #3F51B5
      },
      dark: {
        primary: colors.teal.lighten2,
        secondary: colors.teal.darken2,
        accent: colors.indigo.base // #3F51B5
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  }
});
