<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-card-title class="text-h5 primary white--text" primary-title
          >Feedback senden</v-card-title
        >
        <v-card-text class="mt-5">
          <v-select
            :items="templates"
            v-model="selectedID"
            label="Vorlage"
            item-text="name"
            item-value="id"
            bottom
            autocomplete
          ></v-select>
          <v-text-field
            v-model="title"
            :rules="rules.required"
            label="Betreff"
            required
          ></v-text-field>
          <editor v-model="template" style="mt-1" />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="grey"
            text
            v-if="selectedID"
            @click="
              template = {
                ...templates.filter(e => e.id == selectedID)[0]
              }.template
            "
            >Zurücksetzen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false">Abbrechen</v-btn>
          <v-btn text color="primary" :disabled="!valid" @click="send"
            >Senden</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@/components/Editor";
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";

export default {
  components: { Editor },
  data: () => ({
    dialog: false,
    valid: false,
    templates: [],
    title: "",
    template: "",
    selectedID: "",
    rules: {
      required: [v => !!v || "Benötigt"]
    }
  }),
  watch: {
    selectedID: function(val) {
      this.template = {
        ...this.templates.filter(e => e.id == val)[0]
      }.template;
    }
  },
  methods: {
    loadFeedbackTemplates: function() {
      const vm = this;
      Api.adminFeedbackTemplates().then(response => {
        vm.templates = response.data.data;
      });
    },
    open() {
      this.loadFeedbackTemplates();
      this.dialog = true;
    },
    send() {
      if (this.template != "") {
        const vm = this;
        this.dialog = false;
        Api.feedbackCreate({
          subject: this.title,
          body: this.template
        }).then(() => {
          (vm.title = ""), (vm.template = "");
          EventBus.$emit(
            "showSnackbar",
            "Feedback erfolgreich gesendet!",
            "primary"
          );
        });
      }
    }
  }
};
</script>
