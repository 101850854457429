<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <h1 class="text-h3 font-weight-bold primary--text">
              {{ archiveSwitch ? "Dienste" : "Vergangene Dienste" }}
            </h1>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              color="primary"
              @click="calendar = !calendar"
              outlined
              large
              right
            >
              <span>{{ calendar ? "Listenansicht" : "Kalenderansicht" }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card v-if="!calendar">
          <v-card-title>
            <v-btn outlined color="primary" :to="{ name: 'meetingcreate' }"
              >Erstellen</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Suche"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            ref="dataTable"
            @update:page="goTop()"
            :headers="headers"
            :items="data"
            :search="search"
            :loading="loader"
            sort-by="start"
            :sort-desc.sync="desc"
            @click:row="linkDataTableRow"
          >
            <template v-slot:[`item.start`]="{ item }">
              <meetingDate :value="item.start"></meetingDate>
            </template>
            <template v-slot:[`item.user_groups`]="{ item }">
              <meetingGroups :value="item.user_groups"></meetingGroups>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="$can('meetings.general')"
                text
                icon
                small
                :to="{ name: 'meetingedit', params: { id: item.id } }"
              >
                <v-icon color="primary">{{ icons.mdiPencil }}</v-icon>
              </v-btn>
              <v-btn
                v-if="$can(['meetings.general', 'meetings.advanced'])"
                text
                icon
                small
                :to="{ name: 'meetingshow', params: { id: item.id } }"
              >
                <v-icon color="primary">{{ icons.mdiCalendar }}</v-icon>
              </v-btn>
              <v-btn
                v-if="$can('meetings.general')"
                text
                icon
                small
                @click="deleteItem(item)"
              >
                <v-icon color="primary">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <Confirm ref="confirm"></Confirm>
        </v-card>
        <v-card v-if="calendar">
          <v-row
            class="fill-height"
            v-touch="{
              left: () => calNext(),
              right: () => calPrev()
            }"
          >
            <v-col>
              <div
                v-if="$vuetify.breakpoint.smAndDown"
                width="100%"
                style="text-align: center"
              >
                {{ calTitle }}
              </div>
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    @click="calSetToday"
                    color="primary"
                    >Heute</v-btn
                  >
                  <v-btn fab text small @click="calPrev">
                    <v-icon small>{{ icons.mdiChevronLeft }}</v-icon>
                  </v-btn>
                  <v-btn fab text small @click="calNext">
                    <v-icon small>{{ icons.mdiChevronRight }}</v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown">
                    {{ calTitle }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:[`activator`]="{ on }">
                      <v-btn outlined v-on="on" color="primary">
                        <span>{{ cal.typeToLabel[cal.type] }}</span>
                        <v-icon right>{{ icons.mdiMenuDown }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cal.type = 'day'">
                        <v-list-item-title>Tag</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="cal.type = 'week'">
                        <v-list-item-title>Woche</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="cal.type = 'month'">
                        <v-list-item-title>Monat</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="cal.focus"
                  color="primary"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :events="data"
                  event-name="title"
                  :event-color="calGetEventColor"
                  :event-margin-bottom="3"
                  :now="cal.today"
                  :type="cal.type"
                  @click:event="calEditItem"
                  @click:more="calViewDay"
                  @click:date="calViewDay"
                  @change="calUpdateRange"
                ></v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-bottom-navigation v-if="!calendar" :value="bottomNav" color="primary">
      <v-btn
        @click="
          archiveSwitch = true;
          archive();
        "
      >
        <span>Kommende Dienste</span>
        <v-icon>{{ icons.mdiProgressClock }}</v-icon>
      </v-btn>

      <v-btn
        @click="
          archiveSwitch = false;
          archive();
        "
      >
        <span>Vergangene Dienste</span>
        <v-icon>{{ icons.mdiArchive }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import Confirm from "@/components/Confirm";
import format from "date-fns/format";
var deLocale = require("date-fns/locale/de");
import Api from "@/services/ApiService.js";
import EventBus from "@/eventBus";
import {
  mdiPencil,
  mdiCalendar,
  mdiDelete,
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenuDown,
  mdiProgressClock,
  mdiArchive,
  mdiMagnify
} from "@mdi/js";

export default {
  data: () => ({
    loader: false,
    archiveSwitch: true,
    calendar: false,
    past: false,
    future: false,
    futureMeetings: [],
    pastMeetings: [],
    member_groups: [],
    search: "",
    bottomNav: 0,
    desc: false,
    data: [],
    headers: [
      {
        text: "Datum",
        align: "left",
        value: "start"
      },
      {
        text: "Titel",
        align: "left",
        value: "title"
      },
      {
        text: "Gruppen",
        align: "left",
        value: "user_groups"
      },
      {
        text: "Aktion",
        align: "right",
        sortable: false,
        value: "action"
      }
    ],
    cal: {
      today: format(new Date(), "YYYY-MM-DD"),
      focus: format(new Date(), "YYYY-MM-DD"),
      type: "month",
      typeToLabel: {
        month: "Monat",
        week: "Woche",
        day: "Tag"
      },
      start: null,
      end: null
    },
    icons: {
      mdiPencil,
      mdiCalendar,
      mdiDelete,
      mdiChevronLeft,
      mdiChevronRight,
      mdiMenuDown,
      mdiProgressClock,
      mdiArchive,
      mdiMagnify
    }
  }),
  created: function() {
    this.archive();
    this.loadMembergroups();
  },
  mounted: function() {
    if (this.$route.query.calendar || localStorage.meetingCalendar == "true") {
      this.calendar = true;
      this.loadMeetings();
    }
  },
  watch: {
    calendar: function(newVal, oldVal) {
      if (oldVal != null && newVal != oldVal) {
        if (newVal) {
          if (!this.$route.query.calendar) {
            localStorage.meetingCalendar = true;
            this.loadMeetings();
            this.$router.replace({
              name: "meeting",
              query: { calendar: newVal }
            });
          }
        } else {
          localStorage.meetingCalendar = false;
          this.past = false;
          this.future = true;
          this.$router.replace({ name: "meeting" });
        }
        this.archive();
      }
    },
    past: function() {
      this.setData();
    },
    future: function() {
      this.setData();
    }
  },
  computed: {
    calTitle() {
      const { start, end } = this.cal;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + ".";
      const endDay = end.day + ".";

      switch (this.cal.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
          return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
        case "day":
          return `${startDay} ${startMonth} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long"
      });
    }
  },
  methods: {
    loadMeetings: function() {
      this.loader = true;
      const vm = this;
      if (this.future) {
        Api.meetings()
          .then(function(response) {
            vm.futureMeetings = response.data.data;
            vm.future = true;
            vm.loader = false;
            vm.setData();
          })
          .catch(() => {
            vm.loader = false;
          });
      } else this.future;
      if (this.past) {
        Api.meetings(true)
          .then(function(response) {
            vm.pastMeetings = response.data.data;
            vm.past = true;
            vm.loader = false;
            vm.setData();
          })
          .catch(() => {
            vm.loader = false;
          });
      } else this.past = false;
    },
    loadMembergroups: function() {
      const vm = this;
      Api.userGroups().then(function(response) {
        vm.member_groups = response.data.data;
      });
    },
    deleteItem(item) {
      event.stopPropagation();
      const index = this.data.indexOf(item);
      this.$refs.confirm
        .open(
          "Löschen",
          "Bist du sicher? Das Element kann nicht wiederhergestellt werden!",
          { color: "red" }
        )
        .then(confirm => {
          if (confirm) {
            this.data.splice(index, 1);
            Api.meetingsDelete(item).then(() => {
              EventBus.$emit(
                "showSnackbar",
                "Erfolgreich gelöscht!",
                "primary"
              );
            });
          }
        });
    },
    editItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "meetingedit", params: { id: item.id } });
    },
    showItem(item) {
      event.stopPropagation();
      this.$router.push({ name: "meetingshow", params: { id: item.id } });
    },
    archive() {
      if (!this.archiveSwitch) {
        this.future = false;
        this.past = true;
        this.desc = true;
        this.bottomNav = 1;
      } else {
        this.future = true;
        this.past = false;
        this.desc = false;
        this.bottomNav = 0;
      }
      if (this.calendar) {
        this.future = true;
        this.past = true;
      }
      this.loadMeetings();
    },
    setData() {
      if (this.future) this.data = this.futureMeetings;
      if (this.past && this.future) {
        this.data = this.pastMeetings.concat(this.futureMeetings);
      }
      if (this.past && !this.future) this.data = this.pastMeetings;
      if (!this.past && !this.future) this.data = [];
    },
    goTop() {
      setTimeout(() => {
        this.$vuetify.goTo(".v-data-table");
      }, 50);
    },
    linkDataTableRow(v) {
      const vm = this;
      vm.$router.push({ name: "meetingshow", params: { id: v.id } });
    },
    calEditItem(item) {
      this.$router.push({
        name: "meetingshow",
        params: { id: item.event.id }
      });
    },
    calViewDay({ date }) {
      this.cal.focus = date;
      this.cal.type = "day";
    },
    calGetEventColor(event) {
      if (
        event.user_groups.filter(group => group.name.includes("Helfer"))
          .length >= 1
      )
        return "#003399";
      if (
        event.user_groups.filter(group => group.name.includes("Jung")).length >=
        1
      )
        return "#F6BF28";
      return "primary";
    },
    calSetToday() {
      this.cal.focus = this.cal.today;
    },
    calPrev() {
      this.$refs.calendar.prev();
    },
    calNext() {
      this.$refs.calendar.next();
    },
    calUpdateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.cal.start = start;
      this.cal.end = end;
    }
  },
  components: {
    Confirm,
    meetingDate: {
      props: ["value"],
      template: '<span v-html="meetingStartDate"></span>',
      computed: {
        meetingStartDate() {
          return this.value
            ? format(this.value, "dddd, Do MMMM YYYY", {
                locale: deLocale
              })
            : "";
        }
      }
    },
    meetingGroups: {
      props: ["value"],
      template: '<span v-html="meetingGroup"></span>',
      computed: {
        meetingGroup() {
          var array = [];
          this.value.forEach(function(element) {
            array.push(element.name);
          });
          return array.length != 0 ? array.join(", ") : "Keine";
        }
      }
    }
  }
};
</script>

<style></style>
