<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3 font-weight-bold primary--text">
            Benutzerrolle
            {{ this.$route.params.id ? "bearbeiten" : "erstellen" }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                label="Name"
                :rules="rules.required"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="permission in permissions"
              :key="permission.group"
              cols="12"
              sm="6"
              lg="3"
            >
              <p>{{ permission.group }}</p>
              <v-checkbox
                v-for="item in permission.permissions"
                :key="item.name"
                v-model="canVal[canValGet(item.model)]"
                :label="item.name"
                color="primary"
                class="my-0"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="role.users"
                label="Helfer"
                :items="users"
                item-text="'${data.item.first_name} ${data.item.last_name}'"
                item-value="id"
                multiple
                chips
                :filter="customFilter"
                :search-input.sync="searchUsers"
                @change="searchUsers = ''"
                auto-select-first
              >
                <template v-slot:[`selection`]="data">
                  <v-chip
                    :input-value="data.selected"
                    close
                    class="chip--select-multi"
                    @click:close="removeSelected(data.item)"
                  >
                    {{ data.item.first_name }}
                    {{ data.item.last_name }}
                  </v-chip>
                </template>
                <template v-slot:[`item`]="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="
                          `${data.item.first_name} ${data.item.last_name}`
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="auto">
              <v-btn
                color="primary"
                large
                class="ml-0"
                :disabled="!valid"
                @click="save()"
                >{{ this.$route.params.id ? "Speichern" : "Erstellen" }}</v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="primary"
                large
                @click="$router.push({ name: 'role' })"
                >Abbrechen</v-btn
              >
            </v-col>
          </v-row>

          <v-dialog v-model="loader" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-4">
                Bitte warten!
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Api from "@/services/ApiService.js";
import { USER_REQUEST } from "@/store/actions/user";
import _ from "lodash";
import EventBus from "@/eventBus";

export default {
  data: () => ({
    permissions: [
      {
        group: "Helfer",
        permissions: [
          {
            name: "Helfer verwalten",
            model: "users.general",
          },
          {
            name: "Qualifikationen anzeigen",
            model: "qualifications.general",
          },
          {
            name: "Gruppen verwalten",
            model: "user_groups.general",
          },
          {
            name: "Einheiten verwalten",
            model: "divisions.general",
          },
          {
            name: "Anwesenheit verwalten",
            model: "users.presence",
          },
        ],
      },
      {
        group: "Dienste",
        permissions: [
          {
            name: "Dienste verwalten",
            model: "meetings.general",
          },
          {
            name: "Erweiterte Dienstansicht",
            model: "meetings.advanced",
          },
          {
            name: "Abfragen verwalten",
            model: "events.general",
          },
          {
            name: "Erweiterte Abfragenansicht",
            model: "events.advanced",
          },
        ],
      },
      {
        group: "Aufgaben",
        permissions: [
          {
            name: "Aufgaben verwalten",
            model: "tasks.general",
          },
        ],
      },
      {
        group: "Mails",
        permissions: [
          {
            name: "Mails versenden",
            model: "notifications.general",
          },
          {
            name: "Administrations Mails",
            model: "notifications.administration",
          },
        ],
      },
      {
        group: "Administration",
        permissions: [
          {
            name: "Admin-Panel",
            model: "admin.general",
          },
          {
            name: "Rollen verwalten",
            model: "roles.general",
          },
        ],
      },
    ],
    can: [
      "users.general",
      "users.presence",
      "qualifications.general",
      "user_groups.general",
      "divisions.general",
      "meetings.general",
      "meetings.advanced",
      "events.general",
      "events.advanced",
      "tasks.general",
      "notifications.general",
      "notifications.administration",
      "admin.general",
      "roles.general",
    ],
    canVal: [],
    name: "",
    searchUsers: "",
    role: {
      users: [],
    },
    users: [],
    loader: false,
    valid: false,
    rules: {
      required: [(v) => !!v || "Benötigt"],
    },
  }),
  created: function() {
    this.loadRole();
    this.loadUsers();
  },
  methods: {
    loadRole() {
      if (this.$route.params.id) {
        const vm = this;
        vm.id = this.$route.params.id;
        Api.rolesGet(vm.id).then(function(response) {
          vm.role = response.data.data;
          vm.name = vm.role.name;
          for (var prop in vm.role.permissions) {
            if (vm.role.permissions[prop]) {
              vm.canVal[vm.can.indexOf(prop)] = true;
            }
          }
        });
      }
    },
    loadUsers: function() {
      const vm = this;
      Api.usersSimple().then(function(response) {
        vm.users = response.data.data;
        vm.users = _.sortBy(vm.users, ["last_name", "first_name"]);
      });
    },
    save() {
      const vm = this;
      if (typeof this.role.users[0] === "object") {
        var ids = [];
        this.role.users.forEach((element) => {
          ids.push(element.id);
        });
        this.role.users = ids;
      }
      if (this.$refs.form.validate()) {
        this.loader = true;
        let newRole = vm.role;
        newRole.name = vm.name;
        newRole.permissions = [];
        vm.canVal.forEach(function(value, i) {
          if (value) {
            newRole.permissions.push(vm.can[i]);
          }
        });
        if (vm.$route.params.id) {
          Api.rolesEdit(vm.id, newRole)
            .then(() => {
              this.$store.dispatch(USER_REQUEST);
              vm.loader = false;
              vm.$router.push({ name: "role" }, () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              });
            })
            .catch(() => {
              vm.loader = false;
            });
        } else {
          Api.rolesCreate(newRole)
            .then(() => {
              vm.loader = false;
              vm.$router.push({ name: "role" }, () => {
                EventBus.$emit("showSnackbar", "Erfolgreich!", "primary");
              });
            })
            .catch(() => {
              vm.loader = false;
            });
        }
      }
    },
    canValGet(val) {
      return this.can.indexOf(val);
    },
    customFilter(item, queryText) {
      const textOne =
        item.first_name.toLowerCase() + " " + item.last_name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    removeSelected(item) {
      let index = -1;
      if (typeof this.role.users[0] === "object")
        index = _.findIndex(this.role.users, item);
      else index = this.role.users.indexOf(item.id);
      this.role.users.splice(index, 1);
    },
  },
};
</script>

<style></style>
